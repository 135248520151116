import { useLocation } from "react-router-dom";

import { REPORTING_CENTER_MENU_TABS, ReportingCenterTab } from "../utils";

export function useReportingCenterTab(): ReportingCenterTab {
  let { hash } = useLocation();
  hash = hash.substring(1);

  if (hash && Object.values<string>(ReportingCenterTab).includes(hash)) {
    return hash as ReportingCenterTab;
  }

  return REPORTING_CENTER_MENU_TABS[0].id;
}
