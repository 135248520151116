import { dialogService } from "~/components/DialogStack";
import { CancelDialog } from "~/components/CancelDialog";
import { Text } from "~/components/Text";
import { asCurrency } from "~/utils/mask.utils";
import { SvgDolar } from "~/components/Icon/icons";
import { Form } from "~/components/Form";
import { useForm } from "react-hook-form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Flex } from "~/components/Flex";
import { TextAreaInput } from "~/components/Input";
import { SettingParameterSlug } from "~/application/types/entities/SettingParameter.type";
import * as yup from "yup";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { yupResolver } from "@hookform/resolvers/yup";
import useMobile from "~/presentation/shared/hooks/useMobile";

export type ConfirmOrderCancellationProps = {
  onRequestOrderCancellation: (justification?: string) => Promise<void>;
  taxCancellation?: number;
};

export function ConfirmOrderCancellation({
  onRequestOrderCancellation,
  taxCancellation,
}: ConfirmOrderCancellationProps) {
  const { contexts } = useUser();
  const isMobile = useMobile();

  const maxJustificationLength = Number(
    contexts?.customer.settingsParameters?.find(
      (setting) => setting.slug === SettingParameterSlug.MIN_CHARS_CANCEL_ORDER_OBS
    )?.value || 0
  );
  const schema = yup.object().shape({
    justification: maxJustificationLength
      ? yup
          .string()
          .required("A justificativa é obrigatória")
          .min(
            maxJustificationLength,
            `A justificativa deve ter no mínimo ${maxJustificationLength} caracteres`
          )
      : yup.string(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ justification?: string }>({
    resolver: yupResolver(schema),
  });

  return (
    <Form onSubmit={handleSubmit((data) => onRequestOrderCancellation(data.justification))}>
      <CancelDialog
        css={{
          width: !isMobile ? "600px" : "300px",
        }}
        title="Seu pedido ira gerar uma taxa."
        textCancelation="Cancelar pedido"
        onCloseClick={() => dialogService.popDialog()}
        svg={SvgDolar}
      >
        <Text as="p">
          {" "}
          O valor de{" "}
          <Text
            css={{
              fontWeight: "bold",
            }}
          >
            {asCurrency(taxCancellation || "0")}
          </Text>{" "}
          será cobrado por conta das políticas da companhia. Deseja contiuar?
          <FormControl
            required
            control={control}
            name="justification"
            css={{
              width: "100%",
            }}
          >
            <Flex
              align={"start"}
              direction={"column"}
              justify={"start"}
              css={{
                width: "100%",
              }}
            >
              <FieldLabel>Justificativa</FieldLabel>
              <TextAreaInput
                css={{
                  width: "100%",
                }}
              />
              {errors.justification && (
                <Text
                  as="span"
                  css={{
                    color: "red",
                    fontSize: "12px",
                    marginTop: "4px",
                  }}
                >
                  {errors.justification.message}
                </Text>
              )}
            </Flex>
          </FormControl>
        </Text>
      </CancelDialog>
    </Form>
  );
}
