import { ReportType } from "~/application/usecases/Reports/types";
import { ReportingCenterTab } from ".";

export type Report = {
  title: string;
  description: string;
  category: ReportingCenterTab;
  reportType: ReportType;
}

export const reports: Report[] = [
  {
    category: ReportingCenterTab.ALL,
    title: "Todos os produtos",
    description: "Informações completas sobre voos comprados, reservas de hotéis feitas, passagens de ônibus compradas e locações realizadas.",
    reportType: ReportType.GENERAL,
  },
  {
    category: ReportingCenterTab.AIRWAY,
    title: "Voos",
    description: "Informações completas sobre voos comprados, incluindo detalhes de ofertas e itinerários.",
    reportType: ReportType.AIRWAY,
  },
  {
    category: ReportingCenterTab.HOTEL,
    title: "Hotéis",
    description: "Relatórios completos sobre as reservas de hotéis feitas, com detalhes dos períodos e valores.",
    reportType: ReportType.HOTEL,
  },
  {
    category: ReportingCenterTab.ROAD,
    title: "Rodoviários",
    description: "Informações detalhadas sobre passagens de ônibus compradas, incluindo origem, destino e valores.",
    reportType: ReportType.ROAD,
  },
  {
    category: ReportingCenterTab.VEHICLE,
    title: "Veículos",
    description: "Detalhes das locações realizadas, incluindo tipo de veículo, período de uso e valores pagos.",
    reportType: ReportType.VEHICLE,
  },
  {
    category: ReportingCenterTab.EXPENSES,
    title: "Gasto por viajante",
    description: "Resumo dos gastos de cada viajante nas viagens realizadas no período.",
    reportType: ReportType.TRAVELER_EXPENSES,
  },
  {
    category: ReportingCenterTab.AIRWAY,
    title: "Bilhetes não voados",
    description: "Relatório detalhado sobre os bilhetes de voo comprados, mas não utilizados.",
    reportType: ReportType.UNFLOWN_AIRWAY,
  },
];