import { useForm } from "react-hook-form";
import { DateUtils } from "~/application/utils";
import { DateFormats } from "~/application/utils/date-functions";
import { Avatar } from "~/components/Avatar";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { CardBody } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Flex } from "~/components/Flex";
import { FormControl } from "~/components/FormControl";
import { Col, Row } from "~/components/Grid";
import { TextAreaInput } from "~/components/Input";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { Message } from "~/presentation/shared/components/Chat/ChatMessageGroup/styled";
import { useCreateOrderMessage } from "~/presentation/shared/hooks/useCreateOrderMessage";
import { useOrder } from "~/presentation/shared/hooks/useOrder";
import {
  LOG_TAG,
  TabOrderMessagesFormData,
  TabOrderMessagesProps,
} from "./types";
import { SvgArrowSend } from "~/components/Icon/icons/ArrowSend";

export function TabOrderMessages({
  isLoadingOrderMessages,
  orderMessages,
}: TabOrderMessagesProps) {
  const { order } = useOrder();
  const defaultValues = {
    message: "",
    notify: true,
  };

  const { control, handleSubmit, reset, watch, formState } =
    useForm<TabOrderMessagesFormData>({ defaultValues });

  const { message } = watch();

  const orderId = order?.uuid || "";

  const { create: createMessage } = useCreateOrderMessage(LOG_TAG, {
    orderId,
  });

  const onSubmit = (data: TabOrderMessagesFormData) => {
    if (!data.message.length) {
      return;
    }
    createMessage(data);
    reset();
  };

  const OrderMessages = () => {
    return (
      <Flex direction="column" gap="6">
        {orderMessages.map(({ message, createdAt, sender, uuid }) => {
          return (
            <Message
              senderIsMe={"default"}
              css={{
                maxWidth: "700px",
                width: "100%",
                p: "$2",
                borderRadius: "$sm",
                wordBreak: "keep-all",
              }}
              key={uuid}
            >
              <Flex
                gap="2"
                css={{
                  width: "100%",
                }}
              >
                <Avatar src={sender?.avatar} alt={sender?.fullName} size="sm" />
                <Flex
                  align="start"
                  gap="3"
                  css={{ width: "100%" }}
                  direction="column"
                >
                  <Flex
                    direction="column"
                    gap="1"
                    css={{
                      width: "100%",
                    }}
                  >
                    <Text size="2" fw="700">
                      {sender?.fullName}
                    </Text>
                    <Text
                      size="1"
                      fw="700"
                      css={{
                        color: sender?.agencyName
                          ? "$primary-base"
                          : "$neutrals-dark",
                      }}
                    >
                      {sender?.agencyName || sender?.customerName}
                    </Text>
                    <Text variant="dark" size="1">
                      {DateUtils.format(createdAt, DateFormats.LONG_DATE_TIME)}
                    </Text>
                  </Flex>
                  <Text size="3">{message}</Text>
                </Flex>
              </Flex>
            </Message>
          );
        })}
      </Flex>
    );
  };

  return (
    <Flex
      direction="column"
      css={{ height: "max(70vh, 400px)" }}
      gap="10"
      justify="between"
    >
      <CardBody
        css={{
          display: "flex",
          alignItems: "flex-start",
          width: "100%",
          p: "0",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "0.4rem",
          },
        }}
      >
        {isLoadingOrderMessages ? (
          <Box css={{ alignSelf: "center", justifySelf: "center" }}>
            <Spinner />
          </Box>
        ) : (
          <Flex
            direction="column"
            gap="3"
            css={{
              width: "100%",
            }}
          >
            {orderMessages.length > 0 ? (
              <OrderMessages />
            ) : (
              <EmptyState>
                <Text>Nenhuma observação encontrada</Text>
              </EmptyState>
            )}
          </Flex>
        )}
      </CardBody>

      <CardBody
        css={{
          display: "flex",
          width: "100%",
          p: "0",
          alignItems: "center",
        }}
      >
        <Row
          css={{
            flex: "0 0 auto",
            gap: "$3",
            width: "100%",
          }}
          justify="center"
          align="center"
        >
          <FormControl name="message" control={control}>
            <TextAreaInput
              maxLength={1000}
              placeholder="Escreva uma mensagem aqui"
              css={{
                resize: "vertical",
                borderRadius: "$md",
                fontSize: "$sm",
                "&[data-focused=true]": {
                  boxShadow: "$none",
                },
                "@mxmd": {
                  p: 20,
                },
              }}
              rightAdornment={
                <Col sz="1">
                  <Button
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={formState.isSubmitting || message.length === 0}
                    css={{
                      position: "absolute",
                      right: "$4",
                      bottom: "$2",
                      fontSize: "$sm",
                      "@mxmd": {
                        top: "$3",
                      },
                    }}
                  >
                    <SvgArrowSend />
                    <Text
                      css={{
                        "@mxmd": {
                          display: "none",
                        },
                      }}
                    >
                      Enviar
                    </Text>
                  </Button>
                </Col>
              }
            />
          </FormControl>
        </Row>
      </CardBody>
    </Flex>
  );
}
