import { Button } from "~/components/Button";
import { Card } from "~/components/Card";
import { DialogHeader } from "~/components/Dialog";
import { Icon } from "~/components/Icon";
import { SvgCheck, SvgClose } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";

export type ReportProcessingDialogProps = {
  onCancel: () => void;
};

export function ReportProcessingDialog({ onCancel }: ReportProcessingDialogProps) {
  return (
    <Card
      css={{
        boxShadow: "$sm",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        py: "$5",
        px: "$12",
        gap: "$4",
        textAlign: "center",
        width: "600px",
        position: "relative",
        color: "$neutrals-dark",
        "@mxsm": {
          width: "90vw",
        },
      }}
    >
      <Icon
        size="4xl"
        as={SvgCheck}
        css={{
          fill: "$primary-base",
        }}
      />

      <IconButton
        css={{
          position: "absolute",
          right: "$5",
          stroke: "$primary-base",
        }}
        onClick={onCancel}
      >
        <Icon as={SvgClose} />
      </IconButton>

      <DialogHeader
        css={{
          borderBottom: "none",
          p: 0,
        }}
      >
        <H4 fw="600">
          Processando relatório
        </H4>
      </DialogHeader>

      <Text css={{ lineHeight: 1.4, mb: "$4" }}>
        Seu relatório está sendo processado pelo sistema e será enviado para seu email em breve (isso pode demorar alguns minutos)
      </Text>

      <Button onClick={onCancel}>
        Entendi
      </Button>
    </Card>
  );
}
