import { dialogService } from "~/components/DialogStack";
import { CancelDialog } from "~/components/CancelDialog";
import { Text } from "~/components/Text";
import { SvgRemove } from "~/components/Icon/icons";
import { OrderRoadItem, OrderTaxCancellation } from "~/application/types";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "@tanstack/react-query";

export type CantOrderCancellationProps = {
  onRequestOrderCancellation: () => Promise<void>;
  taxCancellation?: number;
  dontAllowsCancelItems?: OrderRoadItem[];
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<OrderTaxCancellation, unknown>>;
};

export function CantOrderCancellation() {
  return (
    <CancelDialog
      title="Seu pedido não poderá ser cancelado"
      textCancelation="Voltar"
      onCloseClick={() => dialogService.popDialog()}
      svg={SvgRemove}
      showSvg={false}
    >
      <Text>
        Por conta das políticas da companhia sua solicitação de cancelamento não pode prosseguir.{" "}
      </Text>
    </CancelDialog>
  );
}
