import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { keyframes } from "~/application/theme";
import {
  Actions,
  Order,
  OrderAirwaySegment,
  OrderHotelItem,
  OrderItemStatus,
  OrderItems,
  OrderStatus,
  UserContext,
} from "~/application/types";
import { DateUtils } from "~/application/utils";
import { DateFormats } from "~/application/utils/date-functions";
import { Alert, AlertIcon } from "~/components/Alert";
import { Button } from "~/components/Button";
import { CardBody } from "~/components/Card";
import { Cart, CartHeader } from "~/components/Cart";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import {
  SvgChevronDown,
  SvgChevronUp,
  SvgExpenses,
  SvgInfo,
  SvgOrder,
  SvgTicket,
  SvgWhatsapp,
} from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Skeleton } from "~/components/Skeleton";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Label } from "~/components/Typography";
import { userIsIssuer } from "~/core/modules/Order/utils";
import { OrderStatusSteps } from "~/core/shared/components/OrderStatusSteps";
import * as OrderUtils from "~/core/shared/utils/order.utils";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { CartApprovalButtons } from "~/presentation/shared/components/CartApprovalButtons";
import { NotifyIssuerButton } from "~/presentation/shared/components/NotifyIssuerButton";
import { OrderStatusTag } from "~/presentation/shared/components/OrderStatusTag";
import { OrderTimer } from "~/presentation/shared/components/OrderTimer";
import { useOrderApproval } from "~/presentation/shared/hooks/useOrderApproval/useOrderApproval";
import { isValidDate } from "~/utils/date.utils";
import * as MaskUtils from "~/utils/mask.utils";
import { userIsOrderApprover } from "../../../../utils";
import { ApprovalOrderContext } from "../../hooks/ApprovalOrderContext";
import { BrokenPolicyJustificationContext } from "../../hooks/BrokenPolicyJustificationContext";
import { canShowApprovalButtons, getTotalProviderFees } from "../../utils";
import { ApproversList } from "../ApproversList";
import { useBudgetBalance } from "../../hooks/useBudgetBalance";
import { SettingParameterSlug } from "~/application/types/entities/SettingParameter.type";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";

export interface TravelerOrderCartProps {
  order?: Order;
  isLoading: boolean;
  currentTime?: Date;
  isRoadRebookError: boolean;
  isRebookingRoad: boolean;
  airwayOrder: OrderAirwaySegment[];
  isIssuingOrder: boolean;
  onNotifyIssuerThatOrderExpired: () => void;
  onRequestApproval: (data: any) => void;
  onRequestOrderCancellation: () => void;
  onSendVoucherInWhatsapp: () => void;
  onCancelOrder: () => void;
  refetchOrder: () => void;
  onOfflineReject: (orderId: string) => void;
  onRemakeOrder: () => void;
}

export function TravelerOrderCart({
  order,
  currentTime,
  isLoading,
  isRebookingRoad,
  isRoadRebookError,
  airwayOrder,
  isIssuingOrder,
  onNotifyIssuerThatOrderExpired,
  onRequestOrderCancellation,
  onSendVoucherInWhatsapp,
  onRemakeOrder,
  onRequestApproval,
  onCancelOrder,
  refetchOrder,
  onOfflineReject,
}: TravelerOrderCartProps) {
  const { brokenPolicyItems } = useContext(BrokenPolicyJustificationContext);
  const { user, profile, contexts } = useUser();
  const [isCartOpen, setCartIsOpen] = useState(false);

  const orderStatus = order?.status as OrderStatus;
  const isUserIssuer = userIsIssuer(user, order);
  const isValidAgencyEmployee =
    user.context === UserContext.Agency && user?.agency?.uuid === order?.customer.agencyId;

  const canShowRequestApprovalButton =
    ([OrderStatus.OPEN, OrderStatus.REJECTED, OrderStatus.QUOTED].includes(orderStatus) &&
      (isUserIssuer || isValidAgencyEmployee) &&
      !order?.isExpired) ||
    (orderStatus === OrderStatus.APPROVED && isIssuingOrder);

  const shouldRequestApproval = () => {
    if (!orderStatus) return false;

    const offlineRooms = order?.items.hotel?.rooms;
    const offlineHotels = offlineRooms?.filter((room) => room.isOffline);

    const roomIsQuoted = (room: OrderHotelItem) => {
      return room.options?.some(({ status }) => status === OrderItemStatus.QUOTED);
    };

    if (offlineHotels?.some((room) => !roomIsQuoted(room))) {
      return false;
    }

    return canShowRequestApprovalButton;
  };

  const currentTotalValue = useMemo(() => {
    const offlineRooms = order?.items.hotel?.rooms;
    const offlineHotels = offlineRooms?.filter((room) => room.isOffline);

    const roomQuoted = (room: OrderHotelItem) => {
      return room.options?.find(({ status }) => status === OrderItemStatus.QUOTED);
    };
    const valueOfflineValue =
      offlineHotels?.reduce((acc, room) => {
        return (roomQuoted(room)?.customerValue || 0) + acc;
      }, 0) || 0;

    return orderStatus === OrderStatus.QUOTED
      ? valueOfflineValue + (order?.totalValue || 0)
      : order?.totalValue || 0;
  }, [order, shouldRequestApproval, orderStatus]);

  const canRequestApproval = shouldRequestApproval();

  const canCancelOrder =
    [
      OrderStatus.OPEN,
      OrderStatus.QUOTED,
      OrderStatus.CHANGING,
      OrderStatus.REJECTED,
      OrderStatus.ON_APPROVAL,
    ].includes(orderStatus) &&
    (isUserIssuer || user.context === UserContext.Agency);

  const hash = window.location.hash;

  const offlineRooms = order?.items.hotel?.rooms;
  const hasOfflineHotels = offlineRooms?.some((room) => room.isOffline);

  const canRejectOfflineOrder =
    [OrderStatus.QUOTED].includes(orderStatus) && !order?.isExpired && hasOfflineHotels;

  const canDisplayOrderTimer =
    !!currentTime &&
    !!order?.expirationDate &&
    ![
      OrderStatus.CANCELED,
      OrderStatus.ISSUED,
      OrderStatus.EXPIRED,
      OrderStatus.EXPIRED_TIME,
    ].includes(orderStatus) &&
    ![`#${OrderStatus.EXPIRED}`, `#${OrderStatus.EXPIRED_TIME}`].includes(hash);

  const canRemakeOrder =
    isUserIssuer &&
    order?.isExpired &&
    ![OrderStatus.CANCELED, OrderStatus.ISSUED, OrderStatus.CANCELING].includes(orderStatus);

  const generatingTracker = airwayOrder.some(
    (airway) => !airway.tracker || airway?.tracker?.toLowerCase() === "gerando localizador"
  );

  const totalProviderFees = getTotalProviderFees(order);

  const isApprover = userIsOrderApprover(order);

  const { handleSubmit } = useForm({
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const approvers = order?.approvalModel?.approvers ?? [];
  const userId = user.profiles.customer.uuid ?? order?.issuer?.uuid;

  const isSelfApprover =
    profile?.isApprover && approvers.some((a) => a.uuid === userId && a.isSelfApprover);

  const approverId = user.profiles.customer.uuid;
  const canShowJudgementButtons =
    order?.status === OrderStatus.ON_APPROVAL && approvers?.some(({ uuid }) => uuid === approverId);

  const approvalOrderContext = useContext(ApprovalOrderContext);
  const {
    cannotApproveItems,
    cannotReproveItems,
    amountOfItems,
    onConsultOrderItemsPrice,
    onOrderItemsPriceChange,
    handleJudgment,
  } = approvalOrderContext;

  const items = approvalOrderContext.items ?? { approved: [], rejected: [] };

  const { onSubmit } = useOrderApproval({
    handleSubmit,
    handleJudgment,
    canShowApprovalButtons: canShowApprovalButtons(order),
    onConsultOrderItemsPrice,
    onOrderItemsPriceChange,
    isRebookingRoad,
    isRoadRebookError,
    cannotApproveItems,
  });

  const airwaySeatsValue = order?.items.airway?.fees?.seatValue;
  const returnFeeAnotherCity = order?.items.vehicle?.vehicles.reduce(
    (acc, item) => acc + (item.returnFeeAnotherCity || 0),
    0
  );
  const [shouldAnimate, setShouldAnimate] = useState(false);

  const shake = useMemo(() => {
    return keyframes({
      "0%": {
        height: "5%",
        boxShadow: "0 0 0 0 rgba(0, 100, 197, 0.7)",
      },
      "25%": {
        height: "3%",
        boxShadow: "0 0 0 0 rgba(0, 100, 197, 0.7)",
      },
      "50%": {
        height: "6%",
        boxShadow: "0 0 0 0 rgba(0, 100, 197, 0.7)",
      },
      "75%": {
        height: "5%",
        boxShadow: "0 0 0 0 rgba(0, 100, 197, 0.7)",
      },
      "100%": {
        height: "5%",
        boxShadow: "0 0 0 0 rgba(0, 100, 197, 0.7)",
      },
    });
  }, []);

  useEffect(() => {
    if (
      items.rejected.length === amountOfItems &&
      items.rejected.every((item) => item.reasonRejected !== "")
    ) {
      const timer = setTimeout(() => setShouldAnimate(true), 1000);

      return () => clearTimeout(timer);
    }
  }, [items?.rejected]);

  useEffect(() => {
    if (items.approved.length === amountOfItems) {
      setCartIsOpen(true);
    }
  }, [items.approved]);

  const isRequestApprovalDisabled =
    !canRequestApproval || generatingTracker || isIssuingOrder || isRebookingRoad;

  const canRequestOrderCancellation = [OrderStatus.APPROVED, OrderStatus.ISSUED].includes(
    orderStatus
  );

  const handleOrderCancellation = useCallback(async () => {
    onRequestOrderCancellation();
  }, []);

  const canShowAlert =
    isValidDate(order?.expirationDate) &&
    ![OrderStatus.CANCELED, OrderStatus.ISSUED].includes(orderStatus);

  isIssuingOrder = isIssuingOrder && orderStatus === OrderStatus.APPROVED;

  const getRequestApprovalButtonText = () => {
    if (isRebookingRoad) return "Confirmando reserva";
    if (generatingTracker) return "Gerando localizador";
    if (isRebookingRoad) return "Confirmando sua reserva";
    if (isIssuingOrder) return "Emitindo pedido";

    return orderStatus === OrderStatus.ON_APPROVAL ? "Aprovar" : "Solicitar aprovação";
  };

  const canApproveAll = useVerifyActions({
    actions: [Actions.ApprovalAll],
    contexts,
    profile,
  });

  const isOfflineQuote = (order: Order | undefined) => {
    if (!order) return false;

    const { itemsIncluded, items } = order;

    const hasOfflineHotel = itemsIncluded.includes(OrderItems.HOTEL_OFFLINE);

    const hasOfflineRoom = items.hotel?.rooms?.some((room) => room.isOffline) || false;

    return hasOfflineHotel || hasOfflineRoom;
  };

  const { budgetBalance, canShowBudgetBalance, budgetName } = useBudgetBalance({
    order,
    profile,
    contexts,
  });

  return (
    <Cart
      css={{
        "@mxlg": {
          position: "fixed",
          width: "100%",
          left: "0",
          height: isCartOpen ? "450px" : "50px",
          transition: "$slow",
          overflowY: "scroll",
          bottom: "0",
          px: "0",
          backgroundColor: "#003161",
          zIndex: "1",
          borderRadius: "0",
          animation: shouldAnimate && !isCartOpen ? `${shake} 2s infinite` : undefined,
        },
      }}
    >
      <CartHeader
        css={{
          "@mxlg": {
            margin: "0 auto",
            height: "0",
            width: "100%",
          },
        }}
      >
        <Icon
          as={SvgExpenses}
          size="sm"
          css={{
            "@mxlg": {
              margin: "0 auto",
              width: "100%",
              display: "none",
            },
          }}
        />
        <Icon
          as={isCartOpen ? SvgChevronDown : SvgChevronUp}
          onClick={() => {
            setCartIsOpen((prev) => !prev);
            setShouldAnimate(false);
          }}
          size="sm"
          css={{
            display: "none",
            "@mxlg": {
              margin: "0 auto",
              width: "100%",
              display: "flex",
            },
          }}
        />
        <Text
          css={{
            "@mxlg": {
              display: "none",
            },
          }}
        >
          Resumo do pedido
        </Text>
      </CartHeader>

      {canShowAlert && (
        <>
          <CardBody
            css={{
              "@mxlg": {
                display: "none",
              },
            }}
          >
            <Alert variant={order?.isExpired ? "error" : "info"}>
              <AlertIcon />

              <Text>
                {order?.isExpired ? "Expirado em " : "Expira em "}
                {DateUtils.format(order?.expirationDate || "", DateFormats.LONG_DATE)}
              </Text>
            </Alert>
          </CardBody>

          <Divider css={{ "@mxlg": { display: "none" } }} />
        </>
      )}

      <OrderStatusSteps status={orderStatus} isOfflineQuote={isOfflineQuote(order)} />

      <Divider css={{ "@mxlg": { display: "none" } }} />

      <CardBody>
        <Flex direction="column" gap="6">
          <Text size="3" fw="600" css={{ "@mxlg": { color: "White" } }}>
            Informações do pedido
          </Text>

          <Flex direction="column" gap="6">
            {isLoading || !order ? (
              <Skeleton variant="text-6" />
            ) : (
              <Flex gap="4" align="center">
                <Col>
                  <Label css={{ "@mxlg": { color: "White" } }}>Solicitante</Label>
                </Col>

                <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                  {order.issuer.name}
                </Text>
              </Flex>
            )}

            {isLoading || !order ? (
              <>
                <Skeleton variant="text-6" />
                <Skeleton variant="text-4" />
                <Skeleton variant="text-4" />
                <Skeleton variant="text-4" />
              </>
            ) : (
              order.itemsIncluded.map((item) => (
                <Flex gap="2" align="center" key={item}>
                  <Icon variant="dark" as={OrderUtils.getServiceIcon(item)} size="sm" />

                  <Col>
                    <Label css={{ "@mxlg": { color: "White" } }}>
                      {OrderUtils.getServiceLabel(item)}
                    </Label>
                  </Col>
                  <Flex align="center">
                    <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                      {MaskUtils.asCurrency(order.items[item]?.value ?? 0)}
                    </Text>

                    {item === OrderItems.AIRWAY && user.context === UserContext.Agency && (
                      <Tooltip
                        content={
                          <TooltipLabel>
                            <Flex direction="column" gap="2">
                              <TooltipLabel>
                                Taxa de embarque:{" "}
                                {MaskUtils.asCurrency(
                                  order.items.airway?.flights.reduce(
                                    (acc, flight) => (acc += flight.boardingTax),
                                    0
                                  ) as number
                                )}
                              </TooltipLabel>
                              <TooltipLabel>
                                Du:{" "}
                                {MaskUtils.asCurrency(
                                  order.items.airway?.flights.reduce(
                                    (acc, flight) => (acc += flight.du),
                                    0
                                  ) as number
                                )}
                              </TooltipLabel>
                              <TooltipLabel>
                                Tarifa:{" "}
                                {MaskUtils.asCurrency(
                                  order.items.airway?.flights.reduce(
                                    (acc, flight) => (acc += flight.fare),
                                    0
                                  ) as number
                                )}
                              </TooltipLabel>
                            </Flex>
                          </TooltipLabel>
                        }
                      >
                        <IconButton size="sm">
                          <Icon
                            as={SvgInfo}
                            css={{
                              fill: "$primary-base",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Flex>
                </Flex>
              ))
            )}

            {!!returnFeeAnotherCity && (
              <Flex gap="2" align="center">
                <Icon variant="dark" as={SvgOrder} size="sm" />

                <Col>
                  <Label css={{ "@mxlg": { color: "White" } }}>
                    Taxa de devolução em outra loja
                  </Label>
                </Col>

                <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                  {MaskUtils.asCurrency(returnFeeAnotherCity)}
                </Text>
              </Flex>
            )}

            {isLoading || !order ? (
              <Skeleton variant="text-4" />
            ) : (
              <>
                <Flex gap="2" align="center">
                  <Icon variant="dark" as={SvgOrder} size="sm" />

                  <Col>
                    <Label css={{ "@mxlg": { color: "White" } }}>Taxas e encargos</Label>
                  </Col>

                  <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                    {MaskUtils.asCurrency(totalProviderFees)}
                  </Text>
                </Flex>

                {!!airwaySeatsValue && (
                  <Flex gap="2" align="center">
                    <Icon variant="dark" as={SvgTicket} size="sm" />

                    <Col>
                      <Label css={{ "@mxlg": { color: "White" } }}>Valor dos assentos</Label>
                    </Col>

                    <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                      {MaskUtils.asCurrency(airwaySeatsValue)}
                    </Text>
                  </Flex>
                )}

                {order?.totalFees > 0 && (
                  <Flex gap="2" align="center">
                    <Icon variant="dark" as={SvgOrder} size="sm" />
                    <Col>
                      <Label variant={{ "@initial": "dark", "@mxlg": "white" }}>
                        Taxa de serviço
                      </Label>
                    </Col>
                    <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                      {MaskUtils.asCurrency(order.totalFees)}
                    </Text>
                  </Flex>
                )}
              </>
            )}

            {isLoading || !order ? (
              <Skeleton variant="text-4" />
            ) : (
              order.project && (
                <Flex gap="2" align="center">
                  <Col>
                    <Label css={{ "@mxlg": { color: "White" } }}>Projeto</Label>
                  </Col>

                  <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                    {order.project.name}
                  </Text>
                </Flex>
              )
            )}

            {isLoading || !order ? (
              <Skeleton variant="text-4" />
            ) : (
              <Flex gap="2" align="center">
                <Col>
                  <Label css={{ "@mxlg": { color: "White" } }}>Status do pedido</Label>
                </Col>
                <OrderStatusTag data={orderStatus} />
              </Flex>
            )}

            {isLoading || !order ? (
              <Skeleton variant="text-6" />
            ) : (
              <Flex gap="2" align="center">
                <Col>
                  <Label variant="black" size="5" fw="700" css={{ "@mxlg": { color: "White" } }}>
                    Total
                  </Label>
                </Col>

                <Text size="5" fw="700" css={{ "@mxlg": { color: "White" } }}>
                  {MaskUtils.asCurrency(currentTotalValue)}
                </Text>
              </Flex>
            )}

            <Flex
              css={{
                width: "100%",
                margin: "0 auto",
                "@lg": { display: "none" },
              }}
            >
              <ApproversList />
            </Flex>

            {!order?.isExpired && order?.expirationDate && (
              <OrderTimer
                initialDate={currentTime}
                targetDate={order.expirationDate}
                enabled={canDisplayOrderTimer}
                onOrderExpired={refetchOrder}
                isLoading={isLoading}
              />
            )}

            {!isSelfApprover &&
              !canApproveAll &&
              isUserIssuer &&
              approvers.length > 0 &&
              order?.status === OrderStatus.ON_APPROVAL && (
                <Alert variant="warning">
                  <AlertIcon />
                  <Text css={{ lineHeight: "1.25" }}>
                    Você não pode aprovar um pedido que você tenha criado, solicite a aprovação para
                    outro aprovador.
                  </Text>
                </Alert>
              )}

            {!isUserIssuer && user.context === "customer" && (
              <Alert variant="warning">
                <AlertIcon />
                <Text css={{ lineHeight: "1.25" }}>
                  Apenas o solicitante do pedido pode solicitar o cancelamento.
                </Text>
              </Alert>
            )}
          </Flex>

          <Flex direction="column" gap="3">
            <NotifyIssuerButton
              onNotifyIssuer={onNotifyIssuerThatOrderExpired}
              enabled={
                order?.isExpired &&
                isApprover &&
                ![OrderStatus.CANCELED, OrderStatus.CANCELING, OrderStatus.ISSUED].includes(
                  orderStatus
                )
              }
            />
            {canShowRequestApprovalButton && (
              <Button
                onClick={() => onRequestApproval(brokenPolicyItems)}
                disabled={isRequestApprovalDisabled}
              >
                {(generatingTracker || isIssuingOrder || isRebookingRoad) && (
                  <Spinner
                    css={{
                      borderLeftColor: "white",
                      borderBottomColor: "white",
                      scale: 0.5,
                    }}
                  />
                )}
                <Text>{getRequestApprovalButtonText()}</Text>
              </Button>
            )}
            {canRejectOfflineOrder && (
              <Button variant="error-light" onClick={() => onOfflineReject(order?.uuid || "")}>
                Rejeitar cotação
              </Button>
            )}
            {canCancelOrder && (
              <Button
                variant="error"
                onClick={onCancelOrder}
                disabled={!isUserIssuer && user.context === "customer"}
              >
                <Text variant="error-light">Cancelar pedido</Text>
              </Button>
            )}
            {canRequestOrderCancellation && (
              <Button
                variant="error"
                onClick={handleOrderCancellation}
                disabled={!isUserIssuer && user.context === "customer"}
              >
                <Text variant="error-light">Solicitar cancelamento</Text>
              </Button>
            )}
            {!canShowRequestApprovalButton && (
              <CartApprovalButtons
                onSubmit={onSubmit}
                isIssuingOrder={isIssuingOrder}
                getApproveButtonText={getRequestApprovalButtonText}
                enabled={canShowJudgementButtons}
                isRebookingRoad={isRebookingRoad}
                cannotApproveItems={cannotApproveItems}
                cannotReproveItems={cannotReproveItems}
              />
            )}
            {canRemakeOrder && <Button onClick={onRemakeOrder}>Refazer pedido</Button>}
            {orderStatus === OrderStatus.ISSUED && (
              <Button variant="success" onClick={onSendVoucherInWhatsapp}>
                <Icon as={SvgWhatsapp} />
                <Text>Enviar voucher no whatsapp</Text>
              </Button>
            )}
          </Flex>

          {canShowBudgetBalance &&
            (isLoading || !budgetBalance ? (
              <Skeleton css={{ height: "$20" }} />
            ) : (
              <Alert variant={budgetBalance.value! >= 0 ? "info" : "warning"}>
                <Text css={{ lineHeight: 1.4 }}>
                  O orçamento disponível {budgetName()} é de{" "}
                  <Text fw="700">{MaskUtils.asCurrency(budgetBalance.value!)}</Text>
                </Text>
              </Alert>
            ))}
        </Flex>
      </CardBody>
      <ApproversList />
    </Cart>
  );
}
