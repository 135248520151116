import { FC } from "react";
import { OrderItemStatus, OrderStatus } from "~/application/types";
import { createTagHOC } from "~/core/shared/components/TagHOC";
import { getOrderStatus } from "../../utils";
import { OrderStatusTagProps } from "./types";

export const OrderStatusTag: FC<OrderStatusTagProps> = createTagHOC<OrderStatus | OrderItemStatus>({
  config: {
    [OrderItemStatus.FAILED_CANCEL]: {
      title: "Falha ao cancelar",
      variant: "error-light",
    },
    [OrderItemStatus.EXPIRED_TIME]: {
      title: getOrderStatus(OrderStatus.EXPIRED_TIME),
      variant: "error-light",
    },
    [OrderItemStatus.DONE]: {
      title: getOrderStatus(OrderStatus.ISSUED),
      variant: "info-light",
    },
    [OrderItemStatus.FAILED]: {
      title: getOrderStatus(OrderStatus.FAILED),
      variant: "error-light",
    },
    [OrderItemStatus.QUOTED]: {
      title: getOrderStatus(OrderItemStatus.QUOTED),
      variant: "info-light",
    },
    [OrderStatus.APPROVED]: {
      title: getOrderStatus(OrderStatus.APPROVED),
      variant: "success-light",
    },
    [OrderStatus.WAITING]: {
      title: getOrderStatus(OrderStatus.WAITING),
      variant: "warning-light",
    },
    [OrderStatus.EXPIRED]: {
      title: getOrderStatus(OrderStatus.EXPIRED),
      variant: "error-light",
    },
    [OrderStatus.CANCELED]: {
      title: getOrderStatus(OrderStatus.CANCELED),
      variant: "error-light",
    },
    [OrderStatus.OPEN]: {
      title: getOrderStatus(OrderStatus.OPEN),
      variant: "neutral-light",
    },
    [OrderStatus.ON_APPROVAL]: {
      title: getOrderStatus(OrderStatus.ON_APPROVAL),
      variant: "info-light",
    },
    [OrderStatus.CHANGING]: {
      title: getOrderStatus(OrderStatus.CHANGING),
      variant: "warning-light",
    },
    [OrderStatus.REJECTED]: {
      title: getOrderStatus(OrderStatus.REJECTED),
      variant: "error-light",
    },
    [OrderStatus.CANCELING]: {
      title: getOrderStatus(OrderStatus.CANCELING),
      variant: "neutral-light",
    },
    [OrderStatus.QUOTING]: {
      title: getOrderStatus(OrderStatus.QUOTING),
      variant: "info-light",
    },
    [OrderStatus.PENDING_ISSUE]: {
      title: getOrderStatus(OrderStatus.PENDING_ISSUE),
      variant: "warning-light",
    },
    [OrderStatus.ISSUED]: {
      title: getOrderStatus(OrderStatus.ISSUED),
      variant: "info-light",
    },
  },
});

OrderStatusTag.displayName = "OrderStatusTag";
