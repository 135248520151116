import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { DataColItem, DataListItem } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { CostCenterListItemProps } from "./types";
import { Caption } from "~/components/Typography";
import { Switch } from "~/components/Input";
import useMobile from "../../hooks/useMobile";
import { Flex } from "~/components/Flex";
import { dialogService } from "~/components/DialogStack";
import { LoadingDialog } from "../../views/LoadingDialog";
import { routes } from "~/application/theme/routes";

export const CostCenterListItem: FC<CostCenterListItemProps> = ({
  data,
  onEditClick,
  onToggleState,
  ...props
}) => {
  const navigate = useNavigate();

  const isMobile = useMobile();

  const approvalModel = data.approvalModels.find((approvalModel) => approvalModel.active);

  const handleOpenEditApprovalModelModal = () => {
    if (!approvalModel) return;

    navigate(`${routes.Configuration.ApprovalModels.root}?modelo-de-aprovacao=${approvalModel.uuid}`);

    dialogService.showDialog(<LoadingDialog message="Buscando modelo de aprovação" />);
  };

  return (
    <DataListItem
      data-active={data.isActive}
      {...props}
      css={{ "@mxlg": { flexDirection: "column" } }}
    >
      <DataColItem headerTitle="Nome" data={data.name} />

      <DataColItem headerTitle="Integração Externa" data={data.costCenterCode} />

      <DataColItem
        headerTitle="Modelo de aprovação"
        data={approvalModel?.name ?? "Sem modelo vinculado"}
        css={{
          cursor: approvalModel?.name ? "pointer" : "auto",
          "&:hover": { textDecoration: approvalModel?.name ? "underline" : "none" },
        }}
        onClick={handleOpenEditApprovalModelModal}
      />

      <Flex justify="between" css={{ width: "100%", "@lg": { display: "none" }}}>
        {onEditClick && (
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>
        )}

        {onToggleState && (
          <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
            <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        )}
      </Flex>

      <Flex>
        {onEditClick && !isMobile && (
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>
        )}

        {onToggleState && !isMobile && (
          <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
            <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        )}
      </Flex>
    </DataListItem>
  );
};

CostCenterListItem.displayName = "CostCenterListItem";
