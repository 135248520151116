import { logError } from "../../utils/errors";
import { UseDownloadProps } from "./types";
import { useMutation } from "@tanstack/react-query";
import { CustomerOrder, Order } from "~/application/types";
import { orderService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { LoadingDialog } from "../../views/LoadingDialog";

export function useDownload({ logTag }: UseDownloadProps) {
  const { mutate: mutateDownloadVoucher } = useMutation(
    (order: CustomerOrder | Order) => orderService.downloadVoucher(order),
    {
      onMutate: (item) => {
        dialogService.showDialog(<LoadingDialog message="Baixando voucher" />);
      },
      onError: (error) => {
        logError({
          error,
          logTag,
          defaultErrorMessage: "Falha ao baixar voucher",
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );
  const { mutate: mutateDownloadIndividualVoucher } = useMutation(
    ({ customerEmployeeId, order }: { order: CustomerOrder | Order; customerEmployeeId: string }) =>
      orderService.downloadIndividualVoucher({ order, customerEmployeeId }),
    {
      onMutate: (item) => {
        dialogService.showDialog(<LoadingDialog message="Baixando voucher" />);
      },
      onError: (error) => {
        logError({
          error,
          logTag,
          defaultErrorMessage: "Falha ao baixar voucher",
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  return {
    onDownload: mutateDownloadVoucher,
    onDownloadIndividual: mutateDownloadIndividualVoucher,
  };
}
