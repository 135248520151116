import { dialogService } from "~/components/DialogStack";
import { CancelDialog, CancelDialogProps } from "~/components/CancelDialog";
import { Text } from "~/components/Text";
import { SvgDolar } from "~/components/Icon/icons";
import { CardProps } from "~/components/Card";
import { TextAreaInput, TextInput } from "~/components/Input";
import { Flex } from "~/components/Flex";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { useForm } from "react-hook-form";
import { Form } from "~/components/Form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { SettingParameterSlug } from "~/application/types/entities/SettingParameter.type";
import useMobile from "~/presentation/shared/hooks/useMobile";

export interface ConfirmOrderCancelProps extends CardProps {
  onCancelOrder?: () => Promise<void>;
  onRequestCancelOrder?: (justification?: string) => Promise<void>;
}

export function ConfirmOrderCancel({
  onCancelOrder,
  onRequestCancelOrder,
  ...props
}: ConfirmOrderCancelProps) {
  const { contexts } = useUser();
  const isMobile = useMobile();

  const maxJustificationLength = Number(
    contexts?.customer?.settingsParameters?.find(
      (setting) => setting?.slug === SettingParameterSlug.MIN_CHARS_CANCEL_ORDER_OBS
    )?.value || 0
  );
  const schema = yup.object().shape({
    justification:
      onRequestCancelOrder && maxJustificationLength
        ? yup
            .string()
            .required("A justificativa é obrigatória")
            .min(
              maxJustificationLength,
              `A justificativa deve ter no mínimo ${maxJustificationLength} caracteres`
            )
        : yup.string(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ justification?: string }>({
    resolver: yupResolver(schema),
  });

  return (
    <Form
      onSubmit={handleSubmit((data) =>
        onRequestCancelOrder ? onRequestCancelOrder?.(data.justification) : onCancelOrder?.()
      )}
    >
      <CancelDialog
        css={{
          width: !isMobile ? "600px" : "300px",
          display: "flex",
          justifyContent: "start",
        }}
        onCloseClick={() => dialogService.popDialog()}
        title="Cancelamento do pedido"
        textCancelation="Cancelar pedido"
        svg={SvgDolar}
        {...props}
      >
        <Text as="p">Deseja continuar?</Text>
        {onRequestCancelOrder && (
          <FormControl
            required
            control={control}
            name="justification"
            css={{
              width: "100%",
            }}
          >
            <Flex
              align={"start"}
              direction={"column"}
              justify={"start"}
              css={{
                width: "100%",
              }}
            >
              <FieldLabel>Justificativa do cancelamento</FieldLabel>
              <TextAreaInput
                placeholder="Digite a justificativa do cancelamento (mínimo 40 caracteres)"
                css={{
                  width: "100%",
                }}
              />
              {errors.justification && (
                <Text
                  as="span"
                  css={{
                    color: "red",
                    fontSize: "12px",
                    marginTop: "4px",
                  }}
                >
                  {errors.justification.message}
                </Text>
              )}
            </Flex>
          </FormControl>
        )}
      </CancelDialog>
    </Form>
  );
}
