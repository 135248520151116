import { Fragment, useCallback } from "react";
import { ReasonTrip } from "~/application/types";
import {
  AppBar,
  AppBarActions,
  AppBarContainer,
  AppBarHeading,
} from "~/components/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { ReasonTripListItem } from "../../../shared/components/ReasonTripListItem/ReasonTripListItem";
import { useReasonsTripPage } from "./logic/useReasonsTripPage";
import useMobile from "~/presentation/shared/hooks/useMobile";

export function ReasonsTripPage() {
  const {
    data,
    currentPage,
    isLoading,
    lastPage,
    onCreateReasonTrip,
    onEditReasonTrip,
    onToggleReasonTripState,
    onGoToPage,
  } = useReasonsTripPage();

  const isMobile = useMobile();

  const listRenderer = useCallback(
    (item: ReasonTrip) => (
      <ReasonTripListItem
        data={item}
        onEditClick={onEditReasonTrip}
        onToggleState={onToggleReasonTripState}
        key={item.uuid}
      />
    ),
    [onEditReasonTrip, onToggleReasonTripState]
  );

  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <AppBarHeading title="Motivos de viagem" />

          <AppBarActions>
            <Button
              title="Novo motivo"
              color="primary"
              onClick={onCreateReasonTrip}
              css={{ "@mxlg": { background: "#fff" }}}
            >
              <Icon as={SvgPlus} css={{ fill: isMobile ? "#000" : "" }} />
              {!isMobile && <Text>Novo motivo</Text>}
            </Button>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container css={{ pt: "$10", pb: "$20", "@mxlg": { p: "$3" }}}>
        <LazyList
          gap="6"
          items={data}
          isLoading={isLoading}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não possui motivos de viagem cadastrados</Text>
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination
            activePage={currentPage}
            pagesCount={lastPage}
            setActivePage={onGoToPage}
          />
        </Container>
      </Footer>
    </Fragment>
  );
}
