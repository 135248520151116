import type { CustomerEmployee } from "~/application/types";
import { DateFormats, format } from "~/application/utils/date-functions";
import { asCPF } from "~/application/utils/mask-functions";
import { asPhoneNumber } from "~/utils/mask.utils";

export type EditableCustomerEmployee = CustomerEmployee & {
  group: { uuid: string; name: string };
  canCreateUser?: boolean;
  newPassword?: string;
  confirmedNewPassword?: string;
  isAdmin: boolean;
  isApprover: boolean;
};

const DEFAULT_FORM_DATA: Partial<EditableCustomerEmployee> = {
  name: "",
  lastName: "",
  phone: "",
  email: "",
  cpf: "",
  rg: "",
  gender: "",
  birthDate: "",
  registration: "",
  position: "",
  passportNumber: "",
  passportExpiration: "",
  loyaltyLatam: "",
  loyaltyAzul: "",
  loyaltyGol: "",
  customerId: "",
  group: {},
  profile: {},
  policyId: "",
  outsourced: false,
  canCreateUser: false,
  isAdmin: false,
  isApprover: false,
} as EditableCustomerEmployee;

export function createCustomerEmployee(editable: EditableCustomerEmployee): CustomerEmployee {
  return {
    ...editable,
  };
}

export function editCustomerEmployee(data?: CustomerEmployee): Partial<EditableCustomerEmployee> {
  if (!data) return DEFAULT_FORM_DATA;

  const isApprover = !!data.profile?.isApprover;
  const isAdmin = data.profile?.name?.toUpperCase() === "MASTER" && isApprover;

  return {
    ...data,
    group: { name: data.group?.description, uuid: data.group?.uuid },
    cpf: asCPF(data.cpf),
    phone: asPhoneNumber(data.phone),
    canCreateUser: !!data.userId,
    isApprover,
    isAdmin,
    birthDate: format(data.birthDate, DateFormats.SMALL_DATE),
  } as EditableCustomerEmployee;
}
