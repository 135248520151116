export enum ReportingCenterTab {
  ALL = "todos",
  EXPENSES = "despesas",
  AIRWAY = "aereo",
  ROAD = "rodoviario",
  HOTEL = "hotel",
  VEHICLE = "carro",
}

export const REPORTING_CENTER_MENU_TABS = [
  { title: "Todos", id: ReportingCenterTab.ALL },
  { title: "Aéreo", id: ReportingCenterTab.AIRWAY },
  { title: "Rodoviário", id: ReportingCenterTab.ROAD },
  { title: "Hotel", id: ReportingCenterTab.HOTEL },
  { title: "Carro", id: ReportingCenterTab.VEHICLE },
  { title: "Despesas", id: ReportingCenterTab.EXPENSES },
];

export const createSelectOptions = <T extends { uuid: string;[key: string]: any }>(
  items: T[] = [],
  label = "name"
): T[] => [
    { [label]: "Marcar todos", uuid: "" } as T,
    { [label]: "Desmarcar todos", uuid: "" } as T,
    ...items,
  ];