import { useQuery } from "@tanstack/react-query";
import { useReducer } from "react";
import { useParams } from "react-router-dom";
import { QueryKeys } from "~/application/constants/queryKeys";
import { Actions, Order, UserContext } from "~/application/types";
import { orderMessageService, orderService } from "~/application/usecases";
import { orderHistoryService } from "~/application/usecases/OrderHistory";
import { QueryTimes } from "~/constants/queryTimes";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { OrderTab } from "~/presentation/shared/utils";
import { logError } from "~/presentation/shared/utils/errors";
import { OrderProvider } from "../../../../../presentation/shared/hooks/useOrder/OrderContext";
import { useOrderTab } from "../../../../../presentation/shared/hooks/useOrderTab";
import { OrderContainer } from "./OrderContainer";
import { useAccountabilityExpenses } from "./hooks/useAccountabilityExpenses";
import { useOrderItems } from "./hooks/useOrderItems";
import { userIsOrderApprover } from "./utils";
import { BrokenPolicyJustificationProvider } from "./views/OrderItem/hooks/BrokenPolicyJustificationContext";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";

const LOG_TAG = "Order/OrderPage";

const SNACKBAR_MESSAGES = {
  LOAD_ORDER_ERROR_MESSAGE: "Falha ao carregar pedido",
  LOAD_ORDER_MESSAGES_ERROR_MESSAGE: "Falha ao carregar mensagens do pedido",
  LOAD_ORDER_HISTORY_ERROR_MESSAGE: "Falha ao carregar histórico do pedido",
} as const;

export function OrderPage() {
  const { orderId } = useParams() as { orderId: string };
  const { user, profile, contexts } = useUser();
  const tabValue = useOrderTab();
  const isAgency = user.context === UserContext.Agency;

  const [orderState, orderDispatch] = useReducer(
    (state: Order, action: { type: string; payload: Order }) => {
      switch (action.type) {
        case "update":
          return {
            ...state,
            ...action.payload,
          };
        default:
          return state;
      }
    },
    {
      issuer: { uuid: "" },
      itemsIncluded: {},
    } as Order
  );

  const {
    data: order,
    isFetching: isFetchingOrder,
    refetch: refetchOrder,
  } = useQuery([QueryKeys.ORDERS, orderId], async () => await orderService.findById(orderId), {
    staleTime: QueryTimes.SMALL,
    enabled: !!orderId,
    onSuccess: (data) => orderDispatch({ type: "update", payload: data }),
    onError: (error) => {
      logError({
        logTag: LOG_TAG,
        error,
        defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ORDER_ERROR_MESSAGE,
      });
    },
  });

  const orderReducer = {
    state: orderState,
    dispatch: orderDispatch,
  };

  const isUserIssuer = () => {
    const issuerId = order?.issuer?.uuid;

    if (!issuerId) return false;

    return issuerId === user.profiles.agency.uuid || issuerId === user.profiles.customer.uuid;
  };

  const userIsIssuer = isUserIssuer();

  const canViewOrderHistory = useVerifyActions({
    actions: [Actions.ViewAllOrders],
    contexts,
    profile,
  });

  const { isLoading: isLoadingCancellationConditions, refetch: refetchCancellationConditions } =
    useQuery(
      [QueryKeys.ORDER_TAX_CANCELLATION, orderId],
      async () => await orderService.taxCancellation(orderId),
      {
        staleTime: QueryTimes.SMALL,
        refetchOnMount: "always",
        enabled: false,
        onError: (error) => {
          logError({
            error,
            logTag: LOG_TAG,
            defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ORDER_ERROR_MESSAGE,
          });
        },
      }
    );

  const canFetchOrderHistory =
    !!orderId && (userIsIssuer || userIsOrderApprover(order) || canViewOrderHistory || isAgency);

  const {
    data: orderHistory,
    isLoading: isLoadingOrderHistory,
    refetch: refetchOrderHistory,
  } = useQuery([QueryKeys.ORDER_HISTORY], async () => await orderHistoryService.get(orderId), {
    staleTime: QueryTimes.SMALLEST,
    refetchOnMount: "always",
    enabled: canFetchOrderHistory,
    onError: (error) => {
      logError({
        logTag: LOG_TAG,
        error,
        defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ORDER_HISTORY_ERROR_MESSAGE,
      });
    },
  });

  const { data: orderMessages, isLoading: isLoadingOrderMessages } = useQuery(
    [QueryKeys.ORDER_MESSAGES, orderId],
    async () => await orderMessageService.find(orderId),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnWindowFocus: true,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ORDER_MESSAGES_ERROR_MESSAGE,
        });
      },
    }
  );

  const orderItemsState = useOrderItems({
    orderId,
    order,
    enabled: tabValue === OrderTab.ITEMS,
    isLoadingCancellationConditions,
    refetchOrder,
    refetchOrderHistory,
    refetchCancellationConditions,
  });

  const accountabilityExpensesState = useAccountabilityExpenses({
    order,
    enabled: tabValue === OrderTab.ACCOUNTABILITY_EXPENSES,
  });

  return (
    <BrokenPolicyJustificationProvider order={order}>
      <OrderProvider order={order} orderReducer={orderReducer}>
        <OrderContainer
          user={user}
          profile={profile}
          contexts={contexts}
          isLoadingOrderMessages={isLoadingOrderMessages}
          orderMessages={orderMessages || []}
          isLoading={isFetchingOrder}
          isLoadingOrderHistory={isLoadingOrderHistory}
          orderHistory={orderHistory || []}
          activeTab={tabValue}
          order={order}
          orderItemsState={orderItemsState}
          accountabilityExpensesState={accountabilityExpensesState}
        />
      </OrderProvider>
    </BrokenPolicyJustificationProvider>
  );
}
