import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "~/application/theme/routes";
import { Airport, Flight, FlightOption, PolicyParamsSlug, UserContext } from "~/application/types";
import { StringUtils } from "~/application/utils";
import { path } from "~/application/utils/navigator-functions";
import { dialogService } from "~/components/DialogStack";
import { useCreateAirwayBooking } from "~/presentation/Booking/BookingAirway/hooks/useCreateAirwayBooking";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { FlightDetailsDialog } from "~/presentation/shared/views/FlightDetailsDialog";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { BookingAirwaySteps } from "../../../constants";
import { useFlightsPage } from "../../../contexts/FlightsPageContext";
import { AirwayCartUI } from "./AirwayCartUI";
import { AirwayCartSectionProps } from "./types";
import { calculateAirwayBookingCartPrice } from "./utils";
import { useRemakeSearch } from "~/core/shared/contexts/remakeSearchContext";
import { AirwayBookingDispatchActionType } from "../../../hooks/useAirwayReducer/types";
import { useBrokenPolicyJustification } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/hooks/BrokenPolicyJustificationContext";
import { CheckOrderDuplicityDialog } from "~/presentation/shared/views/CheckOrderDuplicityDialog/CheckOrderDuplicityDialog";
import { FlightSelected } from "~/presentation/Booking/BookingAirway/types";

interface OnCreateAirwayBookingParams {
  isCombined: boolean;
  goFlightSelected: FlightSelected | null;
  goFlight?: Flight | null;
}

export const AirwayCartSection: FC<AirwayCartSectionProps> = ({
  issuerId,
  isShowingCart,
  setIsShowingCart,
  deleteOrderAirway,
}) => {
  const { user, contexts } = useUser();

  const customerId = contexts.customer?.uuid;
  const { data, currentStep, setState } = useRemakeSearch();

  const { brokenPolicyItems, scrollToSection } = useBrokenPolicyJustification();

  const navigate = useNavigate();

  const { airwayReducer, bookingStep, flightQuery, isOneWay, order, isManyStretch } =
    useFlightsPage();

  const isAgencyAsCustomer = user.context === UserContext.Agency && contexts.customer;

  const isPenultimateStep = bookingStep.currentIndex === bookingStep.steps.length - 2;

  const isLastStep = bookingStep.currentIndex === bookingStep.steps.length - 1;

  const currentbookingStep = bookingStep.currentIndex;

  const { bookingState } = airwayReducer;
  const lowerFareViolated = {
    departure: Boolean(
      bookingState.goFlightSelected?.flightOption?.violatedPolicies?.some(
        (policy) => policy.policyType === PolicyParamsSlug.LOWER_FARE
      )
    ),
    arrival: Boolean(
      bookingState.returnFlightSelected?.flightOption?.violatedPolicies?.some(
        (policy) => policy.policyType === PolicyParamsSlug.LOWER_FARE
      )
    ),
  };

  const {
    create: createAirwayBooking,
    isCreating: isCreateAirwayBooking,
    checkDuplicity,
    isLoadindCheckDuplicity,
  } = useCreateAirwayBooking({
    issuerId,
    customerId,
    lowerFareViolated,
    flightQuery: {
      adultQuantity: flightQuery.data?.adultQuantity as number,
      childrenQuantity: flightQuery.data?.childrenQuantity as number,
      dateGoSelected: flightQuery.data?.stretch.at(currentbookingStep)?.outboundDate as Date,
      dateReturnSelected: flightQuery.data?.stretch.at(currentbookingStep)?.returnDate as Date,
      destinationSelected: flightQuery.data?.stretch.at(currentbookingStep)?.destination as Airport,
      originSelected: flightQuery.data?.stretch.at(currentbookingStep)?.origin as Airport,
      reasonTrip: flightQuery.data?.reasonTrip,
    },
  });

  const isProceedDisabled = useMemo(() => {
    if (isCreateAirwayBooking || (isAgencyAsCustomer && !issuerId && isPenultimateStep)) {
      return true;
    }

    if (isLoadindCheckDuplicity) {
      return true;
    }

    const currentStep = bookingStep.currentIndex;

    switch (bookingStep.current) {
      case BookingAirwaySteps.SELECT_GO_TICKET:
        return !bookingState.goFlightSelected;
      case BookingAirwaySteps.SELECT_RETURN_TICKET:
        return !isOneWay && !bookingState.returnFlightSelected;
      case BookingAirwaySteps.SELECT_TRAVELERS:
        return (
          bookingState.travelers.length <
          (flightQuery.data?.adultQuantity || 0) + (flightQuery.data?.childrenQuantity || 0)
        );
      case `${currentStep}`:
        return !bookingState.stretch.at(currentStep);
      default:
        return false;
    }
  }, [
    bookingState,
    isOneWay,
    flightQuery,
    bookingStep,
    isCreateAirwayBooking,
    issuerId,
    isAgencyAsCustomer,
    isPenultimateStep,
    isLoadindCheckDuplicity,
  ]);

  const adultQuantity = useMemo<number>(
    () => flightQuery.data?.adultQuantity ?? 0,
    [flightQuery.data]
  );

  const childrenQuantity = useMemo<number>(
    () => flightQuery.data?.childrenQuantity ?? 0,
    [flightQuery]
  );

  const { adultIndividualValue, childIndividualValue, feeItems, totalValue } = useMemo(() => {
    const goFlightSelected = bookingState.goFlightSelected?.flightOption ?? null;

    const returnFlightSelected = bookingState.returnFlightSelected?.flightOption ?? null;

    const stretchs = bookingState.stretch.map((stretch) => stretch.flightOption);

    return calculateAirwayBookingCartPrice(
      goFlightSelected,
      returnFlightSelected,
      adultQuantity,
      childrenQuantity,
      stretchs
    );
  }, [bookingState, adultQuantity, childrenQuantity, flightQuery]);

  const displayPassengersText = useMemo(() => {
    return StringUtils.formatSentence(
      [adultQuantity, "adulto", "adultos"],
      [childrenQuantity, "criança", "crianças"]
    );
  }, [adultQuantity, childrenQuantity]);

  const isConfirmationEnabled = useMemo(
    () => bookingStep.current === BookingAirwaySteps.CONFIRMATION,
    [bookingStep.current]
  );

  const isTravelerEnabled = useMemo(
    () => bookingStep.current === BookingAirwaySteps.SELECT_TRAVELERS,
    [bookingStep.current]
  );

  const isReturnEnabled = useMemo(
    () => bookingStep.current === BookingAirwaySteps.SELECT_RETURN_TICKET,
    [bookingStep.current]
  );

  const isGoEnabled = useMemo(
    () => bookingStep.current === BookingAirwaySteps.SELECT_GO_TICKET,
    [bookingStep.current]
  );

  const onNextStep = useCallback(async () => {
    if (isConfirmationEnabled) return;
    if (bookingStep.currentIndex === 0) {
      airwayReducer.dispatch({ type: AirwayBookingDispatchActionType.TRAVELERS, payload: [] });
    }
    bookingStep.setStep(1 + bookingStep.currentIndex);
  }, [bookingStep, isConfirmationEnabled]);

  const onCreateAirwayBooking = useCallback(
    async ({ isCombined, goFlightSelected, goFlight }: OnCreateAirwayBookingParams) => {
      dialogService.showDialog(
        <LoadingDialog message="Efetuando sua reserva, isto pode demorar um pouco" />
      );

      const combindedGoFlightOption = goFlight?.options.find(
        (o) => o.code === goFlightSelected?.flightOption.code
      );

      const orderId = await createAirwayBooking({
        goFlight: {
          flight: goFlight as Flight,
          flightOption: combindedGoFlightOption ?? (goFlightSelected?.flightOption as FlightOption),
          unflownsAirway: goFlightSelected?.unflownAirway,
        },
        returnFlight: bookingState.returnFlightSelected && {
          flight: bookingState.returnFlightSelected.flight,
          flightOption: bookingState.returnFlightSelected.flightOption,
          unflownsAirway: bookingState?.returnFlightSelected.unflownAirway,
        },
        travelers: bookingState.travelers.map((i) => i.traveler),
        isCombined,
        stretchs: bookingState.stretch,
      });

      if (data?.airway?.length) {
        data.airway[currentStep].airwayOrderUuids.forEach((orderUuid) => {
          const itemAirway = order?.items.airway?.flights.find(
            (flight) => flight.uuid === orderUuid
          );
          if (itemAirway?.tracker === "Gerando Localizador" || !itemAirway?.tracker)
            deleteOrderAirway({ itemId: orderUuid });
        });
        setState({
          currentStep: currentStep + 1,
        });
        if (currentStep + 1 !== data?.airway.length) {
          bookingStep.setStep(0);
          airwayReducer.dispatch({ type: AirwayBookingDispatchActionType.CLEAR });
          return;
        }
      }

      dialogService.popDialog();

      setState({
        currentStep: 0,
        finishStep: {
          airway: true,
        },
      });

      navigate(path(routes.Orders.Order.root, { orderId }));
    },
    [bookingState, createAirwayBooking, navigate]
  );

  const onOpenDetails = useCallback((item: Flight) => {
    dialogService.showDialog(<FlightDetailsDialog data={item} flightOrigin={item.flightOrigin} />);
  }, []);

  const onCheckDuplicity = useCallback(async () => {
    if (!bookingState.goFlightSelected && bookingState.stretch.length < 1) return;

    const getBrokenIds = ({
      isViolated,
      flightId,
      optionId,
      stretch,
    }: {
      isViolated: boolean;
      flightId?: number;
      optionId?: number;
      stretch?: number;
    }) => {
      return isViolated ? `${flightId || ""}-${optionId || ""}${stretch ? `-${stretch}` : ""}` : "";
    };

    const items = !bookingState?.stretch.length
      ? [
          getBrokenIds({
            isViolated: !!bookingState.goFlightSelected?.flight.violatedPolicies?.length,
            flightId: bookingState.goFlightSelected?.flight.id,
            optionId: bookingState.goFlightSelected?.flightOption.id,
          }),

          bookingState.returnFlightSelected?.flight.violatedPolicies?.length
            ? `${bookingState.returnFlightSelected?.flight.id || ""}${
                bookingState.returnFlightSelected?.flight.id ? "-" : ""
              }${bookingState.returnFlightSelected?.flightOption.id || ""}`
            : "",
        ]
          .filter((id) => id)
          .filter((item) => !brokenPolicyItems.some((bp) => bp.uuid === item))
      : bookingState?.stretch
          ?.map((stre) =>
            getBrokenIds({
              isViolated: !!stre?.flight.violatedPolicies?.length,
              flightId: stre?.flight.id,
              optionId: stre?.flightOption.id,
              stretch: (stre.index || 0) + 1,
            })
          )
          .filter((id) => id)
          .filter((item) => !brokenPolicyItems.some((bp) => bp.uuid === item));

    if (items.length) {
      return scrollToSection(items);
    }

    const isCombined =
      !!bookingState.returnFlightSelected &&
      bookingState.returnFlightSelected?.combinedFlight?.hash ===
        bookingState.returnFlightSelected?.oneWayFlight.hash;

    const goFlightSelected = bookingState.goFlightSelected;
    const goFlight = isCombined ? goFlightSelected?.combinedFlight : goFlightSelected?.oneWayFlight;

    const data = isManyStretch
      ? await Promise.all(
          bookingState.stretch?.map((stretch) =>
            checkDuplicity({
              goFlight: { flight: stretch.flight },
              returnFlight: null,
              passengers: bookingState.travelers.map((i) => i.traveler.uuid),
            })
          )
        ).then((data) => data.flat())
      : await checkDuplicity({
          goFlight: { flight: goFlight as Flight },
          returnFlight: bookingState.returnFlightSelected && {
            flight: bookingState.returnFlightSelected.flight,
          },
          passengers: bookingState.travelers.map((i) => i.traveler.uuid),
        });

    if (data.length) {
      return dialogService.showDialog(
        <CheckOrderDuplicityDialog
          data={data}
          onConfirm={async () => {
            dialogService.popDialog();
            await onCreateAirwayBooking({ isCombined, goFlightSelected, goFlight });
          }}
          onCancel={() => dialogService.popDialog()}
        />
      );
    }

    await onCreateAirwayBooking({ isCombined, goFlightSelected, goFlight });
  }, [bookingState, onCreateAirwayBooking, brokenPolicyItems]);

  return (
    <AirwayCartUI
      totalValue={totalValue}
      adultIndividualValue={adultIndividualValue}
      childIndividualValue={childIndividualValue}
      isShowingCart={isShowingCart}
      feeItems={feeItems}
      isReturnEnabled={isReturnEnabled}
      isLastStep={isLastStep}
      passengersText={displayPassengersText}
      isProceedDisabled={isProceedDisabled}
      isConfirmationEnabled={isConfirmationEnabled}
      goFlight={bookingState.goFlightSelected?.flight}
      stretch={bookingState.stretch}
      returnFlight={bookingState.returnFlightSelected?.flight}
      isCombined={bookingState.isCombined}
      isTravelerEnabled={isTravelerEnabled}
      onNextStep={onNextStep}
      onOpenDetails={onOpenDetails}
      setIsShowingCart={setIsShowingCart}
      isGoEnabled={isGoEnabled}
      onCreateAirwayBooking={onCheckDuplicity}
    />
  );
};

AirwayCartSection.displayName = "AirwayCartSection";
