import { useFormContext } from "react-hook-form";

import { GenerateReport, ShoppingReportData } from "~/application/usecases/Reports/types";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { SvgDownload } from "~/components/Icon/icons";
import { Card, CardBody } from "~/components/Card";
import { Button } from "~/components/Button";
import { Report } from "../utils/reports";
import { Grid } from "~/components/Grid";
import { Text } from "~/components/Text";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";

export interface ReportsListProps {
  data: Report[];
  onGenerateReport: (data: GenerateReport) => void;
  onGenerateGeneralReport: (data: ShoppingReportData) => void;
}

export function ReportsList({ data, onGenerateReport, onGenerateGeneralReport }: ReportsListProps) {
  const { contexts: { customer } } = useUser();

  const { handleSubmit } = useFormContext<GenerateReport>();

  const generateReport = (data: GenerateReport, report: Report) => {
    if (report.reportType === "general") {
      return onGenerateGeneralReport({
        ...data,
        customer: { ...customer, name: customer.tradingName },
        branchs: data.branches,
        markAllBranchs: data.areAllBranchesSelected,
      } as ShoppingReportData);
    }

    onGenerateReport({ ...data, reportType: report.reportType, customer });
  };

  return (
    <Card css={{ mt: "$10" }}>
      <CardBody>
        <Grid gap="6" columns={{ "@initial": "3", "@mxlg": "1" }}>
          {data.map((item, i) => (
            <Card key={`${item.title}-${i}`}>
              <CardBody css={{ height: "100%", justifyContent: "space-between", gap: "$6" }}>
                <Flex direction="column" gap="2">
                  <Text fw="600">{item.title}</Text>
                  <Text size="3" variant="dark" css={{ lineHeight: "1.4" }}>{item.description}</Text>
                </Flex>
                <Button type="submit" form="generate-report" onClick={handleSubmit((data) => generateReport(data, item))}>
                  <Icon as={SvgDownload} />
                  Baixar
                </Button>
              </CardBody>
            </Card>
          ))}
        </Grid>
      </CardBody>
    </Card>
  );
}