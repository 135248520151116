import { useState } from "react";
import { Order, OrderStatus, Traveler } from "~/application/types";
import { DateUtils } from "~/application/utils";
import { Avatar } from "~/components/Avatar";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgDownload, SvgInfo } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { DataColItem } from "~/components/List";
import { Text } from "~/components/Text";
import { AdicionalTravelerInfo } from "~/presentation/shared/components/AdicionalTravelerInfo";
import { DownloadOrderVoucher } from "~/presentation/shared/components/DownloadOrderVoucher";
import { LoadingModal } from "~/presentation/shared/components/LoadingModal";
import { useDownload } from "~/presentation/shared/hooks/useDownload";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { useOrder } from "~/presentation/shared/hooks/useOrder";
import { getVoucherDownloadUrl } from "~/presentation/shared/utils";
import { asCPF } from "~/utils/mask.utils";

export interface TravelerListItemProps {
  data: Traveler;
}

export function TravelerListItem({ data, ...props }: TravelerListItemProps) {
  const { order } = useOrder();
  const customerDateOfBirth = DateUtils.smallDateFormat(data.dateBirth);
  const onOpenMoreInfoDialog = () => {
    dialogService.showDialog(<AdicionalTravelerInfo travelerId={data.uuid} order={order} />);
  };

  const [isDownloadingVoucher, setIsDownloadingVoucher] = useState(false);
  const { onDownloadIndividual } = useDownload({ logTag: data.fullName });

  const toggleIsDownloadingVoucher = () => {
    setIsDownloadingVoucher((old) => !old);
  };

  const isMobile = useMobile();
  const orderStatus = order?.status;
  return (
    <Card {...props}>
      <Flex
        direction={{ "@mxlg": "column" }}
        css={{
          "@mxlg": {
            p: 15,
          },
        }}
        as={CardBody}
        gap="4"
      >
        <Avatar
          css={{
            "@mxlg": {
              display: "none",
            },
          }}
          src={data.avatarUrl}
          size="lg"
        />

        {isMobile ? (
          <DataColItem
            headerTitle=""
            data={
              <Text size="3" css={{ fw: "600" }}>
                {data.fullName}
              </Text>
            }
          />
        ) : (
          <DataColItem
            headerTitle="Nome"
            data={
              <Text
                size="2"
                css={{
                  fw: "600",
                  "@mxlg": {
                    ml: "$40",
                  },
                }}
              >
                {data.fullName}
              </Text>
            }
          />
        )}

        <DataColItem
          headerTitle="CPF"
          data={
            <Text size="2" css={{ fw: "600" }}>
              {asCPF(data.cpf)}
            </Text>
          }
        />

        <DataColItem
          headerTitle="RG"
          data={
            <Text size="2" css={{ fw: "600" }}>
              {data.rg}
            </Text>
          }
        />

        <DataColItem
          headerTitle="Data de nascimento"
          data={
            <Text size="2" css={{ fw: "600" }}>
              {DateUtils.smallDateFormat(data.dateBirth)}
            </Text>
          }
        />

        {data.group?.description && (
          <DataColItem
            headerTitle="Grupo"
            data={
              <Text size="2" css={{ fw: "600" }}>
                {data.group?.description}
              </Text>
            }
          />
        )}

        <DataColItem
          headerTitle="Função"
          data={
            <Text size="2" css={{ fw: "600" }}>
              {data.position}
            </Text>
          }
        />
        {orderStatus === OrderStatus.ISSUED && (
          <DataColItem
            headerTitle="Baixar voucher"
            data={
              <>
                <DownloadOrderVoucher
                  icon={null}
                  onDownload={() =>
                    onDownloadIndividual({ order: order as Order, customerEmployeeId: data.uuid })
                  }
                  downloadUrl={getVoucherDownloadUrl(order)}
                  onDownloaded={toggleIsDownloadingVoucher}
                  onIconClick={toggleIsDownloadingVoucher}
                >
                  <IconButton
                    css={{
                      size: "$8",
                      background: "#0064C5",
                      opacity: 0.9,
                      "&:hover": {
                        background: "#0064C5",
                        opacity: 1,
                      },
                    }}
                  >
                    <SvgDownload />
                  </IconButton>
                </DownloadOrderVoucher>
              </>
            }
          />
        )}
        <Button variant="tertiary" onClick={onOpenMoreInfoDialog}>
          <Icon as={SvgInfo} />
        </Button>
      </Flex>
      <LoadingModal isOpen={isDownloadingVoucher} message="Baixando voucher do pedido" />
    </Card>
  );
}

TravelerListItem.displayName = "TravelerListItem";
