import { JudgementStatus, OrderApprover, OrderStatus } from "~/application/types";
import { Card } from "~/components/Card";
import { CartHeader } from "~/components/Cart";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { H6 } from "~/components/Typography";
import { useOrder } from "~/presentation/shared/hooks/useOrder/useOrder";
import { getApprovalStatus } from "~/presentation/shared/utils/approval-model-functions/functions";
import { groupBy } from "~/utils/object.utils";

export function ApproversList() {
  const { order } = useOrder();

  const approvers = order?.approvalModel?.approvers?.filter(({ uuid, isSelfApprover }) => {
    if (order.issuer.uuid === uuid && !isSelfApprover) {
      return false;
    }

    return true;
  });

  const approvalModel = order?.approvalModel;
  const canShowApprovers =
    [OrderStatus.REJECTED, OrderStatus.ON_APPROVAL, OrderStatus.APPROVED].includes(
      order?.status as OrderStatus
    ) &&
    approvalModel &&
    approvalModel?.approvalRequests &&
    Object.keys(approvalModel?.approvalRequests).length > 0 &&
    (approvers?.length as number) > 0;

  const levelEntries = Object.entries(groupBy(approvers ?? [], "level"));

  if (!canShowApprovers) {
    return null;
  }

  return (
    <Card
      css={{
        width: "100%",
        borderTopLeftRadius: "$none",
        borderTopRightRadius: "$none",
        border: "1px solid $neutrals-light",
        "@mxlg": {
          backgroundColor: "#003161",
          color: "#fff",
          borderBottomLeftRadius: "$md",
          borderBottomRightRadius: "$md",
        },
      }}
    >
      <CartHeader
        css={{
          backgroundColor: "$primary-light",
          color: "$primary-dark",
        }}
      >
        <Text>Aprovadores</Text>
      </CartHeader>

      {levelEntries
        .sort(([a], [b]) => {
          return Number(a) - Number(b);
        })
        .map(([level, approvers], index) => {
          return (
            <Flex
              key={`${level}-${index}`}
              direction="column"
              css={{
                borderTop:
                  index + 1 < levelEntries.length ? "1px solid $neutrals-light" : undefined,
                borderBottom: "1px solid $neutrals-light",
                p: "$6",
              }}
            >
              <H6 css={{ mb: "$2" }}>Nível {level}</H6>

              {(approvers as OrderApprover[]).map((approver) => {
                type Variant = "neutral" | "success-light" | "error-light";

                const variants: Record<JudgementStatus, Variant> = {
                  [JudgementStatus.APPROVED]: "success-light" as const,
                  [JudgementStatus.DISAPPROVED]: "error-light" as const,
                };

                let variant: Variant = "neutral";

                const approvalStatus = approver.approvalStatus;

                if (approvalStatus in variants) {
                  variant = variants[approvalStatus];
                }

                return (
                  <Flex
                    key={approver.uuid}
                    gap="4"
                    align="center"
                    css={{
                      py: "$2",
                      "@mxlg": {
                        px: "0",
                      },
                    }}
                  >
                    <Col>
                      <Text>{approver.name}</Text>
                    </Col>

                    <Tag variant={variant}>
                      <Text>{getApprovalStatus(approver.approvalStatus)}</Text>
                    </Tag>
                  </Flex>
                );
              })}
            </Flex>
          );
        })}
    </Card>
  );
}
