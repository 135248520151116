import { FC, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DropdownMenuIndicator } from "~/application/components/DropdownMenu";
import {
  expenseMenuItems,
  orderMenuItems,
  searchMenuItems,
  settingsMenuItems,
} from "~/application/data/menuItems";
import { routes } from "~/application/theme/routes";
import { NavMenuModule, filterMenuItems } from "~/application/utils/menu-functions";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgChevronDown, SvgGear, SvgMetric, SvgOrder, SvgPerson, SvgSearch, SvgTicket } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { Actions, UserContext } from "~/application/types";
import { NavMenuItem } from "./NavMenuItem";
import SvgAnalytics from "~/components/Icon/icons/Analytics";
import { AgencyEmployeeType } from "~/application/types";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";
import { IconButton } from "~/components/IconButton";
import { customerService } from "~/application/usecases";
import { useQuery } from "@tanstack/react-query";
import { logError } from "~/presentation/shared/utils/errors";
import { QueryTimes } from "~/constants";
import { dialogService } from "~/components/DialogStack";
import { AdditionalInfo } from "~/core/modules/Agency/pages/AgencyOrderPage/components/AdditionalInfoDialog";
import { CustomerInfoDialog } from "~/core/modules/Agency/pages/AgencyOrderPage/components/CustomerInfoDialog";
import { getServiceIcon } from "~/presentation/shared/utils";
import { AdditionalInfoProps } from "~/core/modules/Agency/pages/AgencyOrderPage";
import { Skeleton } from "~/components/Skeleton";
import { NavLink } from "~/components/Link";

const LOAD_CUSTOMER_ADITIONALINFO_ERROR_MESSAGE = "Erro ao carregar informações do cliente"

const LOG_TAG = "Customer/CustomerPage/useCostCenters";

export const NavMenu: FC = () => {
  const { user, userActions, profile, contexts } = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  const activeMenu = useMemo<NavMenuModule>(() => {
    if (location.pathname.startsWith("/busca")) return "search";
    if (location.pathname.startsWith("/pedidos")) return "order";
    if (location.pathname.startsWith("/despesas")) return "expenses";
    if (location.pathname.startsWith("/configuracoes")) return "settings";
    if (location.pathname.startsWith("/dashboard")) return "dashboard";
    if (location.pathname.startsWith("/central-de-relatorios")) return "reporting-center";
  }, [location.pathname]);

  const {
    data: additionalInfo,
    isLoading: isLoadingAdditionalInfo,
  } = useQuery(
    [contexts.customer],
    async () => await customerService.findById(contexts?.customer.uuid ?? "",
      { withApprovers: true, withIssuers: true, withAdditionalInfo: true }
    ),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      enabled: !!contexts?.customer,
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage:
            LOAD_CUSTOMER_ADITIONALINFO_ERROR_MESSAGE,
        });
      },
    }
  );

  const dialogAdditionalInfo = useCallback(({ label, obs }: AdditionalInfoProps) => {
    return dialogService.showDialog(<AdditionalInfo label={label} obs={obs} />)
  }, [])

  const customerInfoDialog = useCallback(() => {
    return dialogService.showDialog(<CustomerInfoDialog approvers={additionalInfo?.approvers} issuers={additionalInfo?.issuers} />)
  }, [additionalInfo])

  const dashboardMenus = useMemo(() => {
    const dashboardLink = user.customer?.dashboardLink || contexts?.customer?.dashboardLink;
    const canShowDashboard = useVerifyActions({
      actions: [Actions.ViewDashboard],
      contexts,
      profile,
    });

    if (!dashboardLink || !canShowDashboard) return;

    return (
      <Flex onClick={() => navigate(routes.Dashboard.root)}>
        <NavMenuItem isActive={activeMenu === "dashboard"} menuItems={[]}>
          <Icon as={SvgAnalytics} />
          <Text>Dashboard</Text>
        </NavMenuItem>
      </Flex>
    );
  }, [activeMenu, userActions, contexts, user, navigate]);

  const crmMenus = useMemo(() => {
    const isManager = user.type === AgencyEmployeeType.MANAGER;
    const isValidAgencyEmployee = isManager && user.context === UserContext.Agency;

    if (isValidAgencyEmployee && contexts.customer) {
      return !isLoadingAdditionalInfo ? (
        <Flex align="center">
          <IconButton size="lg" onClick={customerInfoDialog}>
            <Icon as={SvgPerson} />
          </IconButton>
          {Object.entries(additionalInfo?.additionalInfo || []).map(([key, value]) => (
            <IconButton
              key={key}
              size="lg"
              onClick={() => dialogAdditionalInfo({ label: key, obs: value.obs })}
            >
              <Icon as={getServiceIcon(key)} />
            </IconButton>
          ))}
        </Flex>
      ) : (
        <Flex align="center">
          <Skeleton css={{ width: "200px", height: "25px" }} />
        </Flex>
      )
    }

    return null;
  }, [activeMenu, userActions, contexts, user, additionalInfo, isLoadingAdditionalInfo]);

  const settingsMenus = useMemo(() => {
    const items = filterMenuItems(settingsMenuItems, userActions, contexts);
    const isManager = user.type === AgencyEmployeeType.MANAGER;
    const isValidAgencyEmployee = isManager && user.context === UserContext.Agency;
    const isValidCustomerEmployee = user.context === UserContext.Customer;

    if (items.length && (isValidAgencyEmployee || isValidCustomerEmployee)) {
      return (
        <NavMenuItem isActive={activeMenu === "settings"} menuItems={items}>
          <Icon as={SvgGear} />
          <Text>Configurações</Text>
          <DropdownMenuIndicator>
            <Icon as={SvgChevronDown} />
          </DropdownMenuIndicator>
        </NavMenuItem>
      );
    }
  }, [activeMenu, userActions, contexts, user]);

  const searchMenus = useMemo(() => {
    const items = filterMenuItems(searchMenuItems, userActions, contexts);

    if (items.length === 0) return;

    return (
      <NavMenuItem isActive={activeMenu === "search"} menuItems={items}>
        <Icon as={SvgSearch} />
        <Text>Busca</Text>
        <DropdownMenuIndicator>
          <Icon as={SvgChevronDown} />
        </DropdownMenuIndicator>
      </NavMenuItem>
    );
  }, [activeMenu, userActions, contexts]);

  const orderMenus = useMemo(() => {
    const items = filterMenuItems(orderMenuItems, userActions, contexts, user);

    if (items.length === 0) return;

    return (
      <NavMenuItem isActive={activeMenu === "order"} menuItems={items}>
        <Icon as={SvgTicket} />
        <Text>Pedidos</Text>
        <DropdownMenuIndicator>
          <Icon as={SvgChevronDown} />
        </DropdownMenuIndicator>
      </NavMenuItem>
    );
  }, [activeMenu, userActions, contexts, user]);

  const expenseMenus = useMemo(() => {
    const items = filterMenuItems(expenseMenuItems, userActions, contexts);

    if (items.length === 0 || profile?.isExpenseApprover) return;

    return (
      <NavMenuItem isActive={activeMenu === "expenses"} menuItems={items}>
        <Icon as={SvgOrder} />
        <Text>Despesas</Text>
        <DropdownMenuIndicator>
          <Icon as={SvgChevronDown} />
        </DropdownMenuIndicator>
      </NavMenuItem>
    );
  }, [activeMenu, userActions, profile, contexts]);

  const reportingCenterMenu = useMemo(() => {
    const canShowReportingCenter = useVerifyActions({
      actions: [Actions.ViewPurchaseReport],
      contexts,
      profile,
    });

    if (!canShowReportingCenter || !contexts?.customer) return;

    return (
      <NavLink to={routes.ReportingCenter.root} title="Central de relatórios">
        <NavMenuItem isActive={activeMenu === "reporting-center"}>
          <Icon as={SvgMetric} />
          <Text>Central de relatório</Text>
        </NavMenuItem>
      </NavLink>
    );
  }, [activeMenu, userActions, contexts]);

  return (
    <Flex as="nav" gap="4">
      {searchMenus}
      {orderMenus}
      {expenseMenus}
      {settingsMenus}
      {reportingCenterMenu}
      {dashboardMenus}
      {crmMenus}
    </Flex>
  );
};

NavMenu.displayName = "NavMenu";
