import type { Hotel, HotelQuery } from "~/application/types";
import { decompressString } from "~/presentation/shared/utils/string-functions/functions";
import { daysBetween } from "~/utils/date.utils";
import { formatSentence } from "~/utils/string.utils";

export function formatHotelAddress(hotel: Hotel): string {
  return `${hotel?.address.street} - ${[
    hotel?.address.quarter,
    hotel?.address.cityName,
    hotel?.address.country,
    hotel?.address.zipCode,
  ]
    .filter(Boolean)
    .join(", ")}`;
}

export function formatAccommodationText(hotelQuery: HotelQuery, index: number): string {
  return formatSentence(
    [
      daysBetween(hotelQuery?.checkOutDate as Date, hotelQuery?.checkInDate as Date),
      "diária",
      "diárias",
    ],
    [hotelQuery?.accommodations[index]?.adultQuantity, "adulto", "adultos"],
    [hotelQuery?.accommodations[index]?.guestsChildren?.length ?? 0, "criança", "crianças"]
  );
}

export function formatDailyGuestText(hotelQuery: HotelQuery): string {
  return formatSentence(
    [
      daysBetween(hotelQuery?.checkOutDate as Date, hotelQuery?.checkInDate as Date),
      "diária",
      "diárias",
    ],
    [hotelQuery?.accommodations?.reduce((p, c) => p + c.adultQuantity, 0), "adulto", "adultos"],
    [
      hotelQuery?.accommodations?.reduce((p, c) => p + c.guestsChildren.length, 0),
      "criança",
      "crianças",
    ]
  );
}

export function displayGuests({
  roomQuantity,
  guestQuantity,
}: {
  roomQuantity: number;
  guestQuantity: number;
}): string {
  return formatSentence(
    [roomQuantity, "quarto", "quartos"],
    [guestQuantity, "hóspede", "hóspedes"]
  );
}

export function getHotelDataFromUrl() {
  const hotelData = window.location.search.split("hotel=")?.at(1)?.split("&policy=")?.at(0) ?? "";
  return JSON.parse(decompressString(hotelData));
}

export function getPolicyFromUrl() {
  const policy = window.location.search.split("policy=")?.at(1) ?? "";
  return JSON.parse(decodeURIComponent(policy));
}
