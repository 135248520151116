import { useQueries } from "@tanstack/react-query";
import { useMemo } from "react";
import { QueryKeys, QueryTimes } from "~/application/constants";
import { Road } from "~/application/types";
import { roadQueryService } from "~/application/usecases";
import { UseQueryGoRoadsOptions, UseQueryGoRoadsReturn } from "./types";

export function useQueryGoRoads({
  roadQueryLinks,
  enabled,
  retryCount,
}: UseQueryGoRoadsOptions): UseQueryGoRoadsReturn {
  
  const roadQueries = useQueries({
    queries:
      roadQueryLinks?.links.departure.map((link) => ({
        enabled,
        retry: retryCount,
        retryDelay: roadQueryLinks.waitTime,
        staleTime: QueryTimes.LONG,
        queryKey: [QueryKeys.QUERY_ROADS_GO, link],
        queryFn: () => roadQueryService.findGo(link),
      })) ?? [],
  });

  const data = useMemo(
    () => roadQueries.reduce<Road[]>((p, c) => [...p, ...(c.data ?? [])], []),
    [roadQueries]
  );

  const isLoading = useMemo(
    () => roadQueries.some((q) => q.isFetching),
    [roadQueries]
  );

  const isAvailable = useMemo(
    () => roadQueries.some((q) => !q.isFetching),
    [roadQueries]
  );

  return {
    data,
    isLoading,
    isAvailable,
  };
}
