import { Route, Routes } from "react-router-dom";
import { routes } from "~/application/theme/routes";
import { ChangeCustomerEmployeePasswordPage } from "~/core/modules/Customer/pages/ChangeCustomerEmployeePasswordPage";
import { AppContainer } from "~/presentation/core/containers/AppContainer";
import { LoginPage } from "../core/modules/Auth/pages/LoginPage";
import { RedefinePasswordPage } from "~/core/modules/Auth/pages/RedefinePasswordPage";
import { AgencyRouter } from "./AgencyRouter";
import { BookingRouter } from "./BookingRouter";
import { ConfigurationRouter } from "./ConfigurationRouter";
import { ExpenseRouter } from "./ExpenseRouter";
import { OrderRouter } from "./OrderRouter";
import { PreventAuth } from "./helpers/PreventAuth";
import { Redirect } from "./helpers/Redirect";
import { RequireAuth } from "./helpers/RequireAuth";
import { DashboardRouter } from "./DashboardRouter";
import { InstantlyApprovalAuthPage } from "~/core/modules/Auth/pages/InstantlyApprovalAuthPage/InstantlyApprovalAuthPage";
import { ProfileRouter } from "./ProfileRouter";
import { PrivacyPolicyPage } from "~/core/modules/PrivacyPolicy/pages/PrivacyPolicyPage";
import { ReportingCenterRouter } from "./ReportingCenterRouter";

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/politica-de-privacidade" element={<PrivacyPolicyPage />} />
      <Route path="/" element={<PreventAuth />}>
        <Route path="entrar" element={<LoginPage />} />
        <Route path="redefinir-senha" element={<RedefinePasswordPage />} />
        <Route path="senha-aprovar" element={<InstantlyApprovalAuthPage />} />
      </Route>

      <Route path="/" element={<RequireAuth />}>
        <Route path="*" element={<AppContainer />}>
          <Route path={routes.Configuration.route} element={<ConfigurationRouter />} />
          <Route path={routes.Dashboard.route} element={<DashboardRouter />} />
          <Route path={routes.Orders.route} element={<OrderRouter />} />
          <Route path={routes.Expenses.route} element={<ExpenseRouter />} />
          <Route path={routes.Booking.route} element={<BookingRouter />} />
          <Route path={routes.Agencies.route} element={<AgencyRouter />} />
          <Route path={routes.Profile.route} element={<ProfileRouter />} />
          <Route path={routes.ReportingCenter.route} element={<ReportingCenterRouter />} />
        </Route>

        <Route path="*" element={null} />
      </Route>

      <Route path="/alterar-senha/:csrfToken" element={<ChangeCustomerEmployeePasswordPage />} />

      <Route index element={<Redirect />} />
    </Routes>
  );
};

AppRouter.displayName = "AppRouter";
