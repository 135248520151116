import {
  AccountabilityExpense,
  AdvanceOrderStatus,
  RejectedItem,
  TravelerAdvance,
} from "~/application/types";
import { Avatar } from "~/components/Avatar";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgAlert, SvgCheck, SvgClose, SvgEdit, SvgFile, SvgTrash } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { CounterTabBadge } from "~/components/Tabs";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { H5 } from "~/components/Typography";
import { getUnsatisfiedPolicyMessage } from "~/core/shared/utils/order.utils";
import * as DateUtils from "~/utils/date.utils";
import * as MaskUtils from "~/utils/mask.utils";
import {
  ReasonRejectedItem,
  ReasonRejectedProps,
} from "../../../../../../../../../presentation/shared/components/ReasonRejectedItem";
import { EXPENSE_CATEGORY_ICONS } from "../../../../../../../ExpenseCategory/pages/ExpenseCategoriesPage/utils";
import { getExpenseIdentifier } from "../../../../utils";

export type AccountabilityExpenseListItemProps = {
  data: AccountabilityExpense;
  userIsExpenseApprover?: boolean;
  rejectedItems?: RejectedItem[];
  approveItem?: (expense: AccountabilityExpense) => void;
  reproveItem?: (expense: AccountabilityExpense) => void;
  onEditClick: (data: AccountabilityExpense) => void;
  onDeleteClick: (data: AccountabilityExpense) => void;
  onShowVoucher: (data: AccountabilityExpense) => void;
};

export function AccountabilityExpenseListItem({
  data,
  rejectedItems,
  approveItem,
  reproveItem,
  userIsExpenseApprover,
  onEditClick,
  onDeleteClick,
  onShowVoucher,
}: AccountabilityExpenseListItemProps) {
  const icon = EXPENSE_CATEGORY_ICONS.find(({ slug }) => slug === data.expenseCategory.icon)?.icon;

  const expenseDate = DateUtils.displayDate(
    data.expenseDate as Date,
    DateUtils.DateFormats.LONG_DATE
  );

  const reasonRejected = data.reasonRejected;
  const advanceOrder = data.advanceOrder;
  const advanceOrderStatus = advanceOrder?.status;

  const getCardBorderCss = () => {
    let borderCss = "";
    const { status } = advanceOrder as TravelerAdvance;
    const advanceIsDisapproved = status === AdvanceOrderStatus.DISAPPROVED;

    if (advanceIsDisapproved && data.reasonRejected) {
      borderCss = "1px solid red";
    }

    return borderCss;
  };

  return (
    <Card css={{ border: getCardBorderCss() }}>
      <CardBody>
        <Flex direction="column" gap="6">
          <H5>#{getExpenseIdentifier(data)} </H5>
          <Flex justify="between">
            <Text as="p" variant="dark" css={{ fw: "500", lineHeight: 1.25 }}>
              {expenseDate}
            </Text>

            {!userIsExpenseApprover &&
              (advanceOrderStatus === AdvanceOrderStatus.OPEN ||
                advanceOrderStatus === AdvanceOrderStatus.DISAPPROVED) &&
              !window.location.href.includes("aprovações") && (
                <Box>
                  <Tooltip content={<TooltipLabel>Editar Despesa</TooltipLabel>}>
                    <IconButton size="md" onClick={() => onEditClick(data)}>
                      <Icon as={SvgEdit} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip content={<TooltipLabel>Deletar Despesa</TooltipLabel>}>
                    <IconButton size="md" onClick={() => onDeleteClick(data)}>
                      <Icon as={SvgTrash} />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
          </Flex>

          <Flex gap="2">
            <Flex gap="2">
              <Tag variant="info-light">
                {icon && <Icon as={icon} />}
                <Text
                  size="2"
                  css={{
                    p: "$0",
                    fw: "600",
                    display: "block",
                    mr: icon ? "$2" : "$0",
                  }}
                >
                  {data.expenseCategory.name}
                </Text>
              </Tag>
            </Flex>

            {data.unsatisfiedPolicies.length > 0 && (
              <>
                <Tooltip
                  content={
                    <Flex direction="column" gap="2" css={{ p: "$2" }}>
                      <Text variant="black" size="3" css={{ fw: 700 }}>
                        Esta opção está fora da politica de compra.
                      </Text>
                      {data.unsatisfiedPolicies.map(({ uuid, typeValue, value }) => (
                        <Text size="3" as="p" key={uuid}>
                          {getUnsatisfiedPolicyMessage(typeValue, value)}
                        </Text>
                      ))}
                      <Text size="3" as="p">
                        Ao selecionar esta opção, você precisará criar uma justificativa para o seu
                        aprovador.
                      </Text>
                    </Flex>
                  }
                  variant="white"
                >
                  <Tag variant="warning-light">
                    <Icon size="sm" as={SvgAlert} />
                    <Text>FORA DA POLÍTICA</Text>
                  </Tag>
                </Tooltip>
              </>
            )}
          </Flex>

          <Row align="center">
            <Col>
              <Text size="2" variant="dark">
                Tipo de Despesa
              </Text>
              <Text size={{ "@mxlg": "3" }} css={{ fw: "600", display: "block", mt: "$2" }}>
                {data.expenseType.name}
              </Text>
            </Col>

            <Col>
              <Text size="2" variant="dark">
                Estabelecimento
              </Text>
              <Text size={{ "@mxlg": "3" }} css={{ fw: "600", display: "block", mt: "$2" }}>
                {data.company}
              </Text>
            </Col>

            <Col sz="auto">
              <Flex gap="4">
                <Col>
                  <Text size="2" variant="dark">
                    Valor
                  </Text>
                  <Text css={{ fw: "600", display: "block", mt: "$2" }}>
                    {MaskUtils.asCurrency(data.value)}
                  </Text>
                </Col>

                {advanceOrder?.status === AdvanceOrderStatus.APPROVING && userIsExpenseApprover && (
                  <>
                    <Col>
                      <Tooltip content={<TooltipLabel>Reprovar Despesa</TooltipLabel>}>
                        <IconButton
                          css={{
                            background:
                              rejectedItems && rejectedItems.length > 0 ? "$error-base" : "",
                          }}
                          onClick={() => reproveItem && reproveItem(data)}
                          size="md"
                        >
                          <Icon as={SvgClose} />
                        </IconButton>
                      </Tooltip>
                    </Col>

                    <Col>
                      <Tooltip content={<TooltipLabel>Aprovar Despesa</TooltipLabel>}>
                        <IconButton
                          size="md"
                          css={{
                            background:
                              rejectedItems && rejectedItems.length > 0
                                ? "$neutrals-base"
                                : "$success-base",
                          }}
                          onClick={() => approveItem && approveItem(data)}
                        >
                          <Icon as={SvgCheck} />
                        </IconButton>
                      </Tooltip>
                    </Col>
                  </>
                )}

                <Col>
                  {data.voucherPath && (
                    <Flex>
                      <Tooltip content={<TooltipLabel>Visualizar Comprovante</TooltipLabel>}>
                        <IconButton
                          size="md"
                          css={{ m: "$0", width: "auto" }}
                          onClick={() => onShowVoucher(data)}
                        >
                          <Icon as={SvgFile} />
                        </IconButton>
                      </Tooltip>
                      <CounterTabBadge css={{ alignSelf: "start", p: "$1" }}>1</CounterTabBadge>
                    </Flex>
                  )}
                </Col>
              </Flex>
            </Col>
          </Row>
        </Flex>
      </CardBody>

      {data.justification && data.justification?.length > 0 && (
        <>
          <Divider />

          <CardBody>
            <Flex gap="3">
              <Avatar />
              <Flex gap="2" direction="column">
                <Text size="3" css={{ fw: 600 }}>
                  Você
                </Text>
                <Text size="2" variant="dark">
                  {DateUtils.getTimeFromNow(data.expenseDate as Date)}
                </Text>
                <Text as="p" variant="darkest" css={{ mt: "$2", lineHeight: 1.5 }}>
                  {data.justification}
                </Text>
              </Flex>
            </Flex>
          </CardBody>
        </>
      )}

      {reasonRejected && (
        <ReasonRejectedItem
          css={{ p: "$5" }}
          reasonRejected={reasonRejected as ReasonRejectedProps["reasonRejected"]}
        />
      )}
    </Card>
  );
}

AccountabilityExpenseListItem.displayName = "AccountabilityExpenseListItem";
