import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { Project } from "~/application/types";
import { projectService } from "~/application/usecases/Project";
import { dialogService } from "~/components/DialogStack";
import { QueryKeys } from "~/constants";
import { ProjectDialog } from "../views/Projects/components/ProjectDialog";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { snackbarService } from "~/components/SnackbarStack";
import { queryClient } from "~/services/queryClient";
import { useDebounce } from "use-debounce";
import { log } from "~/utils/log";

const LOG_TAG = "Customer/CustomerPage/useProjects";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar projetos",
  CREATE_SUCCESS_MESSAGE: "Novo projeto adicionado",
  CREATE_ERROR_MESSAGE: "Falha ao criar projeto",
  UPDATE_SUCCESS_MESSAGE: "Projeto atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar projeto",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar projeto",
  ACTIVATE_SUCCESS_MESSAGE: "Projeto ativado",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar projeto",
  INACTIVATE_SUCCESS_MESSAGE: "Projeto inativado",
} as const;

export type UseProjectsOptions = {
  customerId: string;
  enabled: boolean;
  isActive?: boolean;
  isOnlyWithPhase?: boolean;
  withApprovalModels?: boolean;
};

export type UseProjectsResult = {
  data?: Project[];
  currentPage: number;
  lastPage: number;
  isLoading: boolean;
  search: string;
  onCreateProject: () => void;
  onToggleActive: (item: Project) => void;
  onUpdateProject: (item: Project) => void;
  onGoToPage: (value: number) => void;
  fetchProjects: (name: string) => Promise<Project[]>;
  onSearch: (text: string) => void;
};

export const useProjects = ({
  customerId,
  enabled,
  isActive,
  isOnlyWithPhase,
  withApprovalModels,
}: UseProjectsOptions): UseProjectsResult => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState("");

  const [searchDebounced] = useDebounce(search, 700);

  const { data, isLoading } = useQuery(
    [
      QueryKeys.PROJECTS,
      customerId,
      enabled,
      currentPage,
      searchDebounced,
      isActive,
      isOnlyWithPhase,
      withApprovalModels,
    ],
    () =>
      projectService.getAll({
        customerId,
        page: currentPage,
        name: searchDebounced,
        isActive,
        isOnlyWithPhase,
        withApprovalModels,
      }),
    {
      enabled,
      staleTime: 1000 * 20,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: mutateCreateProject } = useMutation(
    [QueryKeys.PROJECTS, customerId],
    async ({ name, code }: Project) => await projectService.create({ customerId, name, code }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating Project(${item.uuid})`);

        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Criando novo projeto" />);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created Project(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.PROJECTS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE, "error");
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutate: mutateToggleState } = useMutation(
    [QueryKeys.PROJECTS, customerId],
    async ({ active, uuid: projectId }: Project) =>
      await projectService.toggleActive({ active, projectId }),
    {
      onMutate: (data) => {
        log.i(LOG_TAG, `Updating Project(${data.uuid})`);

        dialogService.showDialog(
          <LoadingDialog message={`${data.active ? "Desativando" : "Ativando"} projeto`} />
        );
      },
      onSuccess: (_, data) => {
        log.i(LOG_TAG, `Successfully updated Project(${data.uuid})`);

        queryClient.invalidateQueries([QueryKeys.PROJECTS, customerId, enabled]);

        snackbarService.showSnackbar(
          data.active
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error, item) => {
        dialogService.popDialog();
        snackbarService.showSnackbar(
          `Erro ao ${item.active ? "desativar" : "ativar"} o projeto`,
          "error"
        );
      },
    }
  );

  const { mutate: mutateUpdateProjects } = useMutation(
    async ({ uuid: projectId, name, code }: Project) =>
      await projectService.update({ projectId, name, code }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Project(${item.uuid})`);

        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Atualizando projeto" />);
      },
      onSuccess: (item) => {
        log.i(LOG_TAG, `Successfully updated Project(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.PROJECTS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE, "error");
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const fetchProjects = useCallback(
    async (name: string) => {
      const response = await projectService.getAll({
        customerId,
        name,
        isOnlyWithPhase,
        withApprovalModels,
        isActive,
      });
      return response.data;
    },
    [customerId, isOnlyWithPhase, withApprovalModels, isActive]
  );

  const onOpenCreateModalProject = useCallback(
    () => dialogService.showDialog(<ProjectDialog onSubmit={mutateCreateProject} isNew />),
    [mutateCreateProject]
  );

  const onOpenUpdateModalProject = useCallback(
    (project: Project) =>
      dialogService.showDialog(
        <ProjectDialog defaultData={project} onSubmit={mutateUpdateProjects} />
      ),
    [mutateUpdateProjects]
  );

  const onSearchProject = useCallback((text: string) => {
    setSearch(text);
    setCurrentPage(1);
  }, []);

  return {
    data: data?.data,
    currentPage,
    lastPage: data?.meta.last_page || 0,
    isLoading,
    search: search,
    onCreateProject: onOpenCreateModalProject,
    onToggleActive: mutateToggleState,
    onGoToPage: setCurrentPage,
    onUpdateProject: onOpenUpdateModalProject,
    fetchProjects,
    onSearch: onSearchProject,
  };
};
