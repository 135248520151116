import { useCallback } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";

import { QueryKeys } from "~/application/constants";
import { orderAirwayService, orderService } from "~/application/usecases";
import { ICreateOrderAirwayData } from "~/application/usecases/OrderAirway";
import { log } from "~/application/utils/log";
import { snackbarService } from "~/components/SnackbarStack";
import { useLogTag } from "~/presentation/core/contexts/LogTagContext";
import { useCreateOrder } from "~/presentation/shared/hooks/useCreateOrder";
import { queryClient } from "~/services/queryClient";
import { CreateOrderAirwayParams, UseCreateOrderAirwayReturn } from "./types";
import { FlightQuery } from "~/application/types";
import { useBrokenPolicyJustification } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/hooks/BrokenPolicyJustificationContext";
import { useFlightsPage } from "../../pages/FlightsPage/contexts/FlightsPageContext";
import { logError } from "~/presentation/shared/utils/errors";

const SNACKBAR_MESSAGES = {
  CREATE_ERROR_MESSAGE: "Falha ao efetuar sua reserva",
  LOAD_ERROR_MESSAGE: "Falha ao carregar pedido",
  CHECK_ORDER_DUPLICITY_ERROR_MESSAGE: "Falha ao checar possível duplicidade de reserva",
} as const;

export type UseCreateAirwayBookingProps = {
  issuerId: string;
  customerId: string;
  lowerFareViolated: { departure: boolean; arrival: boolean };
  flightQuery: FlightQuery | null;
};

export function useCreateAirwayBooking({
  issuerId,
  customerId,
  lowerFareViolated,
  flightQuery,
}: UseCreateAirwayBookingProps): UseCreateOrderAirwayReturn {
  const { LOG_TAG } = useLogTag();
  const [searchParams] = useSearchParams();
  const { brokenPolicyItems } = useBrokenPolicyJustification();
  const { isInternational, isInternationalCombined } = useFlightsPage();
  const orderId = searchParams.get("orderId");

  const { data: order } = useQuery(
    [QueryKeys.ORDERS, orderId],
    () => orderService.findById(orderId!),
    {
      staleTime: 1000 * 60 * 30, // 30 minutes
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
      enabled: !!orderId,
    }
  );

  const { create: createOrderAsync } = useCreateOrder({
    customerId,
    issuerId,
    reasonTripId: flightQuery?.reasonTrip?.uuid,
  });

  const { mutateAsync: mutateCreateAirwayAsync } = useMutation(
    (params: ICreateOrderAirwayData) => orderAirwayService.create(params),
    {
      onSuccess: (_, params) => {
        log.i(LOG_TAG, `Successfully created OrderAirway(order:${params.orderId})`);

        queryClient.invalidateQueries([QueryKeys.ORDERS, params.orderId]);
      },
      onError: (error) => {
        log.e(LOG_TAG, error);
        7;

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutateAsync: checkDuplicity, isLoading: isLoadindCheckDuplicity } = useMutation({
    mutationFn: orderAirwayService.checkDuplicity,
    onError(error) {
      logError({
        error,
        logTag: LOG_TAG,
        defaultErrorMessage: SNACKBAR_MESSAGES.CHECK_ORDER_DUPLICITY_ERROR_MESSAGE,
      });
    },
  });

  const createAirwayBookingAsync = useCallback(
    async ({
      goFlight,
      returnFlight,
      isCombined,
      travelers,
      stretchs,
    }: CreateOrderAirwayParams) => {
      let orderTarget = order;
      const orderId = sessionStorage.getItem("orderId");

      if (!orderTarget && !orderId) {
        orderTarget = await createOrderAsync({
          travelersIds: travelers.map((t) => t.uuid),
        });
      }

      if (orderId) {
        sessionStorage.removeItem("orderId");
      }

      if (stretchs.length > 1) {
        const allStretches = stretchs.map(async (stretch, index) => {
          const goJustification = brokenPolicyItems.find(
            (policy) =>
              policy.uuid === `${stretch.flight.id}-${stretch.flightOption.id}-${index + 1}`
          )?.justification;

          return mutateCreateAirwayAsync({
            orderId: orderTarget?.uuid ?? (orderId as string),
            goFlight: stretch,
            returnFlight,
            isCombined,
            lowerFareViolated,
            passengers: travelers.map((traveler) => traveler.uuid),
            brokenPolicyJustification: { goJustification, returnJustification: "" },
          });
        });

        await Promise.all(allStretches);
      } else {
        const goJustification = brokenPolicyItems.find(
          (policy) => policy.uuid === `${goFlight.flight.id}-${goFlight.flightOption.id}`
        )?.justification;

        const returnJustification = brokenPolicyItems.find(
          (policy) => policy.uuid === `${returnFlight?.flight.id}-${returnFlight?.flightOption.id}`
        )?.justification;

        await mutateCreateAirwayAsync({
          orderId: orderTarget?.uuid ?? (orderId as string),
          goFlight,
          returnFlight,
          isCombined,
          lowerFareViolated,
          passengers: travelers.map((traveler) => traveler.uuid),
          brokenPolicyJustification: { goJustification, returnJustification },
          isInternational: isInternational,
          isInternationalCombined: isInternationalCombined,
        });
      }

      return orderTarget?.uuid ?? (orderId as string);
    },
    [
      order,
      mutateCreateAirwayAsync,
      createOrderAsync,
      lowerFareViolated,
      brokenPolicyItems,
      isInternational,
      isInternationalCombined,
    ]
  );

  const { isLoading, mutateAsync: mutateCreateAsync } = useMutation(
    (params: CreateOrderAirwayParams) => createAirwayBookingAsync(params),
    {
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE, "error");
      },
    }
  );

  return {
    create: mutateCreateAsync,
    isCreating: isLoading,
    checkDuplicity,
    isLoadindCheckDuplicity,
  };
}
