import React, { forwardRef, type ComponentProps, useEffect, useRef } from "react";
import { styled } from "~/application/theme";

export type TextAreaStandardProps = {
  id?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  defaultValue?: any;
  name?: string;
  title?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  maxLength?: number;
  minLength?: number;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  value?: any;
  size?: "sm" | "md";
}

export type TextAreaBaseProps = ComponentProps<typeof TextAreaBaseRoot> & TextAreaStandardProps & {
  textAreaProps?: ComponentProps<typeof TextAreaBaseTextArea>;
  leftAdornment?: React.ReactElement;
  rightAdornment?: React.ReactElement;
};

const TextAreaBaseTextArea = styled("textarea", {
  all: "unset",
  boxSizing: "border-box",
  font: "inherit",
  letterSpacing: "inherit",
  color: "currentColor",
  border: 0,
  margin: "0",
  minWidth: "0",
  width: "100%",
  minHeight: "auto",
  overflow: "hidden",
  resize: "none",
});

const TextAreaBaseRoot = styled("div", {
  boxSizing: "border-box",
  position: "relative",
  display: "flex",
  gap: "$2",
  padding: "$6",
  cursor: "text",
  borderRadius: "$md",
  transition: "$normal",
  color: "$neutrals-darkest",
  fontSize: "$md",
  lineHeight: "$fontSizes$md",
  backgroundColor: "$neutrals-white",
  "&::after": {
    content: "",
    pointerEvents: "none",
    position: "absolute",
    inset: 0,
    margin: 0,
    borderColor: "$neutrals-light",
    borderRadius: "inherit",
    borderStyle: "solid",
    borderWidth: "1px",
    transition: "$faster",
  },
  "&:hover::after": {
    borderWidth: "2px",
    borderColor: "$primary-base",
  },
  "&[data-focused=true]": {
    boxShadow: "$focus-md",
  },
  "&[data-filled=true]::after": {
    borderWidth: "2px",
    borderColor: "$primary-base",
  },
  "&[data-dirty=true]": {
    backgroundColor: "$error-light",
    "&::after": {
      borderWidth: "2px",
      borderColor: "$error-base",
    }
  },
  "&[data-disabled=true]": {
    opacity: "$disabled",
    pointerEvents: "none",
    cursor: "default",
  },
});

export const TextAreaBase = forwardRef<
  React.ElementRef<typeof TextAreaBaseRoot>,
  TextAreaBaseProps
>(({ id, autoComplete, autoFocus, disabled, defaultValue, name, onBlur, onChange, onClick, onFocus, onKeyDown, onKeyUp, maxLength, minLength, placeholder, readOnly, required, value, textAreaProps, leftAdornment, rightAdornment, ...props }, forwardedRef) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [focused, setFocused] = React.useState(false);
  const [filledState, setFilled] = React.useState(false);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [value]);

  const handleFocus: React.FocusEventHandler<HTMLTextAreaElement>  = (event) => {
    if (onFocus) onFocus(event);
    setFocused(true);
  };

  const handleBlur: React.FocusEventHandler<HTMLTextAreaElement> = (event) => {
    if (onBlur) onBlur(event);
    setFocused(false);
  };

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    if (onChange) onChange(event);
    setFilled(event.target.value.length > 0);
  };

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (textAreaRef.current && event.currentTarget === event.target) {
      textAreaRef.current.focus();
    }
    if (onClick) onClick(event);
  };

  const filled = !!value?.length ?? filledState;

  return (
    <TextAreaBaseRoot
      data-focused={focused}
      data-filled={filled}
      data-disabled={disabled}
      onClick={handleClick}
      ref={forwardedRef}
      {...props}
    >
      {leftAdornment}
      <TextAreaBaseTextArea
        id={id}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        disabled={disabled}
        defaultValue={defaultValue}
        minLength={minLength}
        maxLength={maxLength}
        name={name}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        value={value}
        {...textAreaProps}
        ref={textAreaRef}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
      />
      {rightAdornment}
    </TextAreaBaseRoot>
  );
});

TextAreaBase.displayName = "TextAreaBase";
