import { Popover as ReactTinyPopover } from "react-tiny-popover";

export interface PopoverProps {
  popoverOpen?: boolean;
  popoverAnchor: JSX.Element;
  popoverContent: JSX.Element;
  onClickOutside?: () => void;
}

export function Popover({
  popoverOpen = false,
  popoverAnchor,
  popoverContent,
  onClickOutside,
}: PopoverProps) {
  return (
    <ReactTinyPopover
      isOpen={popoverOpen}
      padding={8}
      positions={["bottom"]}
      align="start"
      onClickOutside={onClickOutside}
      content={popoverContent}
      containerStyle={{
        zIndex: "99999",
        position: "absolute",
        pointerEvents: "auto",
      }}
    >
      {popoverAnchor}
    </ReactTinyPopover>
  );
}
