import { HotelRoom } from "~/application/types";
import { CardBody } from "~/components/Card";
import { CartHeader } from "~/components/Cart";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { Caption } from "~/components/Typography";
import { asCurrency } from "~/utils/mask.utils";
import { formatNumber } from "~/utils/string.utils";
import { Dispatch, Fragment, ReactNode, SetStateAction, useMemo } from "react";
import { useBookingHotel } from "../../../../Booking/modules/BookingHotel/contexts/BookingHotelContext";
import { Card, CardSectionTitle } from "../../../components/Card";
import { SimpleListItem } from "../../../components/SimpleListItem";
import { Icon } from "~/components/Icon";
import { SvgChevronDown, SvgChevronUp } from "~/components/Icon/icons";
import { Button } from "~/components/Button";
import { BookingHotelSteps } from "~/core/modules/Booking/modules/BookingHotel/pages/HotelDetailsPage/utils";

type HotelBookingCartProps = {
  rooms: (HotelRoom | null)[];
  children?: ReactNode;
  isShowCart: boolean;
  setIsShowCart: Dispatch<SetStateAction<boolean>>;
  onGoNextStep: () => void;
  activeStep?: BookingHotelSteps;
  isLoadCheckDuplicity?: boolean;
};

export const HotelBookingCart = ({
  rooms = [],
  isShowCart,
  setIsShowCart,
  onGoNextStep,
  activeStep,
  isLoadCheckDuplicity,
}: HotelBookingCartProps) => {
  const { hotelQuery } = useBookingHotel();

  const isButtonDisabled = rooms.some((room) => !room) || isLoadCheckDuplicity;

  const totalPrice = useMemo(
    () =>
      rooms.reduce((prev, curr) => {
        if (curr !== null) {
          prev += curr.totalPriceWithFee;
        }
        return prev;
      }, 0),
    [rooms]
  );

  return (
    <Card
      css={{
        "@mxlg": {
          backgroundColor: "#003161",
          position: "fixed",
          left: "0",
          width: "100%",
          borderRadius: "0",
          zIndex: "$appBar",
          height: isShowCart ? "$80" : "$15",
          transition: "$slow",
          bottom: "0",
        },
      }}
    >
      <CartHeader
        css={{
          "@mxlg": {
            display: "flex",
            justifyContent: "center",
          },
        }}
      >
        <Icon
          css={{
            "@lg": {
              display: "none",
            },
          }}
          as={isShowCart ? SvgChevronDown : SvgChevronUp}
          onClick={() => setIsShowCart((prev) => !prev)}
        />
        <Text
          css={{
            "@mxlg": {
              display: "none",
            },
          }}
        >
          Sua reserva
        </Text>
      </CartHeader>

      <Flex
        as={CardBody}
        css={{
          "@mxlg": {
            width: "87%",
            margin: "0 auto",
          },
        }}
        direction="column"
        gap="4"
      >
        <Flex justify="between" align="center">
          <Caption
            css={{
              "@mxlg": {
                color: "#FFF",
              },
            }}
          >
            Valor total
          </Caption>
          <Text
            size="6"
            css={{
              fw: "600",
              "@mxlg": {
                color: "#FFF",
              },
            }}
          >
            {asCurrency(totalPrice)}
          </Text>
        </Flex>

        {hotelQuery!.accommodations.map(
          (_, roomIndex) =>
            rooms[roomIndex] && (
              <Flex justify="between" align="center" key={`cart_room_${roomIndex}`}>
                <Caption
                  css={{
                    "@mxlg": {
                      color: "#FFF",
                    },
                  }}
                >
                  Quarto {formatNumber(1 + roomIndex, 2)}
                </Caption>
                <Text
                  css={{
                    "@mxlg": {
                      color: "#FFF",
                    },
                  }}
                  size="2"
                  variant="darkest"
                >
                  {asCurrency(rooms[roomIndex]?.totalPriceWithFee || 0)}
                </Text>
              </Flex>
            )
        )}
      </Flex>

      <CardBody
        css={{
          "@mxlg": {
            margin: "70px auto",
            width: "90%",
          },
        }}
      >
        <Button onClick={onGoNextStep} disabled={isButtonDisabled}>
          <Text>
            {activeStep === BookingHotelSteps.SELECT_ROOMS
              ? "Escolher hóspede"
              : "Finalizar reserva"}
          </Text>
        </Button>
      </CardBody>

      {hotelQuery!.accommodations.map((guests, roomIndex) => {
        const room = rooms[roomIndex];
        const guestsLength = guests.adultQuantity + guests.guestsChildren.length;

        return (
          <Fragment key={`reservation_room_${roomIndex}`}>
            {room && (
              <>
                <CardSectionTitle>Quarto {formatNumber(1 + roomIndex, 2)}</CardSectionTitle>

                <SimpleListItem
                  title={room.description || ""}
                  css={{ p: "$4" }}
                  description={guestsLength > 1 ? `${guestsLength} hóspedes` : "1 hóspede"}
                />
              </>
            )}
          </Fragment>
        );
      })}
    </Card>
  );
};

HotelBookingCart.displayName = "HotelBookingCart";
