import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

import {
  ApprovalModel,
  ApprovalTypeEnum,
  Approver,
  CostCenter,
  CustomerEmployee,
  Level,
  Phase,
  Project,
} from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { Select, Switch, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { Caption, H5 } from "~/components/Typography";
import { AsyncSelect } from "../../components/AsyncSelect";
import { ApprovalLevelListItem } from "./ApprovalLevelListItem";
import { ApprovalModelDialogProps } from "./types";
import { getDefaultValues } from "./utils";
import { MultiSelectWrap } from "~/components/Input/MultiSelectWrap";
import { usePhases } from "~/core/modules/Customer/pages/CustomerPage/hooks/usePhases";
import { useProjects } from "~/core/modules/Customer/pages/CustomerPage/hooks/useProjects";
import {
  GroupEmployee,
  useCustomerEmployeesGroups,
} from "~/core/modules/Customer/pages/CustomerPage/hooks/useCustomerEmployeesGroups";
import { Flex } from "~/components/Flex";
import { group } from "console";

const ArrayTypeSchema = yup.array().of(
  yup.object().shape({
    uuid: yup.string().required(),
    name: yup.string().required(),
  })
);

const approvalModelSchema = yup.object().shape({
  applyExpense: yup.boolean().default(true),
  branchName: yup
    .object()
    .shape({
      uuid: yup.string(),
      name: yup.string(),
    })
    .nullable()
    .when("isAllBranches", {
      is: false,
      then: (schema) =>
        schema.shape({
          uuid: yup.string().required("O nome da filial é obrigatório"),
          name: yup.string().required(),
        }),
      otherwise: (schema) => schema.nullable(),
    }),
  expenseApprover: yup
    .object()
    .shape({
      uuid: yup.string(),
      name: yup.string(),
    })
    .nullable()
    .when("applyExpense", {
      is: false,
      then: (schema) =>
        schema.shape({
          uuid: yup.string().required("O aprovador de despesas é obrigatório"),
          name: yup.string().required(),
        }),
      otherwise: (schema) => schema.nullable(),
    }),
  costCenters: ArrayTypeSchema,
  phases: ArrayTypeSchema,
  projects: ArrayTypeSchema,
  groups: ArrayTypeSchema,
  groupApprovers: ArrayTypeSchema,
  isAllBranches: yup.boolean().default(true),
  levels: yup
    .array()
    .of(
      yup.object().shape({
        approvers: yup
          .array()
          .of(
            yup.object().shape({
              uuid: yup.string().required("O aprovador é obrigatório"),
              name: yup.string().required(),
              selfApprover: yup.boolean().default(false),
            })
          )
          .min(1, "Selecione pelo menos um aprovador")
          .required(),
      })
    )
    .min(1, "Selecione pelo menos um nível")
    .required(),
  name: yup.string().required("O nome é obrigatório"),
  outPolicyApprovalModel: yup
    .object()
    .shape({
      uuid: yup.string(),
      name: yup.string(),
    })
    .nullable(),
  approvalType: yup
    .string()
    .oneOf([
      ApprovalTypeEnum.PHASE,
      ApprovalTypeEnum.COST_CENTER,
      ApprovalTypeEnum.PROJECT,
      ApprovalTypeEnum.GROUP,
    ]),
});

export type ApprovalModelSchema = yup.InferType<typeof approvalModelSchema>;

type ApproverSchema = {
  uuid: string;
  name: string;
  selfApprover: boolean;
};

type LevelSchema = {
  approvers: ApproverSchema[];
};

export type OnSelectApproverParams = {
  approver: Approver;
  levelIndex: number;
};

export type ApprovalTypeSchema = {
  value: ApprovalTypeEnum;
  label: string;
};

export function ApprovalModelDialog({
  isNew,
  branches,
  approvalModels,
  isLoadingApprovalModels,
  isLoadingBranches,
  costCenters: defaultCostCenters,
  isLoadingCostCenters,
  availableApprovers,
  isLoadingAvailableApprovers,
  defaultData,
  fetchApprovalModels,
  fetchApprovers,
  fetchCostCenters,
  onCloseClick,
  onSubmit,
  onCreateApprover,
  onUpdateApprover,
  onInactivateApprover,
  customerId,
}: ApprovalModelDialogProps) {
  const [markAllCostCenters, setCanMarkAllCostCenters] = useState(false);
  const [availableCostCenters, setAvailableCostCenters] = useState(defaultCostCenters);
  const [availableApprovalType, setAvailableApprovalType] = useState(defaultData?.approvalType);
  const [isSelectApproverGroup, setSelectApproverGroup] = useState(false);
  const [isRemoveApproverGroup, setRemoveApproverGroup] = useState(false);
  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set("open", "true");
    window.history.pushState({}, "", url.toString());
  }, []);

  const updateCostCenters = useCallback(async () => {
    if (!fetchCostCenters) {
      return [];
    }

    return await fetchCostCenters("", defaultData?.uuid as string);
  }, [fetchCostCenters]);

  useEffect(() => {
    updateCostCenters().then((data) => setAvailableCostCenters(data || []));
  }, [fetchCostCenters, updateCostCenters]);

  const defaultValues = getDefaultValues({ defaultData: defaultData as ApprovalModel, isNew });
  const { control, formState, handleSubmit, watch, setValue, trigger } =
    useForm<ApprovalModelSchema>({
      defaultValues,
      resolver: yupResolver(approvalModelSchema),
    });

  const approvalTypeOptions = [
    {
      label: "Centro de custo",
      value: ApprovalTypeEnum.COST_CENTER,
    },
    {
      label: "Projeto",
      value: ApprovalTypeEnum.PROJECT,
    },
    {
      label: "Fase",
      value: ApprovalTypeEnum.PHASE,
    },
    {
      label: "Grupo",
      value: ApprovalTypeEnum.GROUP,
    },
  ] as ApprovalTypeSchema[];

  const {
    branchName,
    isAllBranches,
    applyExpense,
    levels,
    approvalType,
    projects,
    groups,
    groupApprovers,
    costCenters,
  } = watch();

  const isPhaseType = approvalType === ApprovalTypeEnum.PHASE;
  const isProjectType = approvalType === ApprovalTypeEnum.PROJECT;
  const isCostCenterType = approvalType === ApprovalTypeEnum.COST_CENTER;
  const isGroupType = approvalType === ApprovalTypeEnum.GROUP;

  const {
    data: defaultGroups,
    onSearch: fetchGroups,
    isLoading: isLoadingGroups,
  } = useCustomerEmployeesGroups({
    customerId,
    active: true,
    enabled: isGroupType,
    withApprovalModels: false,
    groupIds: groupApprovers?.map(({ uuid }) => uuid),
  });

  const {
    data: defaultApprovalGroups,
    onSearch: fetchApprovalGroups,
    onSearchEmployee: fetchApprovalGroupEmployees,
    groupEmployees: defaultApprovalGroupEmployees,
    isLoading: isLoadingApprovalGroups,
  } = useCustomerEmployeesGroups({
    customerId,
    active: true,
    enabled: isGroupType,
    groupIds: groupApprovers?.map(({ uuid }) => uuid),
  });

  const [markAllGroups, setCanMarkAllGroups] = useState(false);
  const [availableGroups, setAvailableGroups] = useState(
    defaultGroups?.map(({ uuid, description }) => ({ uuid, name: description }))
  );

  const [markAllEmployeeGroups, setCanMarkAllEmployeeGroups] = useState(false);
  const [availableEmployeeGroups, setAvailableEmployeeGroups] = useState(
    defaultApprovalGroups?.map(({ uuid, description }) => ({ uuid, name: description }))
  );

  const { data: defaultProjects, onSearch: fetchProjects } = useProjects({
    enabled: isProjectType || isPhaseType,
    isActive: true,
    customerId,
    isOnlyWithPhase: isPhaseType ? true : undefined,
  });

  const { data: defaultPhases, onSearch: fetchPhases } = usePhases({
    enabled: isPhaseType && !!projects?.at(0)?.uuid,
    projectId: projects?.at(0)?.uuid || "",
  });
  const [markAllProjects, setCanMarkAllProjects] = useState(false);
  const [availableProjects, setAvailableProjects] = useState(defaultProjects);

  const [markAllPhases, setCanMarkAllPhases] = useState(false);
  const [availablePhases, setAvailablePhases] = useState(defaultPhases);

  useEffect(() => {
    if (defaultPhases) {
      setAvailablePhases(defaultPhases);
    }
    if (defaultProjects) {
      setAvailableProjects(defaultProjects);
    }
    if (defaultGroups) {
      setAvailableGroups(
        defaultGroups.map((group) => ({ name: group.description, uuid: group.uuid }))
      );
    }
    if (defaultApprovalGroups) {
      setAvailableEmployeeGroups(
        defaultApprovalGroups.map((group) => ({ name: group.description, uuid: group.uuid }))
      );
    }
  }, [defaultPhases, defaultProjects, defaultGroups, defaultApprovalGroups]);

  const { append, update, remove, insert } = useFieldArray({
    control,
    name: "levels",
    keyName: "uuid",
  });

  const defaultApprovers = (
    isGroupType ? defaultApprovalGroupEmployees ?? [] : availableApprovers ?? []
  ).filter(
    (approver) =>
      !levels?.some((l) => l.approvers.some((a) => a.uuid === approver.uuid)) && approver.isActive
  ) as Approver[];

  const handleApproverSelection = async ({ approver, levelIndex }: OnSelectApproverParams) => {
    const level = levels.at(levelIndex);

    const hasApproverWithSameUuidInThisLevel = level?.approvers.some(
      (a) => a.uuid === approver.uuid
    );

    if (hasApproverWithSameUuidInThisLevel) {
      return;
    }

    const levelApprovers = (level?.approvers ?? []).filter((a) => a.uuid.length > 0);
    const newApprovers = [...levelApprovers, approver] as ApproverSchema[];

    update(levelIndex, { ...level, approvers: newApprovers });

    await trigger("levels");
  };

  const getApproverOptionLabel = useCallback((item: CustomerEmployee) => item.name, []);

  const getApproverOptionValue = useCallback((item: CustomerEmployee) => item.uuid, []);

  const inactivateApprovers = () => {
    defaultData?.approvers?.forEach((approver) => {
      const approverExists = levels.some(({ approvers }) =>
        approvers.some(({ uuid }) => uuid === approver.uuid)
      );

      if (!approverExists) {
        onInactivateApprover(approver.approverModelId);
      }
    });
  };

  const createOrUpdateApprovers = (levels: Level[], approvalModelId?: string) => {
    levels.forEach((level) => {
      level.approvers.forEach((approver) => {
        const currentApprover = defaultData?.approvers?.find((a) => a.uuid === approver.uuid);
        const approverData = { ...approver, approvalModelId };

        if (currentApprover) {
          return onUpdateApprover({ ...currentApprover, ...approverData });
        }

        onCreateApprover(approverData);
      });
    });
  };

  const onAddLevel = (level: Level, levelIndex: number) => {
    if (levelIndex === levels.length) {
      return append(level as LevelSchema);
    }

    insert(levelIndex, level as LevelSchema);
  };

  const onRemoveLevel = (levelIndex: number) => {
    if (levels.length === 1) {
      return;
    }

    remove(levelIndex);
  };

  const onRemoveApprover = (approver: Approver, levelIndex: number) => {
    const level = levels.at(levelIndex);
    const newApprovers = (level?.approvers ?? []).filter((a) => a.uuid !== approver.uuid);

    update(levelIndex, { ...level, approvers: newApprovers });
  };

  useEffect(() => {
    if (availableApprovalType !== approvalType) {
      setCanMarkAllCostCenters(false);
      setCanMarkAllEmployeeGroups(false);
      setCanMarkAllGroups(false);
      setCanMarkAllPhases(false);
      setCanMarkAllProjects(false);
      setValue("groupApprovers", undefined);
      setValue("groups", undefined);
      setValue("costCenters", undefined);
      setValue("phases", undefined);
      setValue("projects", undefined);
      setAvailableApprovalType(approvalType);
    }
  }, [approvalType, availableApprovalType]);

  useEffect(() => {
    if (
      isGroupType &&
      isSelectApproverGroup &&
      !!groupApprovers?.length &&
      !!defaultApprovers.length
    ) {
      const level = levels.at(0);
      update(0, { ...level, approvers: defaultApprovers as ApproverSchema[] });
      setSelectApproverGroup(false);
    }
  }, [defaultApprovers, isGroupType, isSelectApproverGroup, groupApprovers?.length]);

  const handleOnSubmit = (data: ApprovalModelSchema) => {
    const formattedLevels = levels.map((level, levelIndex) => {
      return {
        ...level,
        approvers: level.approvers.map((approver) => ({
          ...approver,
          level: levelIndex + 1,
        })),
      };
    }) as Level[];

    if (!isNew) {
      inactivateApprovers();
      createOrUpdateApprovers(formattedLevels, defaultData?.uuid);
    }

    onSubmit({
      ...data,
      levels: formattedLevels,
      markAllCostCenters,
      markAllEmployeeGroups,
      markAllGroups,
      markAllProjects,
      markAllPhases,
    });
  };

  useEffect(() => {
    if (isRemoveApproverGroup) {
      (levels as Level[]).forEach((level, levelIndex) => {
        level.approvers.forEach((approver) => {
          if (
            !groupApprovers?.find((groupApprover) => groupApprover.uuid === approver.group?.uuid)
          ) {
            onRemoveApprover(approver, levelIndex);
          }
        });
      });
      setRemoveApproverGroup(false);
    }
  }, [levels, groupApprovers, isRemoveApproverGroup]);

  return (
    <Container size="8" fixed>
      <Form
        onSubmit={handleSubmit((data) => {
          defaultData?.approvers?.forEach((approver) => onRemoveApprover(approver, approver.level));
          handleOnSubmit(data);
        })}
      >
        <FormDialog
          title={isNew ? "Novo modelo de aprovação" : "Editar modelo de aprovação"}
          negativeButton={
            <Button variant="tertiary" type="reset" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "60vh" }}>
            <Box css={{ mb: "$10" }}>
              <H5>Informações básicas</H5>
            </Box>
            <Row
              gap="6"
              css={{
                mb: "$3",
              }}
            >
              <FormControl name="approvalType" control={control} required>
                <FieldLabel>Tipo de aprovação</FieldLabel>
                <Select
                  options={approvalTypeOptions}
                  value={approvalTypeOptions.find((data) => data.value === approvalType)}
                  onChange={(data) => setValue("approvalType", data.value)}
                  placeholder="Selecione o tipo de aprovação"
                  size="sm"
                />
                {formState.errors.approvalType?.message && (
                  <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                    {formState.errors.approvalType?.message}
                  </Text>
                )}
              </FormControl>
            </Row>

            <Row gap="6">
              <Col sz="6">
                <FormControl
                  name="isAllBranches"
                  control={control}
                  required={branchName?.name?.length === 0}
                >
                  <FieldLabel>Aplica-se para todas as filiais</FieldLabel>
                  <Switch checked={isAllBranches}>
                    <Caption>{isAllBranches ? "Ativo" : "Inativo"}</Caption>
                  </Switch>
                </FormControl>
              </Col>
              <Col sz="6">
                <FormControl name="applyExpense" control={control}>
                  <FieldLabel>Aplica-se as despesas</FieldLabel>
                  <Switch checked={applyExpense}>
                    <Caption>{applyExpense ? "Ativo" : "Inativo"}</Caption>
                  </Switch>
                </FormControl>
              </Col>
              {!applyExpense && (
                <Col sz="12">
                  <FormControl name="expenseApprover" control={control} required={!applyExpense}>
                    <FieldLabel>Aprovador das Despesas</FieldLabel>
                    <Select
                      options={availableApprovers}
                      isLoading={isLoadingAvailableApprovers}
                      getOptionLabel={getApproverOptionLabel}
                      getOptionValue={getApproverOptionValue}
                      placeholder="Selecione o aprovador das despesas"
                      size="sm"
                    />
                    {formState.errors.expenseApprover?.uuid && (
                      <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                        {formState.errors.expenseApprover.uuid.message}
                      </Text>
                    )}
                  </FormControl>
                </Col>
              )}
              <Col sz="12">
                <FormControl name="name" control={control} required>
                  <FieldLabel>Nome</FieldLabel>
                  <TextInput placeholder="Digite o nome do modelo de aprovação" />
                  {formState.errors.name && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.name.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
              {!isAllBranches && (
                <Col sz="12">
                  <FormControl
                    name="branchName"
                    control={control}
                    required={!isAllBranches}
                    disabled={isAllBranches}
                  >
                    <FieldLabel>Nome da filial</FieldLabel>
                    <Select
                      disabled={isAllBranches}
                      options={branches}
                      isLoading={isLoadingBranches}
                      getOptionLabel={(item) => item.name}
                      getOptionValue={(item) => item.uuid}
                      placeholder="Selecione a filial"
                      size="sm"
                    />
                    {formState.errors.branchName?.uuid && (
                      <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                        {formState.errors.branchName.uuid.message}
                      </Text>
                    )}
                  </FormControl>
                </Col>
              )}
              {isCostCenterType && (
                <Col sz="12">
                  <FormControl name="costCenters" control={control} required>
                    <FieldLabel>Nome do centro de custo</FieldLabel>
                    <MultiSelectWrap
                      size="sm"
                      placeholder="Selecione o centro de custo"
                      isLoading={isLoadingCostCenters}
                      getOptionLabel={(item: unknown) => (item as CostCenter).name}
                      getOptionValue={(item: unknown) => (item as CostCenter).uuid}
                      options={[
                        { uuid: "", name: "Desmarcar todos" },
                        { uuid: "", name: "Marcar todos" },
                        ...(availableCostCenters || []),
                      ]}
                      onInputChange={(search: string) => {
                        if (!fetchCostCenters) {
                          return;
                        }

                        fetchCostCenters(search, defaultData?.uuid).then((costCenters) => {
                          setAvailableCostCenters((old) => {
                            if (!old) return costCenters;

                            const newCostCenters = costCenters.filter(
                              (c) => !old.some((o) => o.uuid === c.uuid)
                            );
                            return [...old, ...newCostCenters];
                          });
                        });
                      }}
                      onChange={async (costCenters: { uuid: string; name: string }[]) => {
                        if (costCenters.find(({ name }) => name === "Desmarcar todos")) {
                          setValue("costCenters", []);
                          return setCanMarkAllCostCenters(false);
                        }
                        if (costCenters.find(({ name }) => name === "Marcar todos")) {
                          setCanMarkAllCostCenters(true);
                          return setValue("costCenters", availableCostCenters || []);
                        }

                        setValue("costCenters", costCenters || []);
                        await trigger("costCenters");
                        return setCanMarkAllCostCenters(false);
                      }}
                    />
                    {formState.errors.costCenters && (
                      <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                        {formState.errors.costCenters.message}
                      </Text>
                    )}
                  </FormControl>
                </Col>
              )}
              {isGroupType && (
                <Flex direction="column" gap="3">
                  <Col sz="12">
                    <FormControl name="groups" control={control} required>
                      <FieldLabel>Grupos</FieldLabel>
                      <MultiSelectWrap
                        size="sm"
                        isLoading={isLoadingGroups}
                        placeholder="Selecione o grupo"
                        getOptionLabel={(item: unknown) => (item as CustomerEmployee).name}
                        getOptionValue={(item: unknown) => (item as CustomerEmployee).uuid}
                        options={[
                          { uuid: "", name: "Desmarcar todos" },
                          { uuid: "", name: "Marcar todos" },
                          ...(availableGroups || []),
                        ]}
                        onInputChange={fetchGroups}
                        onChange={async (newGroups: { uuid: string; name: string }[]) => {
                          if (isPhaseType && newGroups.length > 1) {
                            setValue(
                              "groups",
                              newGroups.filter(
                                (newGroup) =>
                                  !groups?.map((group) => group.uuid).includes(newGroup?.uuid)
                              )
                            );
                            await trigger("groups");
                            return setCanMarkAllGroups(false);
                          }
                          if (newGroups.find(({ name }) => name === "Desmarcar todos")) {
                            setValue("groups", []);
                            return setCanMarkAllGroups(false);
                          }
                          if (newGroups.find(({ name }) => name === "Marcar todos")) {
                            setCanMarkAllGroups(true);
                            return setValue("groups", availableGroups || []);
                          }

                          setValue("groups", newGroups || []);
                          await trigger("groups");
                          return setCanMarkAllGroups(false);
                        }}
                      />
                      {formState.errors.groups && (
                        <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                          {formState.errors.groups.message}
                        </Text>
                      )}
                    </FormControl>
                  </Col>
                  <Col sz="12">
                    <FormControl name="groupApprovers" control={control} required>
                      <FieldLabel>Grupos de aprovação</FieldLabel>
                      <MultiSelectWrap
                        size="sm"
                        placeholder="Selecione os grupos de aprovação"
                        getOptionLabel={(item: unknown) => (item as GroupEmployee).name}
                        getOptionValue={(item: unknown) => (item as GroupEmployee).uuid}
                        isLoading={isLoadingApprovalGroups}
                        options={[
                          { uuid: "", name: "Desmarcar todos" },
                          { uuid: "", name: "Marcar todos" },
                          ...(availableEmployeeGroups || []),
                        ]}
                        onInputChange={fetchApprovalGroups}
                        onChange={async (newGroupEmployees: { uuid: string; name: string }[]) => {
                          if (newGroupEmployees.find(({ name }) => name === "Marcar todos")) {
                            setCanMarkAllEmployeeGroups(true);
                            return setValue("groupApprovers", availableEmployeeGroups || []);
                          }
                          if (newGroupEmployees.length < (groupApprovers?.length || 0)) {
                            setRemoveApproverGroup(true);
                          }

                          setValue("groupApprovers", newGroupEmployees || []);
                          await trigger("groupApprovers");
                          setSelectApproverGroup(true);
                          return setCanMarkAllEmployeeGroups(false);
                        }}
                      />
                      {formState.errors.groups && (
                        <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                          {formState.errors.groups.message}
                        </Text>
                      )}
                    </FormControl>
                  </Col>
                </Flex>
              )}
              {(isPhaseType || isProjectType) && (
                <Col sz="12">
                  <FormControl name="projects" control={control} required>
                    <FieldLabel>{isPhaseType ? "Nome do projeto" : "Projetos"}</FieldLabel>
                    <MultiSelectWrap
                      size="sm"
                      placeholder={`Selecione o${isPhaseType ? "" : "s"} projeto${
                        isPhaseType ? "" : "s"
                      }`}
                      getOptionLabel={(item: unknown) => (item as Project).name}
                      getOptionValue={(item: unknown) => (item as Project).uuid}
                      options={[
                        ...(isProjectType
                          ? [
                              { uuid: "", name: "Desmarcar todos" },
                              { uuid: "", name: "Marcar todos" },
                            ]
                          : []),
                        ...(availableProjects || []),
                      ]}
                      onInputChange={fetchProjects}
                      onChange={async (newProjects: { uuid: string; name: string }[]) => {
                        if (isPhaseType && newProjects.length > 1) {
                          setValue(
                            "projects",
                            newProjects.filter(
                              (newProject) =>
                                !projects?.map((project) => project.uuid).includes(newProject?.uuid)
                            )
                          );
                          await trigger("projects");
                          return setCanMarkAllProjects(false);
                        }
                        if (newProjects.find(({ name }) => name === "Desmarcar todos")) {
                          setValue("projects", []);
                          return setCanMarkAllProjects(false);
                        }
                        if (newProjects.find(({ name }) => name === "Marcar todos")) {
                          setCanMarkAllProjects(true);
                          return setValue("projects", availableProjects || []);
                        }

                        setValue("projects", newProjects || []);
                        await trigger("projects");
                        return setCanMarkAllProjects(false);
                      }}
                    />
                    {formState.errors.projects && (
                      <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                        {formState.errors.projects.message}
                      </Text>
                    )}
                  </FormControl>
                </Col>
              )}
              {isPhaseType && (
                <Col sz="12">
                  <FormControl name="phases" control={control} required>
                    <FieldLabel>Fases</FieldLabel>
                    <MultiSelectWrap
                      size="sm"
                      placeholder="Selecione as fases"
                      getOptionLabel={(item: unknown) => (item as Phase).name}
                      getOptionValue={(item: unknown) => (item as Phase).uuid}
                      options={[
                        { uuid: "", name: "Desmarcar todos" },
                        { uuid: "", name: "Marcar todos" },
                        ...(availablePhases || []),
                      ]}
                      onInputChange={fetchPhases}
                      onChange={async (phases: { uuid: string; name: string }[]) => {
                        if (phases.find(({ name }) => name === "Desmarcar todos")) {
                          setValue("phases", []);
                          return setCanMarkAllPhases(false);
                        }
                        if (phases.find(({ name }) => name === "Marcar todos")) {
                          setCanMarkAllPhases(true);
                          return setValue("phases", availablePhases || []);
                        }

                        setValue("phases", phases || []);
                        await trigger("phases");
                        return setCanMarkAllPhases(false);
                      }}
                    />
                    {formState.errors.phases && (
                      <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                        {formState.errors.phases.message}
                      </Text>
                    )}
                  </FormControl>
                </Col>
              )}
              <Col sz="12">
                <FormControl name="outPolicyApprovalModel" control={control}>
                  <FieldLabel>Modelo de aprovação para política violada</FieldLabel>
                  <AsyncSelect
                    queryKey="fetch-approval-models"
                    defaultOptions={approvalModels ?? []}
                    fetchOptions={fetchApprovalModels}
                    isLoading={isLoadingApprovalModels}
                    getOptionLabel={(item) => item.name}
                    getOptionValue={(item) => item.uuid}
                    placeholder="Selecione o modelo de aprovação para a política violada"
                    size="sm"
                  />
                  {formState.errors.outPolicyApprovalModel?.uuid && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.outPolicyApprovalModel.uuid.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
              <Box>
                {formState.errors.levels && (
                  <Text variant="error-base" size="2" css={{ fontWeight: "bold" }}>
                    {formState.errors.levels.message}
                  </Text>
                )}

                {levels.map((level, index) => (
                  <ApprovalLevelListItem
                    css={{
                      border: "1px solid $neutrals-light",
                      mt: "$2",
                      mb: "$4",
                      px: "$6",
                      py: "$6",
                      borderRadius: "$sm",
                    }}
                    key={`level-${index}`}
                    level={level as Level}
                    index={index}
                    control={control}
                    formState={formState}
                    fetchApprovers={fetchApprovers}
                    onSearchApprover={fetchApprovalGroupEmployees}
                    defaultApprovers={defaultApprovers}
                    onSelectApprover={handleApproverSelection}
                    onAddLevel={onAddLevel}
                    onRemoveApprover={onRemoveApprover}
                    onRemoveLevel={onRemoveLevel}
                    isGroupType={isGroupType}
                  />
                ))}
              </Box>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
