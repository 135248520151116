import { Route, Routes } from "react-router-dom";

import { ReportingCenterPage } from "~/presentation/Reports/pages/ReportingCenterPage";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { RequireRole } from "./helpers/RequireRole";
import { UserContext } from "~/application/types";

export const ReportingCenterRouter = () => {
  const { contexts } = useUser();

  if (!contexts.customer) {
    return null;
  }

  return (
    <Routes>
      <Route path="*">
        <Route
          index
          element={
            <RequireRole role={UserContext.Customer}>
              <ReportingCenterPage />
            </RequireRole>
          }
        />
      </Route>
    </Routes>
  );
};

ReportingCenterRouter.displayName = "ReportingCenterRouter";
