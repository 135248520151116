import { Markdown } from "~/application/types";
import {
  AppBarActions,
  AppBarContainer,
  AppBarHeading,
} from "~/components/AppBar";
import { AppBar } from "~/components/AppBar/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { SearchBar } from "~/components/Input";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { Fragment, useCallback, useState } from "react";
import { MarkdownListItem } from "./components/MarkdownListItem";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Box } from "~/components/Box";

export interface MarkdownsContainerProps {
  isLoading: boolean;
  markdownsList: Markdown[] | undefined;
  currentPage: number;
  lastPage: number;
  searchText: string;
  setSearchText: (searchO: string) => void;
  onGoToPage: (page: number) => void;
  onCreateMarkdown: () => void;
  onEditMarkdown: (item: Markdown) => void;
  onToggleState: (data: Markdown) => void;
}

export function MarkdownsContainer({
  markdownsList,
  currentPage,
  lastPage,
  searchText,
  setSearchText,
  onGoToPage,
  onCreateMarkdown,
  onEditMarkdown,
  onToggleState,
}: MarkdownsContainerProps) {

  const isMobile = useMobile();
  const [searchInput, setSearchInput] = useState(false);
  
  const listRenderer = useCallback(
    (item: Markdown) => (
      <MarkdownListItem
        data={item}
        onToggleState={onToggleState}
        onEditClick={onEditMarkdown}
        key={item.uuid}
      />
    ),
    [onToggleState, onEditMarkdown]
  );

  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <AppBarHeading title={!searchInput ? "Markdowns"  : ""} />

          <Box
            onClick={() => setSearchInput(false)}
            css={{
              zIndex: 10000,
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(100, 100, 100, 0.5)",
              display: !searchInput ? "none" : "block",
            }}
          />


          <AppBarActions>
            <SearchBar
              search={searchText}
              onSearchChange={setSearchText}
              placeholder={!isMobile ? "Encontrar markdown" : ""}
              style={{
                width: searchInput ? "100%" : "29%",
                marginRight: "10px",
                paddingTop: "0px",
                zIndex: "10001",
              }}
              onClick={() => setSearchInput(true)}
              onBlur={() => setSearchInput(false)}
            />

            <Button title="Novo markdown" onClick={onCreateMarkdown} css={{ height: "$13", px: "$5" }}>
              <Icon as={SvgPlus} css={{ "@mxlg": { fill: "#000" }}} />
              {!isMobile && <Text>Novo markdown</Text>}
            </Button>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container css={{ pt: "$10", pb: "$20", "@mxlg": { p: "$5" }}}>
        <LazyList
          gap="6"
          items={markdownsList}
          render={listRenderer}
          skeletonQuantity={10}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              {searchText.length > 0 ? (
                <Text>Nenhum markdown corresponde aos dados informados</Text>
              ) : (
                <Text>Você não possui markdowns cadastrados</Text>
              )}
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination
            activePage={currentPage}
            pagesCount={lastPage}
            setActivePage={onGoToPage}
          />
        </Container>
      </Footer>
    </Fragment>
  );
}
