import queryString from "query-string";
import { PaginatedResource, AccessReport } from "~/application/types";
import { api } from "~/infrastructure/api";
import { ReportsDTO } from "~/infrastructure/api/dtos/ReportsDTO";
import { mapReportsDTO } from "~/infrastructure/api/mappers/mapReportsDTO";
import { DateFormats, displayDate } from "~/utils/date.utils";
import { IReportService } from "./IReportsService";
import { FindAccessReportsData, GenerateReport, ShoppingReportData } from "./types";
import { convertKeysToSnakeCase } from "~/utils/object.utils";

export class ReportService implements IReportService {
  async findAccess({
    agency,
    page,
    search,
    customer,
    date,
  }: FindAccessReportsData): Promise<PaginatedResource<AccessReport[]>> {
    const currentDate = new Date();
    const endDate = displayDate(currentDate, DateFormats.RANGE_DATE);
    const url = `/agencies/${agency}/access-history?page=${page}&search=${search}&customer_uuid=${customer}&start_date=${date}&end_date=${endDate}`;

    return await api.get<PaginatedResource<ReportsDTO[]>>(url).then(({ data }) => ({
      data: data.data.map(mapReportsDTO),
      links: data.links,
      meta: data.meta,
    }));
  }

  async findShopping(data: ShoppingReportData) {
    const body = this.body(data);

    const url = queryString.stringifyUrl(
      {
        url: `/customers/${data.customer.uuid}/purchase-report`,
        query: convertKeysToSnakeCase(body),
      },
      {
        arrayFormat: "bracket",
      }
    );

    await api.get(url).then(({ data }) => ({
      link: data.link,
    }));
  }

  async generate(data: GenerateReport): Promise<void> {
    const {
      areAllApproversSelected,
      areAllBranchesSelected,
      areAllCostCentersSelected,
      areAllCustomerEmployeesGroupsSelected,
      areAllIssuersSelected,
      areAllPassengersSelected,
      areAllProjectsSelected,
      customer,
      reportType,
      shoppingDate,
      travelDate,
      approvers,
      branches,
      costCenters,
      customerEmployeesGroups,
      issuers,
      passengers,
      projects
    } = data;

    const body = {
      report_type: reportType,
      branches_uuid: areAllBranchesSelected ? null : branches?.map(({ uuid }) => uuid).join(","),
      issuers_uuid: areAllIssuersSelected ? null : issuers?.map(({ uuid }) => uuid).join(","),
      approvers_uuid: areAllApproversSelected ? null : approvers?.map(({ uuid }) => uuid).join(","),
      travelers_uuid: areAllPassengersSelected ? null : passengers?.map(({ uuid }) => uuid).join(","),
      cost_centers_uuid: areAllCostCentersSelected ? null : costCenters?.map(({ uuid }) => uuid).join(","),
      projects_uuid: areAllProjectsSelected ? null : projects?.map(({ uuid }) => uuid).join(","),
      groups_uuid: areAllCustomerEmployeesGroupsSelected ? null : customerEmployeesGroups?.map(({ uuid }) => uuid).join(","),
      start_date: displayDate(shoppingDate.start, DateFormats.ISO_TIME_DATE),
      end_date: shoppingDate?.end
        ? displayDate(shoppingDate?.end, DateFormats.ISO_TIME_DATE)
        : undefined,
      departure_date: travelDate?.start
        ? displayDate(travelDate?.start, DateFormats.ISO_TIME_DATE)
        : undefined,
      arrival_date: travelDate?.end
        ? displayDate(travelDate?.end, DateFormats.ISO_TIME_DATE)
        : undefined,
    };

    return api.post(`/customers/${customer.uuid}/custom-reports`, body).then(({ data }) => data);
  }

  private body(data: ShoppingReportData) {
    return {
      branches: data.markAllBranchs ? [] : data?.branchs?.map(branch => branch.uuid),
      mark_all_branches: data.markAllBranchs,
      issuers_uuid: data.issuers?.map(({ uuid }) => uuid).join(","),
      approvers_uuid: data.approvers?.map(({ uuid }) => uuid).join(","),
      passengers_uuid: data.passengers?.map(({ uuid }) => uuid).join(","),
      cost_centers_uuid: data.costCenters?.map(({ uuid }) => uuid).join(","),
      start_date: displayDate(data.shoppingDate.start, DateFormats.ISO_TIME_DATE),
      end_date: data?.shoppingDate?.end
        ? displayDate(data?.shoppingDate?.end, DateFormats.ISO_TIME_DATE)
        : undefined,
      departure_date: data?.travelDate?.start
        ? displayDate(data?.travelDate?.start, DateFormats.ISO_TIME_DATE)
        : undefined,
      arrival_date: data?.travelDate?.end
        ? displayDate(data?.travelDate?.end, DateFormats.ISO_TIME_DATE)
        : undefined,
      airway_orders: Boolean(data.items?.airway),
      road_orders: Boolean(data.items?.road),
      vehicle_orders: Boolean(data.items?.vehicle),
      hotel_orders: Boolean(data.items?.hotel),
      hotel_offline: Boolean(data.items?.offline),
    };
  }
}
