import queryString from "query-string";
import { Order, OrderStatus, PaginatedResource } from "~/application/types";
import { DateFormats, asBrazilianDate } from "~/application/utils/date-functions";
import { api } from "~/infrastructure/api";
import { OrderDTO } from "~/infrastructure/api/dtos";
import { mapOrderDTO } from "~/infrastructure/api/mappers";
import type {
  ICustomerOrderService,
  IFindCustomerOrderParams,
  IFindCustomerOrderResult,
  OrderSummary,
} from "./ICustomerOrderService";

export class CustomerOrderService implements ICustomerOrderService {
  async summary({ customerId }: { customerId?: string }): Promise<OrderSummary> {
    const url = queryString.stringifyUrl({
      url: "/orders/summary",
      query: {
        customer_uuid: customerId,
      },
    });
    return await api
      .get<{
        summary: {
          total: number;
          by_status: {
            status: OrderStatus;
            total: number;
          }[];
        };
      }>(url)
      .then(({ data }) => ({
        total: data.summary.total,
        byStatus: data.summary.by_status,
      }));
  }

  async find({
    page,
    customerName,
    employeeName,
    issuerName,
    approverName,
    orderNumber,
    startDate,
    endDate,
    customerId,
    expenseOnly,
    status,
    tracker,
  }: IFindCustomerOrderParams): Promise<PaginatedResource<IFindCustomerOrderResult>> {
    const url = queryString.stringifyUrl(
      {
        url: "/orders",
        query: {
          page,
          status: status,
          customer_uuid: customerId,
          expense_only: expenseOnly,
          customer_name: customerName,
          employee_name: employeeName,
          issuer_name: issuerName,
          approver_name: approverName,
          order_number: orderNumber,
          start_date: startDate ? asBrazilianDate(startDate, DateFormats.ISO_DATE) : "",
          end_date: endDate ? asBrazilianDate(endDate, DateFormats.ISO_DATE) : "",
          tracker,
        },
      },
      {
        arrayFormat: "bracket",
      }
    );

    return await api
      .get<
        {
          summary: {
            total: number;
            by_status: {
              status: OrderStatus;
              total: number;
            }[];
          };
          orders: OrderDTO[];
        } & Omit<PaginatedResource<any>, "data">
      >(url)
      .then(({ data }) => ({
        meta: data.meta,
        links: data.links,
        data: {
          orders: data.orders.map(mapOrderDTO),
        },
      }));
  }
}
