import { useQueries } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { OrderItems, type Hotel, type HotelQuery } from "~/application/types";
import { hotelQueryService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { QueryKeys } from "~/constants/queryKeys";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { useFilters } from "../../../../hooks/useFilters";
import { BoundsData } from "../../components/MapViewDialog";
import { useBookingHotel } from "../../contexts/BookingHotelContext";
import { HotelsContainer } from "./HotelsContainer";
import { STATIC_HOTEL_FILTERS } from "./utils";
import { useHotelBudget } from "../HotelDetailsPage/hooks/useHotelBudget/useHotelBudget";
import { usePoliciesFormats } from "~/presentation/shared/hooks/usePoliciesFormats";
import { compressString } from "~/presentation/shared/utils/string-functions/functions";
import useMobile from "~/presentation/shared/hooks/useMobile";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG_TAG = "Booking/BookingHotel/HotelsPage";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SNACKBAR_MESSAGES = {} as const;

export function HotelsPage() {
  const { hotelQuery, hotelReducer, isLoadingLinks, hotelQueryLinks, onSearchHotels, order } = useBookingHotel();
  const hotelQueries = useQueries({
    queries: hotelQueryLinks?.links.map((link, index) => {
      const startTime = performance.now();
      let timePaused = 0;
      let pauseStartTime = 0;
  
      const handleVisibilityChange = () => {
        if (document.visibilityState === "hidden") {
          pauseStartTime = performance.now();
        } else if (document.visibilityState === "visible" && pauseStartTime) {
          timePaused += performance.now() - pauseStartTime;
          pauseStartTime = 0;
        }
      };
  
      document.addEventListener("visibilitychange", handleVisibilityChange);
  
      return {
        queryKey: [QueryKeys.HOTEL, link],
        retry: 1,
        retryDelay: hotelQueryLinks.waitTime,
        enabled: !isLoadingLinks,
        queryFn: async () => {
          const data = await hotelQueryService.find(link);
  
          const endTime = performance.now();
          const totalTimeTaken = endTime - startTime - timePaused;
  
          setResponseTimes((prevTimes) => ({
            ...prevTimes,
            [index]: totalTimeTaken,
          }));
  
          document.removeEventListener("visibilitychange", handleVisibilityChange);
  
          return data;
        },
      };
    }) ?? [],
  });
  const { contexts, user } = useUser();
  const [searchText, setSearchText] = useState<string>("");
  const [search] = useDebounce<string>(searchText, 700);
  
  const [rangeValeu, setRangeValue] = useState({ min: 0, max: 0, providersFinished: 0 });
  const [range] = useDebounce<{ min: number; max: number; providersFinished: number }>(rangeValeu, 600);

  const navigate = useNavigate();
  const { search: locationSearch } = useLocation();
  const isMobile = useMobile()
  

  const [responseTimes, setResponseTimes] = useState<Record<string, number>>({});
  const [bounds, setBounds] = useState<BoundsData>();

  const [hotelSelectedHover, setHotelSelectedHover] = useState<Hotel>()
  const [hotelSelectedInMap, setHotelSelectedInMap] = useState<Hotel>()
  const [canFilteredMap, setCanFilteredMap] = useState(true)

  const hotelsList = useMemo(() => {
    return hotelQueries
      .reduce<Hotel[]>((p, c) => [...p, ...(c.data ?? [])], [])
      .sort((a, b) => (a?.bestValueTotal || 0) - (b?.bestValueTotal || 0));
  }, [hotelQueries]);

  const isLoadingHotels = useMemo(() => hotelQueries.some((q) => q.isFetching), [hotelQueries]);
  const queryLoading = useMemo(() => hotelQueries.map(item => ({ isLoading: item.isFetching })), [hotelQueries])
  
  const countProvidersFinished = useMemo(
    () => hotelQueries.reduce((ac, q) => (!q.isFetching ? ac + 1 : ac), 0),
    [hotelQueries]
  );

  const providerFilterOptions = useMemo(() => {
    const providerSet = new Map<string, { name: string; slug: string; timeTaken: number }>();
  
    hotelQueries.forEach((query, index) => {
      if (!query.isFetching && query.data) {
        const hotelsData = query.data;
        const timeTakenInSeconds = (responseTimes[index] ?? 0) / 1000;
  
        hotelsData.forEach((hotel) => {
          const provider = hotel?.provider;
          if (provider && provider.slug && provider.name) {
            providerSet.set(provider.slug, { ...provider, timeTaken: timeTakenInSeconds });
          }
        });
      }
    });
  
    return Array.from(providerSet.values())
      .map((provider, index) => ({
        key: `provider_${index + 1}`,
        label: `${provider.slug} (${provider.timeTaken.toFixed(2)}s)`,
        value: provider.slug,
      }))
      .sort((a, b) => b.label.localeCompare(a.label));
  }, [hotelQueries, responseTimes]);
  
  const dynamicHotelFilters = useMemo(() => {
    if (!contexts.agency) {
      return STATIC_HOTEL_FILTERS;
    }
  
    return [
      ...STATIC_HOTEL_FILTERS,
      {
        key: "suppliers",
        label: "Fornecedores",
        type: "suppliers",
        isValid: (item: Hotel, optionValue: string) => item?.provider.slug === optionValue,
        options: providerFilterOptions,
      },
    ];
  }, [providerFilterOptions, contexts]);

  const customerId = user?.customer?.uuid || contexts?.customer?.uuid;
  const isSomeSettled = useMemo(() => hotelQueries.some((q) => !q.isFetching), [hotelQueries]);
  const hotels = usePoliciesFormats({ customerId, data: hotelsList, itemType: OrderItems.HOTEL });
  const { filteredData, filters, onFilterChange, onClearFilters } = useFilters({
    data: hotels as Hotel[],
    filters: dynamicHotelFilters,
  });

  const searchedData = useMemo(() => {
    return filteredData.filter((item) => {
      return (new RegExp(search, "i").test(item?.name || "") || 
      new RegExp(search, "i").test(item?.address.street || "") || 
      new RegExp(search, "i").test(item?.address.quarter || "") || 
      new RegExp(search, "i").test(item?.address.complement || ""))
    })
  }, [filteredData, search]);


  useEffect(() => {
    if (countProvidersFinished !== rangeValeu.providersFinished) {
      setRangeValue({
        min: 0,
        max: 0,
        providersFinished: countProvidersFinished,
      });
    }
  }, [countProvidersFinished, rangeValeu.providersFinished]);

  useEffect(() => {
    if (searchedData.length && countProvidersFinished !== rangeValeu.providersFinished) {
      setRangeValue({
        min: searchedData[0]?.bestValueTotal || 0,
        max: searchedData[searchedData.length - 1]?.bestValueTotal || 0,
        providersFinished: countProvidersFinished,
      });
    }
  }, [searchedData, rangeValeu, countProvidersFinished]);

  const filtredValueData = useMemo(() => {
    return searchedData.filter((hotel) => {
      return (
        (hotel?.bestValueTotal || 0) >= (range.min || 0) &&
        (hotel?.bestValueTotal || 0) <= (range.max || 0) 
      );
    });
  }, [range, searchedData]);
 
  const hotelData = filtredValueData.filter((hotel)=> hotel?.address.latitude && hotel?.address.longitude)

  const sorteredMapData = useMemo(() => {
    const sorteredData =  canFilteredMap ? filtredValueData.sort((hotelA, hotelB) => {
      const isHotelANear = 
        (hotelA?.address?.latitude || 0) >= (bounds?.southwest?.lat || 0) &&
        (hotelA?.address?.latitude || 0) <= (bounds?.northeast?.lat || 0) &&
        (hotelA?.address?.longitude || 0) >= (bounds?.southwest?.lng || 0) &&
        (hotelA?.address?.longitude || 0) <= (bounds?.northeast?.lng || 0);
      
      const isHotelBNear = 
        (hotelB?.address?.latitude || 0) >= (bounds?.southwest?.lat || 0) &&
        (hotelB?.address?.latitude || 0) <= (bounds?.northeast?.lat || 0) &&
        (hotelB?.address?.longitude || 0) >= (bounds?.southwest?.lng || 0) &&
        (hotelB?.address?.longitude || 0) <= (bounds?.northeast?.lng || 0);

  
      if (isHotelANear === isHotelBNear) {
        return (hotelA?.bestValueTotal || 0) - (hotelB?.bestValueTotal || 0);
      }
  
      return isHotelANear ? -1 : 1;
    }) : filtredValueData
    
    const sorteredHoverMap = (() => {
      if (!hotelSelectedHover) return sorteredData;
      
      return [hotelSelectedHover, ...sorteredData.filter(hotel => hotel?.uuid !== hotelSelectedHover?.uuid)];

    })()
    
    const orderedWhenSelectingTheMap = (() => {
      if (!hotelSelectedInMap) return sorteredHoverMap;
      
      return [hotelSelectedInMap, ...sorteredHoverMap.filter(hotel => hotel?.uuid !== hotelSelectedInMap?.uuid)];

    })()
    return orderedWhenSelectingTheMap
  }, [range, filtredValueData, bounds, hotelSelectedHover, canFilteredMap, hotelSelectedInMap]);
  

  const handleSearchHotels = useCallback(
    (data: HotelQuery) => {
      hotelReducer.dispatch({
        type: "RESET_BOOKING",
      });

      onClearFilters();

      onSearchHotels(data);
    },
    [hotelReducer, onClearFilters, onSearchHotels]
  );

  const minPriceRoomAll = (hotelsList || [])
    .sort((a, b) => (a?.bestRoomValue || 0) - (b?.bestRoomValue || 0))
    ?.at(0);

  const handleSelectHotel = useCallback(
    (hotel: Hotel) => {
      hotelReducer.dispatch({
        type: "SET_HOTEL",
        payload: hotel,
      });
      const customerId = contexts.customer?.uuid;
      const url = `/busca/hospedagens/detalhes${locationSearch}&customerId=${customerId}&hotel=${compressString(
        JSON.stringify({provider:hotel?.provider, uuid: hotel?.uuid, 
          stars: hotel?.stars,searchKey: hotel?.searchKey})
      )}&policy=${minPriceRoomAll?.bestRoomValue}`;

      window.open(url, "_blank", "noopener,noreferrer");
    },
    [navigate, hotelReducer, user, contexts, minPriceRoomAll]
  );

  const hotelBudget = useHotelBudget();

  return (
    <HotelsContainer
      filters={filters}
      data={hotelData}
      dataFilterMap={sorteredMapData}
      hotelSelectedInMap={hotelSelectedInMap}
      onSelectInMap={setHotelSelectedInMap}
      hotelSelectedHover={hotelSelectedHover}
      dataDefault={searchedData}
      hotelBudget={hotelBudget}
      queryLoading={queryLoading}
      onRangeValue={setRangeValue}
      countProvidersFinished={countProvidersFinished}
      rangeValue={rangeValeu}
      hotelQuery={hotelQuery!}
      isLoading={isLoadingHotels}
      isSomeSettled={isSomeSettled}
      searchText={searchText}
      setSearchText={setSearchText}
      onFilterChange={onFilterChange}
      onSelect={handleSelectHotel}
      setBounds={setBounds}
      onSelectHover={setHotelSelectedHover}
      onSearchHotels={handleSearchHotels}
      setCanFilteredMap={setCanFilteredMap}
      canFilteredMap={canFilteredMap}
      order={order}
    />
  );
}
