import { Order, OrderStatus, Passenger } from "~/application/types";
import { DateUtils } from "~/application/utils";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgDownload, SvgInfo, SvgTrash } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { CombinedOrderAirwayItemSegmentType } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/components/OrderAirwayItem";
import { useOrder } from "../../hooks/useOrder";
import { dialogService } from "~/components/DialogStack";
import { AdicionalTravelerInfo } from "../AdicionalTravelerInfo";
import { useDownload } from "../../hooks/useDownload";
import { useState } from "react";
import { IconButton } from "~/components/IconButton";
import { getVoucherDownloadUrl } from "../../utils";
import { DownloadOrderVoucher } from "../DownloadOrderVoucher";

export interface PassengerListItemProps {
  data: Passenger;
  item?: CombinedOrderAirwayItemSegmentType;
  onRemoveSeat?: ({ orderItemId, seatId }: { orderItemId: string; seatId: string }) => void;
}

export function PassengerListItem({ data, item, onRemoveSeat, ...props }: PassengerListItemProps) {
  const baggage = item?.servicesIncluded?.baggage;
  const customerDateOfBirth = DateUtils.smallDateFormat(data.dateBirth);
  const { order } = useOrder();

  const onOpenMoreInfoDialog = () => {
    dialogService.showDialog(<AdicionalTravelerInfo travelerId={data.uuid} order={order} />);
  };

  const [isDownloadingVoucher, setIsDownloadingVoucher] = useState(false);
  const { onDownloadIndividual } = useDownload({ logTag: data.fullName });

  const toggleIsDownloadingVoucher = () => {
    setIsDownloadingVoucher((old) => !old);
  };

  const orderStatus = order?.status;

  return (
    <Card css={{ overflow: "visible" }}>
      <Flex
        css={{
          borderRadius: "$md",
          backgroundColor: "$neutrals-white",
          width: "100%",
          p: "$6",
        }}
      >
        <Flex gap="4" direction="column" css={{ width: "100%" }}>
          <Flex justify="between" direction={{ "@mxmd": "column" }} gap="4" css={{ width: "100%" }}>
            <Flex
              direction={{ "@initial": "column", "@mxmd": "row" }}
              align={{ "@mxmd": "center" }}
            >
              <Text
                size="3"
                variant="dark"
                css={{
                  mb: "1rem",
                  fw: "500",
                  display: "block",
                  "@mxmd": {
                    mr: "$3",
                    display: "none",
                  },
                }}
              >
                Nome
              </Text>

              <Text size={{ "@initial": "2", "@mxmd": "3" }} css={{ fw: "600" }}>
                {data.fullName}
              </Text>
            </Flex>

            <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
              <Text
                size="3"
                variant="dark"
                css={{
                  mb: "1rem",
                  fw: "500",
                  display: "block",
                  "@mxmd": {
                    mr: "$3",
                  },
                }}
              >
                Ticket
              </Text>

              <Text
                size="3"
                css={{
                  fw: "600",
                  "@mxmd": {
                    color: "#727272",
                  },
                }}
              >
                {data.ticket}
              </Text>
            </Flex>

            <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
              <Text
                size="3"
                variant="dark"
                css={{
                  mb: "1rem",
                  fw: "500",
                  display: "block",
                  "@mxmd": {
                    mr: "$3",
                  },
                }}
              >
                Quantidade de bagagem
              </Text>

              <Text size="3" css={{ fw: "600", wordBreak: "break-all", color: "#727272" }}>
                {baggage}
              </Text>
            </Flex>
            <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
              <Text
                size="3"
                variant="dark"
                css={{
                  mb: "1rem",
                  fw: "500",
                  display: "block",
                  "@mxmd": {
                    mr: "$3",
                  },
                }}
              >
                Data de nascimento
              </Text>

              <Text size="3" css={{ fw: "600", wordBreak: "break-all", color: "#727272" }}>
                {customerDateOfBirth}
              </Text>
            </Flex>
            <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
              <Text
                size="3"
                variant="dark"
                css={{
                  mb: "1rem",
                  fw: "500",
                  display: "block",
                  "@mxmd": {
                    mr: "$3",
                  },
                }}
              >
                Função
              </Text>

              <Text size="3" css={{ fw: "600", wordBreak: "break-all", color: "#727272" }}>
                {data.position}
              </Text>
            </Flex>
            {orderStatus === OrderStatus.ISSUED && (
              <Flex direction="column" gap="1" wrap={{ "@mxlg": "wrap" }}>
                <Text
                  size="3"
                  variant="dark"
                  css={{
                    fw: "500",
                    display: "block",
                    "@mxmd": {
                      mr: "$3",
                    },
                  }}
                >
                  Baixar voucher individual
                </Text>
                <DownloadOrderVoucher
                  icon={null}
                  onDownload={() =>
                    onDownloadIndividual({
                      order: order as Order,
                      customerEmployeeId: data.uuid,
                    })
                  }
                  downloadUrl={getVoucherDownloadUrl(order)}
                  onDownloaded={toggleIsDownloadingVoucher}
                  onIconClick={toggleIsDownloadingVoucher}
                >
                  <IconButton
                    css={{
                      size: "$8",
                      background: "#0064C5",
                      opacity: 0.9,
                      "&:hover": {
                        background: "#0064C5",
                        opacity: 1,
                      },
                    }}
                  >
                    <SvgDownload />
                  </IconButton>
                </DownloadOrderVoucher>
              </Flex>
            )}
            <Button variant="tertiary" onClick={onOpenMoreInfoDialog}>
              <Icon as={SvgInfo} />
            </Button>
          </Flex>

          {!!data.seats?.length && (
            <Flex gap="4">
              {data.seats?.map((seat, index) => {
                return (
                  <Flex direction="row" gap="1" align="center" key={seat.uuid}>
                    <Flex
                      justify="between"
                      css={{
                        cursor: "pointer",
                        backgroundColor: "$primary-light",
                        borderRadius: "$md",

                        p: "$3",
                        ["&:hover"]: {
                          backgroundColor: "$error-light",
                          [`& ${Text}`]: {
                            color: "$error-base",
                          },
                          [`& ${Icon}`]: {
                            fill: "$error-base",
                          },
                        },
                      }}
                      gap="2"
                      align="center"
                      onClick={() =>
                        onRemoveSeat?.({
                          orderItemId: item?.uuid || "",
                          seatId: data?.seats?.at(index)?.uuid || "",
                        })
                      }
                    >
                      <Flex gap="1">
                        <Text variant="primary" size="2" fw="600">
                          {seat.seatNumber}
                        </Text>
                      </Flex>
                      <Icon as={SvgTrash} variant="primary" />
                    </Flex>

                    <Flex direction="column" gap="2">
                      <Text variant="primary" size="1" fw="600">
                        {
                          item?.segments
                            .concat(item.combined || [])
                            .find(
                              (seg) =>
                                seg.arrivalFlightInfo.shortAirport === seat.arrival &&
                                seg.departureFlightInfo.shortAirport === seat.departure
                            )?.departureFlightInfo.shortAirport
                        }
                      </Text>
                      <Text variant="primary" size="1" fw="600">
                        {
                          item?.segments
                            .concat(item.combined || [])
                            .find(
                              (seg) =>
                                seg.arrivalFlightInfo.shortAirport === seat.arrival &&
                                seg.departureFlightInfo.shortAirport === seat.departure
                            )?.arrivalFlightInfo.shortAirport
                        }
                      </Text>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Card>
  );
}
