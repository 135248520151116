import { useCallback } from "react";
import { OrderVehicle } from "~/application/types";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col, Grid } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgCall, SvgClock, SvgGps, SvgInfo } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { TravelerListItem } from "~/core/shared/components/TravelerListItem";
import { OrderVehicleCard } from "~/presentation/shared/components/OrderVehicleCard";
import { OrderVehicleItemSkeleton } from "~/presentation/shared/components/OrderVehicleItemSkeleton";
import { ReasonRejectedItem } from "~/presentation/shared/components/ReasonRejectedItem";
import { useCopyTracker } from "~/presentation/shared/hooks/useCopyTracker";
import { useOrder } from "~/presentation/shared/hooks/useOrder/useOrder";
import { canMarkAsRejected } from "~/presentation/shared/utils";
import { DateFormats, displayDate } from "~/utils/date.utils";

export interface OrderVehicleItemProps {
  isLoading: boolean;
  data?: OrderVehicle[];
  onDeleteItem?: (itemId: string) => void;
}

export function OrderVehicleItem({ data, isLoading, onDeleteItem }: OrderVehicleItemProps) {
  const { order } = useOrder();

  const { renderCopyTracker } = useCopyTracker();

  const listRenderer = useCallback((item: OrderVehicle) => {
    const reasonRejected = item.reasonRejected;
    const shouldMarkAsRejected = canMarkAsRejected({
      reasonRejected: item.reasonRejected,
      order,
    });

    return (
      <>
        <Card
          css={{
            mt: "$3",
            border: shouldMarkAsRejected ? "1px solid red" : "none",
          }}
        >
          <CardBody>
            <Flex direction="column" gap="8" key={item.uuid}>
              <Flex direction="column" gap="6">
                <Flex justify="between">
                  <Col>
                    <Text size="5" css={{ mb: "$4" }}>
                      Veículo
                    </Text>
                  </Col>

                  <Flex css={{ gap: "$2" }} align="center">
                    <Tag variant="info-light">{item.credential}</Tag>

                    <Text variant="darkest" css={{ fw: "600" }}>
                      <Flex align="center">{item.tracker && item.tracker}</Flex>
                    </Text>

                    {item.tracker && renderCopyTracker(item.tracker as string)}
                  </Flex>
                </Flex>

                <Col>
                  <OrderVehicleCard data={item} />
                </Col>

                <Flex direction="column" gap="6">
                  <Text size="5">Datas e localização</Text>

                  <Grid columns={{ "@initial": "2", "@mxlg": "1" }} gap="6">
                    <Card>
                      <Flex direction="column" as={CardBody} gap="4">
                        <Text size="3" css={{ fw: "600" }}>
                          Informações sobre retirada
                        </Text>

                        <Flex gap="3" align="center">
                          <Icon variant="primary" as={SvgClock} />
                          <Text size="2" css={{ fw: "500" }}>
                            Data e hora
                          </Text>
                          <Text size="2" variant="dark">
                            {displayDate(item.departureDate, DateFormats.LONG_DATE_TIME)}
                          </Text>
                        </Flex>
                        <Flex gap="3" align="center">
                          <Icon variant="primary" as={SvgGps} />
                          <Text size="2" css={{ fw: "500" }}>
                            Local
                          </Text>
                          <Text size="2" variant="dark">
                            {`${item.departure} - ${item.detail?.address?.state}, ${item.detail?.address?.country}`}
                          </Text>
                        </Flex>
                        {item.detail && (
                          <>
                            <Flex gap="3" align="center">
                              <Icon variant="primary" size="sm" as={SvgCall} />
                              <Text size="2" css={{ fw: "500" }}>
                                Contato
                              </Text>
                              <Text size="2" variant="dark">
                                {item.detail?.phone}
                              </Text>
                            </Flex>
                            <Flex gap="3" align="center">
                              <Icon variant="primary" as={SvgInfo} />
                              <Text size="2" css={{ fw: "500" }}>
                                Detalhes
                              </Text>
                              <Text size="2" variant="dark">
                                {item.detail?.deadLineDescription}
                              </Text>
                            </Flex>
                          </>
                        )}
                      </Flex>
                    </Card>

                    <Card>
                      <Flex direction="column" as={CardBody} gap="4">
                        <Text size="3" css={{ fw: "600" }}>
                          Informações sobre devolução
                        </Text>

                        <Flex gap="3" align="center">
                          <Icon variant="primary" as={SvgClock} />
                          <Text size="2" css={{ fw: "500" }}>
                            Data e hora
                          </Text>
                          <Text size="2" variant="dark">
                            {displayDate(item.arrivalDate, DateFormats.LONG_DATE_TIME)}
                          </Text>
                        </Flex>
                        <Flex gap="3" align="center">
                          <Icon variant="primary" as={SvgGps} />
                          <Text size="2" css={{ fw: "500" }}>
                            Local
                          </Text>
                          <Text size="2" variant="dark">
                            {`${item.arrival} - ${item.detail?.address?.state}, ${item.detail?.address?.country}`}
                          </Text>
                        </Flex>
                        {item.detail && (
                          <>
                            <Flex gap="3" align="center">
                              <Icon variant="primary" size="sm" as={SvgCall} />
                              <Text size="2" css={{ fw: "500" }}>
                                Contato
                              </Text>
                              <Text size="2" variant="dark">
                                {item.detail?.phone}
                              </Text>
                            </Flex>
                            <Flex gap="3" align="center">
                              <Icon variant="primary" as={SvgInfo} />
                              <Text size="2" css={{ fw: "500" }}>
                                Detalhes
                              </Text>
                              <Text size="2" variant="dark">
                                {item.detail?.deadLineDescription}
                              </Text>
                            </Flex>
                          </>
                        )}
                      </Flex>
                    </Card>
                  </Grid>
                </Flex>

                <Flex direction="column" gap="6">
                  <Text size="5">Condutor</Text>
                  <TravelerListItem data={item.conductor} />
                </Flex>

                {shouldMarkAsRejected && <ReasonRejectedItem reasonRejected={reasonRejected} />}
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </>
    );
  }, []);

  return (
    <Box>
      <LazyList
        gap="8"
        isLoading={isLoading}
        items={data}
        render={listRenderer}
        skeletonQuantity={1}
        SkeletonComponent={<OrderVehicleItemSkeleton />}
      />
    </Box>
  );
}

OrderVehicleItem.displayName = "OrderVehicleItem";
