import { useCallback, useState } from "react";
import { useMutation } from "@tanstack/react-query";

import { AdvanceDialog } from "~/core/modules/Order/components/AdvanceDialog";
import { Order, OrderAdvance, TravelerAdvance } from "~/application/types";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { orderAdvanceService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { logError } from "~/presentation/shared/utils/errors";
import { queryClient } from "~/services/queryClient";
import { QueryKeys } from "~/constants";

export interface UseAdvanceItemResult {
  isAdvanceItemExpanded: boolean;
  toggleAdvanceItemVisible: () => void;
  onAddOrderAdvance: () => void;
}

export interface UseAdvanceItemProps {
  order?: Order;
  orderId: string;
}

const LOG_TAG = "Agency/Pages/AgencyOrderPage";

const SNACKBAR_MESSAGES = {
  CREATE_ADVANCE_SUCCESS_MESSAGE: "Adiantamento adicionado ao pedido",
  CREATE_ADVANCE_ERROR_MESSAGE: "Falha ao adicionar adiantamento",
} as const;

export function useAdvanceItem({ order, orderId }: UseAdvanceItemProps): UseAdvanceItemResult {
  const [isAdvanceItemExpanded, setAdvanceItemExpanded] = useState(true);

  const toggleAdvanceItemVisible = useCallback(() => {
    setAdvanceItemExpanded((old) => !old);
  }, []);

  const { mutate: mutateCreateOrderAdvance } = useMutation(
    (item: OrderAdvance) => orderAdvanceService.append(orderId, item.travelers),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(
          <LoadingDialog message="Criando adiantamento" />
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.ORDERS, orderId]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_ADVANCE_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ADVANCE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const onAddOrderAdvance = useCallback(() => {
    dialogService.showDialog(
      <AdvanceDialog
        isNew={false}
        defaultData={{
          travelers: order?.travelers.map(
            (traveler) =>
            ({
              traveler: traveler,
              description: "",
            } as TravelerAdvance)
          ),
        }}
        onSubmit={mutateCreateOrderAdvance}
        fetchTravelers={() => Promise.resolve(order?.travelers || [])}
      />
    );
  }, [order, mutateCreateOrderAdvance]);

  return {
    isAdvanceItemExpanded,
    toggleAdvanceItemVisible,
    onAddOrderAdvance,
  };
}
