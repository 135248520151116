import {
  Actions,
  JudgementStatus,
  Order,
  OrderAdvanceItem,
  OrderAirwaySegment,
  OrderHotelItem,
  OrderRoadSegment,
  OrderStatus,
  OrderVehicle,
  TravelerAdvance,
} from "~/application/types";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { userIsOrderApprover } from "../../../utils";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";

export function canShowApprovalButtons(order?: Order): boolean {
  const { user, contexts, profile } = useUser();

  if (!order || order.isExpired) {
    return false;
  }

  const isOrderOnApproval = order.status === OrderStatus.ON_APPROVAL;

  const userHasNotApproved = order.approvalModel?.approvers?.some(({ uuid, approvalStatus }) => {
    return user.profiles.customer.uuid === uuid && approvalStatus === null;
  }) as boolean;

  const isApprover = userIsOrderApprover(order);
  const approvers = order?.approvalModel?.approvers || [];
  const approverIndex = approvers.findIndex((a) => a.uuid === user?.profiles.customer.uuid);
  const approverLevel = approvers[approverIndex]?.level;
  const previousLevelApprovers = approvers.filter((a) => a.level === approverLevel - 1);

  const itsApproverTurn =
    (approverLevel === 1 &&
      !approvers.some((a) => a.approvalStatus === JudgementStatus.APPROVED)) ||
    (approverLevel > 1 &&
      previousLevelApprovers.some((a) => a.approvalStatus === JudgementStatus.APPROVED));

  const isMasterApprover = useVerifyActions({
    actions: [Actions.ApprovalAll],
    contexts,
    profile,
  });

  return (
    isOrderOnApproval && ((userHasNotApproved && isApprover && itsApproverTurn) || isMasterApprover)
  );
}

export type GetOrderItemResult =
  | TravelerAdvance[]
  | OrderAirwaySegment[]
  | OrderHotelItem[]
  | OrderVehicle[]
  | OrderAdvanceItem[]
  | OrderRoadSegment[]
  | undefined;

export function shouldTagItemAsRejected({
  itemIsRejected,
  order,
}: {
  order?: Order;
  itemIsRejected?: boolean;
}): boolean {
  return !!itemIsRejected && order?.status === OrderStatus.REJECTED;
}

export function getTotalProviderFees(order?: Order): number {
  const roadTotalProviderTaxes = order?.items.road?.fees?.fee || 0;

  const airwayTotalProviderTaxes =
    (order?.items.airway?.fees?.fee || 0) + (order?.items.airway?.fees?.boardingTax || 0);

  const hotelTotalProviderTaxes = order?.items.hotel?.fees?.fee || 0;

  const vehicleTotalProviderTaxes = order?.items.vehicle?.fees?.fee || 0;

  return (
    roadTotalProviderTaxes +
    airwayTotalProviderTaxes +
    hotelTotalProviderTaxes +
    vehicleTotalProviderTaxes
  );
}

export function getNameRoomTypes(item?: OrderHotelItem) {
  switch (item?.guests?.length) {
    case 1:
      return ["Single"];
    case 2:
      return ["Duplo"];
    case 3:
      return ["Triplo"];
    case 4:
      return ["Quadruplo"];
    default:
      return [""];
  }
}
