import queryString from "query-string";
import { AccountabilityExpense, ApprovalType, JudgementStatus } from "~/application/types";
import { DateUtils } from "~/application/utils";
import { api } from "~/infrastructure/api";
import { AccountabilityExpenseDTO, TravelerAdvanceDTO } from "~/infrastructure/api/dtos";
import { mapAccountabilityExpenseDTO } from "~/infrastructure/api/mappers";
import { parseTravelerAdvanceDTO } from "../OrderAdvance/OrderAdvanceService";
import {
  IAccountabilityExpenseService,
  ICreateAccountabilityExpenseParams,
  IFindAccountabilityExpenseParams,
  IFindAccountabilityExpenseResult,
  IFindExpenseByAdvanceOrderResult,
} from "./IAccountabilityExpenseService";
import { DateFormats } from "~/application/utils/date-functions";

interface IFindAccountabilityExpenseResponse {
  summary: {
    advances: number;
    expenses: number;
    refund: number;
  };
  data: AccountabilityExpenseDTO[];
}

type ApprovalRequest = IFindExpenseByAdvanceOrderResult["advanceOrder"]["approvalRequest"];

function parseAdvanceOrder(
  advanceOrder: TravelerAdvanceDTO & {
    approval_request?: {
      date: string;
      advance_order_uuid: string;
      approved: boolean;
      approval_model: { approval_type: ApprovalType; active: boolean };
    };
  }
): IFindExpenseByAdvanceOrderResult["advanceOrder"] {
  let approvalRequest: ApprovalRequest = {} as ApprovalRequest;

  if (advanceOrder.approval_request) {
    approvalRequest = {
      date: advanceOrder.approval_request.date
        ? DateUtils.toDate(advanceOrder.approval_request?.date)
        : undefined,
      advanceOrderId: advanceOrder.approval_request.advance_order_uuid as string,
      isApproved: advanceOrder.approval_request.approved,
      approvalModel: {
        approvalType: advanceOrder.approval_request.approval_model.approval_type as ApprovalType,
        isActive: advanceOrder.approval_request.approval_model.active,
      },
    };
  }

  return {
    ...parseTravelerAdvanceDTO(advanceOrder as TravelerAdvanceDTO),
    approvalRequest: { ...approvalRequest },
  };
}

export class AccountabilityExpenseService implements IAccountabilityExpenseService {
  private headers = {
    "Content-Type": "multipart/form-data",
  };

  async find({
    orderId,
    travelerId,
    expenseDate,
    expenseTypeId,
  }: IFindAccountabilityExpenseParams): Promise<IFindAccountabilityExpenseResult> {
    const url = queryString.stringifyUrl({
      url: `/orders/${orderId}/travelers/expenses`,
      query: {
        customer_employee_uuid: travelerId,
        expense_type_uuid: expenseTypeId,
        expense_date: expenseDate,
      },
    });

    return await api.get<IFindAccountabilityExpenseResponse>(url).then(({ data: response }) => ({
      summary: {
        advances: response.summary?.advances,
        expenses: response.summary?.expenses,
        refundable: response.summary?.refund,
      },
      data: response.data.length ? response.data.map(mapAccountabilityExpenseDTO) : [],
    }));
  }

  async findByAdvanceOrder(advanceOrderId: string): Promise<IFindExpenseByAdvanceOrderResult> {
    const url = `/orders/advance-orders/${advanceOrderId}/expenses`;

    return await api
      .get<{
        expenses: AccountabilityExpenseDTO[];
        advance_order: TravelerAdvanceDTO;
        approvers: {
          uuid: string;
          name: string;
          self_approver: boolean;
          approval_status?: JudgementStatus;
        }[];
      }>(url)
      .then(({ data: response }) => ({
        expenses: response.expenses.map(mapAccountabilityExpenseDTO),
        advanceOrder: parseAdvanceOrder(response.advance_order),
        approvers: response.approvers.map(({ uuid, name, self_approver, approval_status }) => ({
          id: uuid,
          name,
          isSelfApprover: self_approver,
          approvalStatus: approval_status,
        })),
      }));
  }

  async create({
    orderId,
    travelerId,
    ...data
  }: ICreateAccountabilityExpenseParams): Promise<AccountabilityExpense> {
    const url = `/orders/${orderId}/travelers/${travelerId}/expenses`;

    return await api
      .post<AccountabilityExpenseDTO>(
        url,
        this.createBody(data as AccountabilityExpense),
        this.headers
      )
      .then(({ data }) => mapAccountabilityExpenseDTO(data));
  }

  async updateById(data: AccountabilityExpense): Promise<AccountabilityExpense> {
    const url = `/orders/travelers/expenses/${data.uuid}`;
    return await api
      .post<AccountabilityExpenseDTO>(url, this.createBody(data), this.headers)
      .then(({ data }) => mapAccountabilityExpenseDTO(data));
  }

  async deleteById(data: AccountabilityExpense): Promise<void> {
    return await api.delete(`/orders/travelers/expenses/${data.uuid}`).then(({ data }) => data);
  }

  private createBody(data: AccountabilityExpense): FormData {
    const formData = new FormData();

    formData.append("expense_type_uuid", data.expenseType.uuid);
    formData.append(
      "expense_date",
      DateUtils.format(data.expenseDate as Date, DateFormats.DATE_ISO)
    );
    formData.append("company", data.company);
    formData.append("value", data.value.toString());

    if (data.justification) {
      formData.append("justification", data.justification);
    }

    if (data.voucherImage) {
      formData.append("voucher_image", data.voucherImage);
    }

    return formData;
  }
}
