import { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { ApprovalModel, Approver } from "~/application/types";
import {
  approvalModelService,
  branchService,
  costCenterService,
  customerEmployeeService,
} from "~/application/usecases";
import { ILinkingNewCostCenterData } from "~/application/usecases/ApprovalModel/IApprovalModelService";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys, QueryTimes } from "~/constants";
import { InactivateDialog } from "~/core/shared/components/InactivateDialog";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { logError } from "~/presentation/shared/utils/errors";
import {
  ApprovalModelDialog,
  ApprovalModelDialogProps,
  EditableApprovalModel,
} from "~/presentation/shared/views/ApprovalModelDialog";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { parseCreationBody, parseUpdateBody } from "../views/ApprovalModel/utils";
import { filterByStatusOptions } from "../constants";

export interface ChangeApproversParams {
  approvers: Approver[];
  approvalModelId: string;
  update?: boolean;
}
export interface UseApprovalModelsOptions {
  customerId: string;
  enabled: boolean;
}

export type UseApprovalModelsResult = {
  isLoading: boolean;
  searchText: string;
  currentPage: number;
  lastPage: number;
  data: ApprovalModel[];
  approverOptions?: IApproverFilters;
  isLoadingApprovers: boolean;
  selectedApprover: IApproverFilters;
  selectedStatus: ISelectOption;
  handleChangeSearchApprover: (text: string) => void;
  onChangeFilterApprover: (data: ISelectOption[]) => void;
  onChangeStatusFilter: (data: ISelectOption) => void;
  onClearFilters: () => void;
  setSearchText: (search: string) => void;
  onCreateApprovalModel: () => void;
  onEditApprovalModel: (item: ApprovalModel) => void;
  onToggleState: (item: ApprovalModel) => void;
  onGoToPage: (value: number) => void;
  onAddingNewCostCenter: (data: ILinkingNewCostCenterData) => void;
};

export const LOG_TAG = "Customer/CustomerPage/useApprovalModels";

export const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar modelos de aprovação",
  LOAD_ONE_ERROR_MESSAGE: "Falha ao carregar modelo de aprovação",
  CREATE_SUCCESS_MESSAGE: "Novo modelo de aprovação adicionado",
  CREATE_ERROR_MESSAGE: "Falha ao criar modelo de aprovação",
  UPDATE_SUCCESS_MESSAGE: "Modelo de aprovação atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar modelo de aprovação",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar modelo de aprovação",
  ACTIVATE_SUCCESS_MESSAGE: "Modelo de aprovação ativado",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar modelo de aprovação",
  INACTIVATE_SUCCESS_MESSAGE: "Modelo de aprovação inativado",
  LINKING_NEW_COST_CENTER_SUCCESS_MESSAGE: "O centro de custo criado foi vinculado com sucesso",
  LINKING_NEW_COST_CENTER_ERROR_MESSAGE: "Falha ao vincular o centro de custo nesse modelo",
} as const;

interface IUseApprovalModelsState {
  searchText: string;
  currentPage: number;
}

export interface ISelectOption {
  label: string;
  value: string | boolean | undefined;
}

export interface IApproverFilters {
  approvers: ISelectOption[];
}

const EMPTY_APPROVERS_FILTERS: IApproverFilters = {
  approvers: [],
};

const DEFAULT_STATUS: ISelectOption = filterByStatusOptions.find(
  (option) => option.value === undefined
)!;

export function useApprovalModels({
  customerId,
  enabled,
}: UseApprovalModelsOptions): UseApprovalModelsResult {
  const [searchParams] = useSearchParams();
  const approvalModelId = searchParams.get("modelo-de-aprovacao");

  const { user } = useUser();
  const [state, setState] = useState<IUseApprovalModelsState>({
    searchText: "",
    currentPage: 1,
  });

  const [approverSearch, setApproverSearch] = useState<IUseApprovalModelsState>({
    searchText: "",
    currentPage: 1,
  });

  const [approverSelected, setApproverFiltered] = useState(EMPTY_APPROVERS_FILTERS);
  const [selectedStatus, setSelectedStatus] = useState(DEFAULT_STATUS);

  const { searchText, currentPage } = state;

  const [search] = useDebounce(searchText, 700);

  const [approverSearchDebouced] = useDebounce(approverSearch, 700);

  const fetchApprovalModels = useCallback(
    async (name: string) => {
      return await approvalModelService
        .find({ customerId, page: currentPage, name })
        .then(({ data }) => data);
    },
    [customerId, currentPage]
  );

  const { mutate: mutateAddingNewCostCenter } = useMutation(
    async ({ approvalModelId, costCenter }: ILinkingNewCostCenterData) =>
      await approvalModelService.linkingNewCostCenter({ approvalModelId, costCenter }),
    {
      onMutate: () => {
        dialogService.showDialog(<LoadingDialog message="Vinculando centro de custo ao modelo" />);
      },
      onSuccess: (_, item) => {
        dialogService.popAll();
        log.i(LOG_TAG, `Successfully (${item.costCenter.name})`);
        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LINKING_NEW_COST_CENTER_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error) => {
        dialogService.popDialog();
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LINKING_NEW_COST_CENTER_ERROR_MESSAGE,
        });
        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LINKING_NEW_COST_CENTER_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const approvers = approverSelected.approvers.map((item) => item.value) as string[];

  const { data: approvalModels, isFetching: isFetchingApprovalModels } = useQuery(
    [
      QueryKeys.CUSTOMER_APPROVAL_MODELS,
      currentPage,
      customerId,
      { search, selectedStatus },
      approverSelected,
    ],
    () =>
      approvalModelService.find({
        customerId,
        page: currentPage,
        name: search,
        active: selectedStatus.value as boolean | undefined,
        approvers_uuid: approvers,
      }),
    {
      enabled,
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
    }
  );

  const { data: branches, isLoading: isLoadingBranches } = useQuery(
    [QueryKeys.CUSTOMER_BRANCHES],
    () => branchService.find({ customerId }).then((data) => data),
    {
      cacheTime: 0,
      retry: 2,
      enabled,
      onError: (error) => {
        log.e(LOG_TAG, error);
      },
    }
  );

  const { data: approverOptions, isLoading: isLoadingApprovers } = useQuery(
    [QueryKeys.APPROVERS, approverSearchDebouced],
    () =>
      customerEmployeeService
        .find({ customerId, approver: true, name: approverSearchDebouced.searchText })
        .then((data) => data.data),
    {
      cacheTime: 0,
      retry: 2,
      enabled,
      onError: (error) => {
        log.e(LOG_TAG, error);
      },
    }
  );

  const { data: costCenters, isLoading: isLoadingCostCenters } = useQuery(
    [QueryKeys.CUSTOMER_COST_CENTERS],
    () => costCenterService.findWithoutApprovalModelLink({ customerId }),
    {
      cacheTime: 0,
      retry: 2,
      enabled,
      onError: (error) => {
        log.e(LOG_TAG, error);
      },
    }
  );

  const fetchCostCenters = useCallback(
    async (name: string, approvalModelId: string) => {
      return await costCenterService.findWithoutApprovalModelLink({
        name,
        customerId,
        approvalModelId,
      });
    },
    [customerId]
  );

  const { data: availableApprovers, isLoading: isLoadingAvailableApprovers } = useQuery(
    [QueryKeys.CUSTOMER_EMPLOYEES, customerId],
    async () => {
      return customerEmployeeService
        .find({ customerId, approversOnly: "Ativo", isActive: "Ativo", onlyUser: true })
        .then(({ data }) => data);
    },
    {
      cacheTime: 0,
      retry: 2,
      enabled,
    }
  );

  const { mutateAsync: mutateCreateApprover } = useMutation(
    async (approver: Approver & { approvalModelId: string }) => {
      return await approvalModelService.addApprover(approver);
    },
    {
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created Approver(${item.name})`);
      },
      onError: (error) => {
        log.e(LOG_TAG, error);
      },
    }
  );

  const { mutateAsync: mutateUpdateApprover } = useMutation(
    async (data: Approver & { approvalModelId: string }) => {
      return await approvalModelService.updateApprover(data);
    },
    {
      onMutate: (item) => log.i(LOG_TAG, `Creating Approver(${item.name})`),
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created Approver(${item.name})`);
      },
      onError: (error) => {
        log.e(LOG_TAG, error);
      },
    }
  );

  const { mutateAsync: mutateInactivateApprover } = useMutation(
    async (approverModelId: string) => {
      return await approvalModelService.inactivateApprover(approverModelId);
    },
    {
      onMutate: (approverModelId) => log.i(LOG_TAG, `Inactivating Approver(${approverModelId})`),
      onSuccess: (_, approverModelId) => {
        log.i(LOG_TAG, `Successfully inactivated Approver(${approverModelId})`);
      },
      onError: (error) => {
        log.e(LOG_TAG, error);
      },
    }
  );

  const { mutateAsync: mutateCreateApprovalModel } = useMutation(
    async (data: EditableApprovalModel) => {
      const body = parseCreationBody({ data, customerId, user });
      return await approvalModelService.create({ customerId, data: body });
    },
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating ApprovalModel(${item.uuid})`);

        dialogService.showDialog(<LoadingDialog message="Criando novo modelo de aprovação" />);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created ApprovalModel(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_APPROVAL_MODELS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");
        dialogService.popAll();
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popDialog(),
    }
  );

  const { mutateAsync: mutateUpdateApprovalModel } = useMutation(
    async (data: EditableApprovalModel) => {
      return await approvalModelService.update(parseUpdateBody(data));
    },
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating ApprovalModel(${item.uuid})`);

        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Atualizando modelo de aprovação" />);
      },
      onSuccess: (_, item) => {
        dialogService.popAll();
        log.i(LOG_TAG, `Successfully updated ApprovalModel(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_APPROVAL_MODELS]);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_COST_CENTERS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const fetchApprovers = useCallback(
    async (name: string) => {
      return await customerEmployeeService
        .find({
          customerId,
          approversOnly: "Ativo",
          name,
          isActive: "Ativo",
          onlyUser: true,
          approver: true,
        })
        .then(({ data }) => data);
    },
    [customerId]
  );

  const approvalModelDialogDeps = [
    branches,
    isLoadingBranches,
    costCenters,
    isLoadingCostCenters,
    availableApprovers,
    isLoadingAvailableApprovers,
    fetchCostCenters,
    mutateCreateApprover,
    mutateUpdateApprover,
    mutateInactivateApprover,
    fetchApprovers,
  ];

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <ApprovalModelDialog
        isNew
        approvalModels={approvalModels?.data}
        isLoadingApprovalModels={isFetchingApprovalModels}
        fetchApprovers={fetchApprovers}
        branches={branches?.data}
        isLoadingBranches={isLoadingBranches}
        fetchCostCenters={fetchCostCenters as ApprovalModelDialogProps["fetchCostCenters"]}
        costCenters={costCenters}
        isLoadingCostCenters={isLoadingCostCenters}
        availableApprovers={availableApprovers}
        isLoadingAvailableApprovers={isLoadingAvailableApprovers}
        fetchApprovalModels={fetchApprovalModels}
        onSubmit={mutateCreateApprovalModel}
        onCreateApprover={mutateCreateApprover}
        onUpdateApprover={mutateUpdateApprover}
        onInactivateApprover={mutateInactivateApprover}
        customerId={customerId}
      />
    );
  }, [
    ...approvalModelDialogDeps,
    approvalModels,
    isFetchingApprovalModels,
    fetchApprovalModels,
    mutateCreateApprovalModel,
    customerId,
  ]);

  const handleOpenEditModal = useCallback(
    (item: ApprovalModel) => {
      dialogService.showDialog(
        <ApprovalModelDialog
          approvalModels={approvalModels?.data}
          isLoadingApprovalModels={isFetchingApprovalModels}
          branches={branches?.data}
          isLoadingBranches={isLoadingBranches}
          fetchCostCenters={fetchCostCenters as ApprovalModelDialogProps["fetchCostCenters"]}
          costCenters={costCenters}
          isLoadingCostCenters={isLoadingCostCenters}
          fetchApprovalModels={fetchApprovalModels}
          availableApprovers={availableApprovers}
          isLoadingAvailableApprovers={isLoadingAvailableApprovers}
          onSubmit={mutateUpdateApprovalModel}
          onCreateApprover={mutateCreateApprover}
          onUpdateApprover={mutateUpdateApprover}
          onInactivateApprover={mutateInactivateApprover}
          fetchApprovers={fetchApprovers}
          defaultData={item}
          customerId={customerId}
        />
      );
    },
    [
      ...approvalModelDialogDeps,
      approvalModels,
      isFetchingApprovalModels,
      fetchApprovalModels,
      mutateUpdateApprovalModel,
      customerId,
    ]
  );

  const { mutate: mutateToggleApprovalModelState } = useMutation(
    (item: ApprovalModel) => approvalModelService.toggleActive(item),
    {
      onSuccess: (_, item) => {
        queryClient.invalidateQueries([QueryKeys.CUSTOMER_APPROVAL_MODELS]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        if (item.isActive) {
          dialogService.popDialog();
        }
      },
      onError: (error, item) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
        });
      },
    }
  );

  const handleToggleState = useCallback(
    (item: ApprovalModel) => {
      if (item.isActive) {
        return dialogService.showDialog(
          <InactivateDialog
            loadingMessage="Inativando modelo de aprovação"
            onConfirm={() => mutateToggleApprovalModelState(item)}
          />
        );
      }

      mutateToggleApprovalModelState(item);
    },
    [mutateToggleApprovalModelState]
  );

  const handleChangeSearch = useCallback(
    (text: string) => setState({ ...state, searchText: text, currentPage: 1 }),
    [state]
  );

  const handleChangeFilterApprover = useCallback((data: ISelectOption[]) => {
    setApproverFiltered((old) => ({ ...old, approvers: data }));
    setState({ ...state, currentPage: 1 });
  }, []);

  const handleChangeSearchApprover = useCallback((text: string) => {
    setApproverSearch((old) => ({ ...old, searchText: text }));
    setState({ ...state, currentPage: 1 });
  }, []);

  const handleChangeStatusFilter = useCallback((data: ISelectOption) => {
    setSelectedStatus(data);
    setState({ ...state, currentPage: 1 });
  }, []);

  const handleClearFilters = useCallback(() => {
    setSelectedStatus(DEFAULT_STATUS);
    setApproverFiltered(EMPTY_APPROVERS_FILTERS);
    setState({ searchText: "", currentPage: 1 });
    setApproverSearch({ searchText: "", currentPage: 1 });
  }, []);

  const availableFilters = useMemo(
    () => ({
      approvers:
        approverOptions?.map((i) => ({
          label: i.name,
          value: i.uuid,
        })) || [],
    }),
    [approverOptions]
  );

  useQuery({
    queryKey: [QueryKeys.CUSTOMER_APPROVAL_MODEL, approvalModelId],
    queryFn: () => approvalModelService.findById(approvalModelId as string),
    enabled: !!approvalModelId,
    staleTime: QueryTimes.NONE,
    onSuccess: (data) => {
      searchParams.delete("modelo-de-aprovacao");

      dialogService.popAll();

      handleOpenEditModal({
        ...data,
        approvers: data.approvers.filter((approver) => approver.isActive),
      });
    },
    onError: (error) => {
      dialogService.popDialog();

      logError({
        error,
        logTag: LOG_TAG,
        defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ONE_ERROR_MESSAGE,
      });
    },
  });

  return {
    approverOptions: availableFilters,
    isLoadingApprovers,
    selectedApprover: approverSelected,
    selectedStatus,
    isLoading: isFetchingApprovalModels,
    data: approvalModels?.data || [],
    searchText,
    lastPage: approvalModels?.meta.last_page || 0,
    currentPage,
    handleChangeSearchApprover,
    setSearchText: handleChangeSearch,
    onCreateApprovalModel: handleOpenCreateModal,
    onChangeFilterApprover: handleChangeFilterApprover,
    onChangeStatusFilter: handleChangeStatusFilter,
    onClearFilters: handleClearFilters,
    onEditApprovalModel: handleOpenEditModal,
    onToggleState: handleToggleState,
    onAddingNewCostCenter: mutateAddingNewCostCenter,
    onGoToPage: (currentPage: number) => setState({ ...state, currentPage }),
  };
}
