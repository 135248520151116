import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDebounce } from "use-debounce";

import { useCustomerEmployeesGroups } from "~/core/modules/Customer/pages/CustomerPage/hooks/useCustomerEmployeesGroups";
import { useCostCenters } from "~/core/modules/Customer/pages/CustomerPage/hooks/useCostCenters";
import { useBranches } from "~/core/modules/Customer/pages/CustomerPage/hooks/useBranches";
import { useProjects } from "~/core/modules/Customer/pages/CustomerPage/hooks/useProjects";
import { ReportProcessingDialog } from "./components/ReportProcessingDialog";
import { ShoppingReportData } from "~/application/usecases/Reports/types";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { useReportingCenterTab } from "./hooks/useReportingCenterTab";
import { ReportingCenterContainer } from "./ReportingCenterContainer";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { customerEmployeeService } from "~/application/usecases";
import { reportService } from "~/application/usecases/Reports";
import { logError } from "~/presentation/shared/utils/errors";
import { dialogService } from "~/components/DialogStack";
import { QueryKeys } from "~/application/constants";
import { DeepPartial } from "~/application/types";

const LOG_TAG = "Reports/ReportingCenterPage";

const SNACKBAR_MESSAGES = {
  ISSUERS_LOAD_ERROR_MESSAGE: "Falha ao listar solicitantes",
  APPROVERS_LOAD_ERROR_MESSAGE: "Falha ao listar aprovadores",
  PASSENGERS_LOAD_ERROR_MESSAGE: "Falha ao listar passageiros",
  GENERATE_ERROR_MESSAGE: "Falha ao gerar relatório",
} as const;

export function ReportingCenterPage() {
  const [search, setSearch] = useState<
    DeepPartial<{
      approver: string;
      issuer: string;
      passenger: string;
    }>
  >({
    approver: "",
    issuer: "",
    passenger: "",
  });

  const [searchName] = useDebounce(search, 700);

  const tabValue = useReportingCenterTab();

  const { contexts } = useUser();

  const customerId = contexts.customer?.uuid;

  const {
    data: branches,
    isLoading: isLoadingBranches,
    setSearchText: onFetchBranches
  } = useBranches({ customerId, enabled: !!customerId });

  const {
    data: costCenters,
    isLoading: isLoadingCostCenters,
    setSearchText: onFetchCostCenters
  } = useCostCenters({ customerId, enabled: !!customerId });

  const {
    data: projects,
    isLoading: isLoadingProjects,
    onSearch: onFetchProjects
  } = useProjects({ customerId, enabled: !!customerId });

  const {
    data: customerEmployeesGroups,
    isLoading: isLoadingCustomerEmployeesGroups,
    onSearch: onFetchCustomerEmployeesGroups
  } = useCustomerEmployeesGroups({ customerId, enabled: !!customerId });

  const { data: approvers, isLoading: isLoadingApprovers } = useQuery(
    [QueryKeys.CUSTOMER_EMPLOYEES, customerId, searchName.approver],
    async () =>
      await customerEmployeeService.find({
        customerId,
        approversOnly: "Ativo",
        name: searchName.approver,
      }),
    {
      enabled: !!customerId,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.APPROVERS_LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data: issuers, isLoading: isLoadingIssuers } = useQuery(
    [QueryKeys.CUSTOMER_EMPLOYEES, customerId, searchName.issuer],
    async () =>
      await customerEmployeeService.find({
        customerId,
        name: searchName.issuer,
      }),
    {
      enabled: !!customerId,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.ISSUERS_LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data: passengers, isLoading: isLoadingPassengers } = useQuery(
    [QueryKeys.CUSTOMER_EMPLOYEES, customerId, searchName.passenger],
    async () =>
      await customerEmployeeService.find({
        customerId,
        name: searchName.passenger,
      }),
    {
      enabled: !!customerId,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.PASSENGERS_LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { mutate: generateReport } = useMutation({
    mutationFn: reportService.generate,
    onMutate: () => {
      dialogService.showDialog(<LoadingDialog message="Processando relatório" />);
    },
    onSuccess: () => {
      dialogService.popAll();
      dialogService.showDialog(<ReportProcessingDialog onCancel={() => dialogService.popDialog()} />);
    },
    onError: (error) => {
      logError({
        error,
        logTag: LOG_TAG,
        defaultErrorMessage: SNACKBAR_MESSAGES.GENERATE_ERROR_MESSAGE,
      });

      dialogService.popAll();
    },
  });

  const { mutate: generateGeneralReport } = useMutation({
    mutationFn: (data: ShoppingReportData) => reportService.findShopping({
      ...data,
      items: {
        airway: true,
        road: true,
        hotel: true,
        offline: true,
        vehicle: true,
      },
    }),
    onMutate: () => {
      dialogService.showDialog(<LoadingDialog message="Processando relatório" />);
    },
    onSuccess: () => {
      dialogService.popAll();
      dialogService.showDialog(<ReportProcessingDialog onCancel={() => dialogService.popDialog()} />);
    },
    onError: (error) => {
      logError({
        error,
        logTag: LOG_TAG,
        defaultErrorMessage: SNACKBAR_MESSAGES.GENERATE_ERROR_MESSAGE,
      });

      dialogService.popAll();
    },
  });

  return (
    <ReportingCenterContainer
      activeTab={tabValue}
      onGenerateReport={generateReport}
      onGenerateGeneralReport={generateGeneralReport}
      onSearchName={setSearch}
      branches={branches}
      isLoadingBranches={isLoadingBranches}
      onFetchBranches={onFetchBranches}
      costCenters={costCenters}
      isLoadingCostCenters={isLoadingCostCenters}
      onFetchCostCenters={onFetchCostCenters}
      projects={projects}
      isLoadingProjects={isLoadingProjects}
      onFetchProjects={onFetchProjects}
      customerEmployeesGroups={customerEmployeesGroups}
      isLoadingCustomerEmployeesGroups={isLoadingCustomerEmployeesGroups}
      onFetchCustomerEmployeesGroups={onFetchCustomerEmployeesGroups}
      passengers={passengers?.data}
      isLoadingPassengers={isLoadingPassengers}
      issuers={issuers?.data}
      isLoadingIssuers={isLoadingIssuers}
      approvers={approvers?.data}
      isLoadingApprovers={isLoadingApprovers}
    />
  );
}