import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { QueryKeys, QueryTimes } from "~/application/constants";
import {
  AirwaySeatsForm,
  Order,
  OrderAirwaySegment,
  OrderHotelItem,
  OrderHotelOfflineOption,
  OrderItems,
  OrderRoadSegment,
  OrderStatus,
  OrderTaxCancellation,
  Passenger,
} from "~/application/types";
import { branchService, orderAirwayService, orderService } from "~/application/usecases";
import { orderAirwayTrackerService } from "~/application/usecases/OrderAirwayTracker";
import { timeService } from "~/application/usecases/Time";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { useAssessment } from "~/presentation/core/hooks/useAssessment";
import { LoadingModal } from "~/presentation/shared/components/LoadingModal";
import { RoadDetails } from "~/presentation/shared/components/RoadDetails";
import { SimpleDialog } from "~/presentation/shared/components/SimpleDialog";
import { useDeleteOrderAirway } from "~/presentation/shared/hooks/useDeleteOrderAirway";
import { useDeleteOrderHotel } from "~/presentation/shared/hooks/useDeleteOrderHotel";
import { useDeleteOrderRoad } from "~/presentation/shared/hooks/useDeleteOrderRoad";
import { useDeleteOrderVehicle } from "~/presentation/shared/hooks/useDeleteOrderVehicle";
import { useFetchRoadQuery } from "~/presentation/shared/hooks/useFetchRoadQuery";
import {
  consultOrderItemsPriceChange,
  getRoadConnectionSeats,
  getRoadSeats,
} from "~/presentation/shared/utils";
import { logError } from "~/presentation/shared/utils/errors";
import { DeleteOrderItemDialog } from "~/presentation/shared/views/DeleteOrderItemDialog";
import { SendVoucherInWhatsappDialog } from "~/presentation/shared/views/SendVoucherInWhatsappDialog";
import { log } from "~/utils/log";
import { CantOrderCancellation } from "../../components/CantOrderCancellation";
import { ConfirmOrderCancel } from "../../components/ConfirmOrderCancel";
import { ConfirmOrderCancellation } from "../../components/ConfirmOrderCancellation";
import { SomeOrderOrderCancellation } from "../../components/SomeOrderDontCancellation";
import { CreateAssessmentData } from "../../utils";
import { ConfirmRequestApprovalDialog } from "../../views/OrderItem/components/ConfirmRequestApprovalDialog";
import { QuoteOfflineHotelForm } from "../../views/OrderItem/components/QuoteOfflineHotelForm";
import { useAdditionalInfo } from "../useAdditionalInfo";
import { UseOrderItemsOptions, UseOrderItemsResult } from "./types";
import { useAdvanceItem } from "./useAdvanceItem";
import { useAirwayItem } from "./useAirwayItem";
import { useHotelItem } from "./useHotelItem";
import { useRoadItem } from "./useRoadItem";
import { useVehicleItem } from "./useVehicleItem";
import { useModalPortal } from "~/core/modules/DeprecatedBooking/components/ModalPortal";
import { AirwaySeatsSelectDialog } from "./useAirwaySeats/AirwaySeatsSelectDialog/AirwaySeatsSelectDialog";
import { queryClient } from "~/services/queryClient";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { useCancelOrderChannel } from "~/presentation/shared/hooks/useCancelOrderChannel";
import { useIssueOrderChannel } from "~/presentation/shared/hooks/useIssueOrderChannel";
import { useAirwayBookingChannel } from "~/presentation/shared/hooks/useAirwayBookingChannel/useAirwayBookingChannel";
import { useViolatedPoliciesInOrderDetails } from "./useViolatedPolicies/useViolatedPolicies";
import { Button } from "~/components/Button";
import { Text } from "~/components/Text";
import {
  BrokenPolicyJustification,
  BrokenPolicyJustificationContext,
} from "../../views/OrderItem/hooks/BrokenPolicyJustificationContext";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG_TAG = "Order/OrderPage/useOrderItems";

const SNACKBAR_MESSAGES = {
  REQUEST_APPROVAL_SUCCESS_MESSAGE: "Solicitação de aprovação feita com sucesso",
  ORDER_APPROVAL_SUCCESS_MESSAGE: "Pedido aprovado com sucesso",
  ORDER_REPROVAL_SUCCESS_MESSAGE: "Pedido rejeitado com sucesso",
  CANCEL_ORDER_SUCCESS_MESSAGE: "Pedido cancelado com sucesso",
  CANCEL_ORDER_ERROR_MESSAGE: "Falha ao cancelar pedido",
  REQUEST_CANCEL_ORDER_SUCCESS_MESSAGE: "Solitação de cancelamento feita com sucesso",
  REQUEST_CANCEL_ORDER_ERROR_MESSAGE: "Falha ao solicitar cancelamento",
  REQUEST_APPROVAL_ERROR_MESSAGE: "Falha ao solicitar aprovação",
  LOAD_COST_CENTERS_ERROR_MESSAGE: "Falha ao carregar centros de custo",
  REJECT_QUOTE_OFFLINE_HOTEL_ERROR_MESSAGE: "Falha ao rejeitar cotação",
  REJECT_QUOTE_OFFLINE_HOTEL_SUCCESS_MESSAGE: "Cotação rejeitada com sucesso",
} as const;

const DEFAULT_FORM_DATA = {
  observation: "",
} as CreateAssessmentData;

export function useOrderItems({
  order,
  orderId,
  enabled, // eslint-disable-line @typescript-eslint/no-unused-vars
  refetchOrder,
  refetchOrderHistory,
  refetchCancellationConditions,
}: UseOrderItemsOptions): UseOrderItemsResult {
  const { user } = useUser();
  const customerId = user.customer?.uuid || (order?.customer.uuid as string);
  const { controller } = useModalPortal();

  const canCancelOrder =
    !!orderId &&
    [OrderStatus.OPEN, OrderStatus.REJECTED, OrderStatus.CANCELING, OrderStatus.QUOTING].includes(
      order?.status as OrderStatus
    );

  const canIssuerOrderStatuses = [
    OrderStatus.OPEN,
    OrderStatus.REJECTED,
    OrderStatus.ON_APPROVAL,
    OrderStatus.APPROVED,
  ];

  const { allBrokenPolicyItemsAreJustificated } = useContext(BrokenPolicyJustificationContext);
  const canIssueOrder =
    !!orderId &&
    canIssuerOrderStatuses.includes(order?.status as OrderStatus) &&
    allBrokenPolicyItemsAreJustificated;

  useCancelOrderChannel({
    orderId,
    enabled: canCancelOrder,
    onCancelOrder: (data: any) => {
      dialogService.popAll();

      refetchOrder();
      refetchOrderHistory();

      const message = data?.success ? "Pedido cancelado com sucesso" : "Falha ao cancelar pedido";

      snackbarService.showSnackbar(message, data?.success ? "success" : "error");
    },
  });

  useAirwayBookingChannel({
    enabled: order && order.items?.airway?.flights?.some((flight) => !flight.tracker),
    orderId,
    onAirwayBooked: (data) => {
      dialogService.popAll();

      refetchOrder();

      data?.forEach((item: any) => {
        let message = `Reserva do vôo ${item?.number} feita com sucesso`;

        if (!item?.success) {
          message = `Falha ao reservar vôo ${item?.number}`;
        }

        snackbarService.showSnackbar(message, item?.success ? "success" : "error");
      });
    },
  });

  const { isLoading: isIssuingOrder } = useIssueOrderChannel({
    orderId,
    enabled: canIssueOrder,
    onOrderIssued: (data) => {
      dialogService.popAll();

      refetchOrder();

      let message = data?.success ? "Pedido emitido com sucesso" : "Falha na emissão do pedido";

      if (order?.items.hotel?.rooms?.some((r) => r.isOffline)) {
        message = "Solicitação de aprovação feita com sucesso";
      }

      if (data?.success) {
        queryClient.invalidateQueries([QueryKeys.BUDGETS_VIEW, customerId]);
        queryClient.invalidateQueries([QueryKeys.ORDER_BUDGET_BALANCE, order?.uuid]);
      }

      snackbarService.showSnackbar(message, data?.success ? "success" : "error");
    },
  });

  const itemsAvailableToAdd = useMemo<OrderItems[]>(() => {
    return !order || order.expenseOnly ? [] : Object.values(OrderItems);
  }, [order]);

  const { data: currentTime } = useQuery(
    [QueryKeys.CURRENT_TIME],
    async () => await timeService.now(),
    {
      enabled: true,
      refetchOnMount: "always",
      staleTime: QueryTimes.NORMAL,
      onError: (error: any) => {
        // eslint-disable-next-line no-console
        console.log(error);
      },
    }
  );

  const { mutate: onOfflineReject } = useMutation(
    (orderId: string) => orderService.rejectQuote(orderId),
    {
      onMutate: () => {
        dialogService.showDialog(<LoadingDialog message="Rejeitando cotação" />);
      },
      onError: (error) => {
        dialogService.popDialog();

        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.REJECT_QUOTE_OFFLINE_HOTEL_ERROR_MESSAGE,
        });
      },
      onSuccess: () => {
        dialogService.popDialog();
        queryClient.invalidateQueries([QueryKeys.ORDERS]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.REJECT_QUOTE_OFFLINE_HOTEL_SUCCESS_MESSAGE,
          "success",
          5000
        );
      },
    }
  );

  const { mutateAsync: mutateCreateAssessment, isLoading: isRequestingApproval } = useAssessment({
    order,
    logTag: LOG_TAG,
    refetchOrder,
    refetchOrderHistory,
  });

  const { mutate: mutateRemoveSeat } = useMutation(
    async ({ orderItemId, seatId }: { orderItemId: string; seatId: string }) =>
      await orderAirwayService.removeSeat({ orderItemId, seatId }),
    {
      onMutate: () => {
        dialogService.showDialog(<LoadingDialog message="Removendo assentos" />);
      },
      onSuccess: () => {
        dialogService.popAll();

        queryClient.invalidateQueries([QueryKeys.ORDERS]);
      },
    }
  );

  const fetchCustomerBranch = useCallback(
    (name: string) => branchService.find({ customerId, name }).then((data) => data.data),
    [customerId]
  );
  const [roadRebooking, onRoadRebooking] = useState({
    isRebookingRoad: false,
    isRoadRebookError: false,
  });

  const { isRebookingRoad, isRoadRebookError } = roadRebooking;
  const hasOrderHotelOffline = order?.items.hotel?.rooms.some((room) => room.isOffline);

  const isSuccessfulRoadRebooking = !isRebookingRoad && !isRoadRebookError;

  const { mutateAsync: onConsultOrderItemsPrice } = useMutation(
    async (orderUuid?: string) => {
      return await orderService.getOrderItemsPriceChange(orderUuid ?? orderId);
    },
    {
      onSuccess() {
        if (!hasOrderHotelOffline) {
          queryClient.invalidateQueries({ queryKey: [QueryKeys.ORDERS] });
        }
      },
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Verificando alteração de preço" />);
      },
      onError: (error) => log.e(LOG_TAG, error),
      onSettled: () => dialogService.popAll(),
    }
  );

  const onRequestApproval = useCallback(
    async (brokenPolicyItems: BrokenPolicyJustification[]) => {
      const orderItems = await onConsultOrderItemsPrice(order?.uuid);
      const someAirwayIncreasedPrice = orderItems?.airway?.some(({ newValue }) => newValue);

      const onContinue = () => {
        if (someAirwayIncreasedPrice) {
          queryClient.invalidateQueries([QueryKeys.ORDERS]);
        }

        dialogService.popAll();
        dialogService.showDialog(
          <ConfirmRequestApprovalDialog
            enabled={enabled}
            customerId={customerId}
            defaultData={DEFAULT_FORM_DATA}
            order={order}
            onRoadRebooking={onRoadRebooking}
            onSubmit={(data) => mutateCreateAssessment({ ...data, brokenPolicyItems })}
            onCloseClick={() => dialogService.popDialog()}
          />
        );
      };

      const negativeButton = (
        <Button variant="tertiary" type="reset" onClick={() => dialogService.popDialog()}>
          <Text>Cancelar</Text>
        </Button>
      );

      const positiveButton = (
        <Button onClick={onContinue}>
          <Text>Prosseguir</Text>
        </Button>
      );

      const { onOrderItemsPriceChange } = consultOrderItemsPriceChange({
        negativeButton,
        positiveButton,
        order,
      });

      if (someAirwayIncreasedPrice) {
        dialogService.popDialog();
        return onOrderItemsPriceChange(orderItems);
      }

      return onContinue();
    },
    [order, enabled, customerId, DEFAULT_FORM_DATA, onRoadRebooking, mutateCreateAssessment]
  );

  const { mutateAsync: mutateCancelOrder, isLoading: isCancellingOrder } = useMutation(
    async () => await orderService.cancel(orderId),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Cancelando pedido" />);
      },
      onSuccess: () => {
        if (
          [OrderStatus.OPEN, OrderStatus.REJECTED, OrderStatus.QUOTED].includes(
            order?.status as OrderStatus
          )
        ) {
          dialogService.popAll();
          queryClient.invalidateQueries([QueryKeys.ORDERS]);
          queryClient.invalidateQueries([QueryKeys.ORDER_HISTORY]);
        }
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CANCEL_ORDER_ERROR_MESSAGE,
        });

        dialogService.popAll();
      },
    }
  );

  const onCancelOrder = useCallback(() => {
    dialogService.showDialog(<ConfirmOrderCancel onCancelOrder={mutateCancelOrder} />);
  }, [mutateCancelOrder]);

  const { mutateAsync: mutateOrderCancellation, isLoading: isRequestingOrderCancellation } =
    useMutation(
      async (justification?: string) =>
        await orderService.requestCancellation({ orderId, justification }),
      {
        onMutate: () => {
          dialogService.popDialog();
          dialogService.showDialog(<LoadingDialog message="Solicitando cancelamento do pedido" />);
        },
        onSuccess: () => {
          refetchOrder();
          refetchOrderHistory();

          snackbarService.showSnackbar(
            SNACKBAR_MESSAGES.REQUEST_CANCEL_ORDER_SUCCESS_MESSAGE,
            "success"
          );
        },
        onError: (error) => {
          logError({
            error,
            logTag: LOG_TAG,
            defaultErrorMessage: SNACKBAR_MESSAGES.REQUEST_CANCEL_ORDER_ERROR_MESSAGE,
          });
        },
        onSettled: () => dialogService.popAll(),
      }
    );

  const [cancellationConditions, setCancellationConditions] = useState<OrderTaxCancellation>();

  const [cancelOrder, setCancelOrder] = useState(false);

  useEffect(() => {
    if (cancelOrder) {
      onRequestOrderCancellation();
    }
  }, [cancelOrder]);

  const onRequestOrderCancellation = useCallback(async () => {
    if (!cancelOrder) {
      dialogService.showDialog(<LoadingDialog message="Carregando condições de cancelamento" />);

      const { data: cancellation } = await refetchCancellationConditions();
      setCancellationConditions(cancellation);

      dialogService.popDialog();
      setCancelOrder(true);

      return;
    }

    const hasARoad = !!order?.items.road;
    const valueTaxCancellation = cancellationConditions?.road
      .map((item) => item.value)
      .reduce((a, b) => a + b, 0);

    const dontAllowsCancel = cancellationConditions?.road
      .filter((item) => !item.allowsCancellation)
      .map((item) => item.uuid);

    const dontAllowsCancelItems = order?.items.road?.travels.filter((item) =>
      dontAllowsCancel?.includes(item.uuid)
    );

    dialogService.showDialog(
      <>
        {hasARoad ? (
          <>
            {dontAllowsCancelItems?.length === 0 ? (
              <ConfirmOrderCancellation
                onRequestOrderCancellation={mutateOrderCancellation}
                taxCancellation={valueTaxCancellation}
              />
            ) : dontAllowsCancelItems?.length === order?.items.road?.travels.length ? (
              <CantOrderCancellation />
            ) : (
              <SomeOrderOrderCancellation
                onRequestOrderCancellation={mutateOrderCancellation}
                taxCancellation={valueTaxCancellation}
                dontAllowsCancelItems={dontAllowsCancelItems}
              />
            )}
          </>
        ) : (
          <ConfirmOrderCancel onRequestCancelOrder={mutateOrderCancellation} />
        )}
      </>
    );
  }, [mutateOrderCancellation, order, cancelOrder]);

  const { isAdvanceItemExpanded, onAddOrderAdvance, toggleAdvanceItemVisible } = useAdvanceItem({
    order,
    orderId,
    enabled,
  });

  const { isAirwayItemExpanded, toggleAirwayItemVisible, onOpenFlightDetails } = useAirwayItem({
    orderId,
    enabled,
  });

  const { isHotelItemExpanded, toggleHotelItemVisible, onOpenHotelDetails, onOpenHotelPolicies } =
    useHotelItem({
      orderId,
      enabled,
    });

  const { isAdditionalInfoExpanded, toggleAdditionalInfoVisible } = useAdditionalInfo();

  const { isRoadItemExpanded, toggleRoadItemVisible } = useRoadItem({
    orderId,
    enabled,
  });

  const { isVehicleItemExpanded, toggleVehicleItemVisible } = useVehicleItem({
    orderId,
    enabled,
  });

  const { delete: deleteOrderAirway } = useDeleteOrderAirway(LOG_TAG, {
    order,
  });

  const { delete: deleteOrderRoad } = useDeleteOrderRoad(LOG_TAG, {
    order,
  });

  const { delete: deleteOrderHotel } = useDeleteOrderHotel(LOG_TAG, {
    order,
  });

  const { delete: deleteOrderVehicle } = useDeleteOrderVehicle(LOG_TAG, {
    order,
  });

  const openDeleteItemDialog = useCallback(
    (callback: () => void) => {
      const totalRoadItems = order?.items.road?.travels.length || 0;
      const totalHotelItems = order?.items.hotel?.rooms.length || 0;
      const totalAirwayItems = order?.items.airway?.flights.length || 0;
      const totalVehicleItems = order?.items.vehicle?.vehicles.length || 0;
      const combinedAirwaySize = new Set(
        order?.items.airway?.flights.map((airway) => airway.tracker)
      ).size;

      const totalItems = totalAirwayItems + totalVehicleItems + totalHotelItems + totalRoadItems;

      dialogService.showDialog(
        totalItems === 1 || combinedAirwaySize === 1 ? (
          <DeleteOrderItemDialog onConfirm={callback} />
        ) : (
          <SimpleDialog
            title="Deseja remover este item do pedido?"
            negativeTitle="Cancelar"
            positiveTitle="Excluir"
            onPositiveClick={callback}
          />
        )
      );
    },
    [order]
  );

  const onDeleteOrderAirway = useCallback(
    (itemId: string) => {
      openDeleteItemDialog(() => {
        deleteOrderAirway({ itemId }).then(() => controller.hide());
      });
    },
    [openDeleteItemDialog, deleteOrderAirway]
  );

  const onDeleteOrderHotel = useCallback(
    (itemId: string) => {
      openDeleteItemDialog(() => deleteOrderHotel({ itemId }));
    },
    [openDeleteItemDialog, deleteOrderHotel]
  );

  const onDeleteOrderRoad = useCallback(
    (itemId: string) => {
      openDeleteItemDialog(() => {
        deleteOrderRoad({ itemId });
      });
    },
    [openDeleteItemDialog, deleteOrderRoad]
  );

  const onDeleteOrderVehicle = useCallback(
    (itemId: string) => {
      openDeleteItemDialog(() => deleteOrderVehicle({ itemId }));
    },
    [openDeleteItemDialog, deleteOrderVehicle]
  );

  const onSendVoucherInWhatsapp = useCallback(() => {
    const defaultData = {
      phone: order?.travelers.filter(({ phone }) => phone?.length > 0).at(0)?.phone || "",
    };

    dialogService.showDialog(
      <SendVoucherInWhatsappDialog defaultData={defaultData} order={order} />
    );
  }, [order]);

  const onReloadAirwayTracker = useCallback((itemId: string) => {
    return orderAirwayTrackerService.findTracker(itemId);
  }, []);

  const onNotifyIssuerThatOrderExpired = useCallback(async () => {
    return orderService
      .notifyIssuerThatOrderExpired(order?.uuid as string)
      .then(() => refetchOrder())
      .catch(({ data }) => snackbarService.showSnackbar(data, "error"));
  }, [order]);

  const { mutate: handleOrderCancellation } = useMutation(
    (orderId: string) => orderService.cancel(orderId),
    {
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar("Falha ao cancelar o pedido", "error");
      },
    }
  );

  const { mutate: onMarkSeatsAsSelected } = useMutation(
    async ({ orderItemId, seats }: { orderItemId: string; seats: AirwaySeatsForm }) =>
      await orderAirwayService.markSeatsAsSelected({
        orderItemId,
        seats,
      }),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingModal message="Marcando assentos" title="Aguarde" />);
      },
      onSuccess: () => {
        dialogService.popAll();
        snackbarService.showSnackbar("Assentos marcados com sucesso", "success");
        queryClient.invalidateQueries([QueryKeys.ORDERS]);
      },
      onError: (error: { message: string }) => {
        snackbarService.showSnackbar(error.message, "error");

        queryClient.invalidateQueries([QueryKeys.ORDERS]);

        dialogService.popAll();

        return error;
      },
    }
  );

  const onOpenHotelOptionDetails = (item: OrderHotelItem, option: OrderHotelOfflineOption) => {
    const defaultData = {
      options: [
        {
          ...option,
          regimen: { name: option.regimen },
          roomType: { name: option.roomType },
          checkIn: option.checkIn || new Date(item.checkOut),
          checkOut: option.checkOut || new Date(item.checkOut),
        },
      ],
    };

    dialogService.showDialog(
      <QuoteOfflineHotelForm order={order as Order} item={item} defaultData={defaultData} />
    );
  };

  const onOpenAirwaySeats = ({
    itemId,
    passengers,
    item,
  }: {
    itemId: string;
    passengers: Passenger[];
    item: OrderAirwaySegment;
  }) => {
    dialogService.showDialog(
      <AirwaySeatsSelectDialog
        orderItemId={itemId}
        onCloseClick={() => dialogService.popDialog()}
        onSubmit={onMarkSeatsAsSelected}
        passengers={passengers}
        item={item}
      />
    );
  };

  const onRefetchRoadQuery = useFetchRoadQuery({
    onCancelOrder: handleOrderCancellation,
  })(orderId, order?.travelers || [], order?.uuid || "");

  const onRemakeOrder = () => {
    dialogService.showDialog(
      <LoadingModal
        message="Isso pode demorar alguns minutos."
        title="Aguarde, estamos refazendo o seu pedido."
      />
    );

    onRefetchRoadQuery.refetch().then(() => {
      dialogService.popDialog();
    });
  };

  const onOpenRoadDetails = useCallback((data: OrderRoadSegment) => {
    const roadData = {
      ...data,
      company: data.companyName,
      from: data.departure,
      to: data.arrival,
    };

    const seats = getRoadSeats(data);
    const seatsConnection = getRoadConnectionSeats(data);

    dialogService.showDialog(
      <RoadDetails data={roadData} seats={seats} seatsConnection={seatsConnection} />
    );
  }, []);

  const { itemsNumberWithViolatedPolicies } = useViolatedPoliciesInOrderDetails({ order });

  return {
    order,
    orderId,
    isLoading: !order,
    itemsAvailableToAdd,
    isAirwayItemExpanded,
    isVehicleItemExpanded,
    isHotelItemExpanded,
    isRoadItemExpanded,
    isAdvanceItemExpanded,
    isAdditionalInfoExpanded,
    isRequestingApproval,
    isRequestingOrderCancellation,
    isCancellingOrder,
    isRebookingRoad,
    isRoadRebookError,
    isSuccessfulRoadRebooking,
    itemsNumberWithViolatedPolicies,
    currentTime,
    onReloadAirwayTracker,
    refetchOrder,
    refetchOrderHistory,
    onNotifyIssuerThatOrderExpired,
    onRemakeOrder,
    onAddOrderAdvance,
    onSendVoucherInWhatsapp,
    onRequestOrderCancellation,
    toggleAirwayItemVisible,
    toggleHotelItemVisible,
    toggleRoadItemVisible,
    fetchCustomerBranch,
    toggleVehicleItemVisible,
    toggleAdvanceItemVisible,
    toggleAdditionalInfoVisible,
    onOpenHotelDetails,
    onOpenHotelPolicies,
    onOpenFlightDetails,
    onOfflineReject,
    onOpenRoadDetails,
    onCancelOrder,
    onRequestApproval,
    onDeleteOrderAirway,
    onDeleteOrderHotel,
    onDeleteOrderRoad,
    onDeleteOrderVehicle,
    onOpenHotelOptionDetails,
    onOpenAirwaySeats,
    onRemoveSeat: mutateRemoveSeat,
    isIssuingOrder,
  };
}
