import { FC, useCallback, useMemo } from "react";
import { OrderItems, Road } from "~/application/types";
import { NavigatorUtils } from "~/application/utils";
import { log } from "~/application/utils/log";
import { useQueryGoRoads } from "~/presentation/Booking/BookingRoad/hooks/useQueryGoRoads";
import { useLogTag } from "~/presentation/core/contexts/LogTagContext";
import { useFilters } from "~/presentation/shared/hooks/useFilters";
import { RoadSeatFormData } from "~/presentation/shared/views/RoadSeatsDialog";
import { BookingRoadSteps, DYNAMIC_ROAD_FILTERS, STATIC_ROAD_FILTERS } from "../../../constants";
import { useRoadsPage } from "../../../contexts/RoadsPageContext";
import { GoRoadsSectionUI } from "./GoRoadsSectionUI";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { usePoliciesFormats } from "~/presentation/shared/hooks/usePoliciesFormats";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { RoadBudgetActionType } from "../../../hooks/useRoadBudget/type";

const VIEW_ID = BookingRoadSteps.SELECT_GO_TICKET;

interface GoRoadsSectionProps {
  onOpenDetails?: (road: Road) => void;
}

export const GoRoadsSection: FC<GoRoadsSectionProps> = ({ onOpenDetails }) => {
  const { LOG_TAG } = useLogTag();

  const { bookingStep, roadReducer, roadQuery, isManyStretch, roadBudget } = useRoadsPage();
  const { contexts, user } = useUser();
  
  const customerId = user?.customer?.uuid || contexts?.customer?.uuid;
  
  const { bookingInfo } = roadReducer;
  
  const isMobile = useMobile();
  
  const { data, isAvailable, isLoading } = useQueryGoRoads({
    enabled:
      bookingStep.current === VIEW_ID || bookingStep.current === `${bookingStep.currentIndex}`,
    roadQueryLinks: roadQuery.links,
    retryCount: 2,
  });

  const roadsList = useMemo(() => data.sort((a, b) => a.totalPrice - b.totalPrice), [data]);

  const roads = usePoliciesFormats({
    customerId,
    data: roadsList,
    itemType: OrderItems.ROAD,
  }) as Road[];

  const currentStep = bookingStep.currentIndex;

  const onSelectRoad = useCallback(
    (road: Road, { seats, seatsConnection }: RoadSeatFormData) => {
      log.i(LOG_TAG, "onSelectRoad", { road, seats });

      if (!isManyStretch) {
        roadReducer.dispatch({
          type: "SET_GO_ROAD",
          payload: { road, seats, seatsConnection },
        });
        return;
      }
      
      const sectionAlreadySelected = roadReducer.bookingInfo.stretches.some(
        (stre) => stre.index === currentStep
      );
      
      roadReducer.dispatch({
        type: sectionAlreadySelected ? "UPDATE_STRETCH" : "SET_STRETCH",
        payload: { road, seats, seatsConnection, index: currentStep },
      });

      if (!isMobile) {
        NavigatorUtils.scrollToTop(true);
      }
    },
    [currentStep, roadReducer, isManyStretch, isMobile]
  );
  
  const { filteredData, filters, onFilterChange } = useFilters({
    filters: STATIC_ROAD_FILTERS,
    filtersGenerator: DYNAMIC_ROAD_FILTERS,
    data: roads,
    sort: false,
  });

  const allRoadBudget = roadBudget.state.departure.length === filteredData.length;

  const onSelectAllBookingRoad = useCallback(() => {
    if(allRoadBudget) {
      roadBudget?.dispatch?.({ 
        paylod: [], 
        type: RoadBudgetActionType.DEPARTURE 
      })
    }

    if(!allRoadBudget) {
      roadBudget?.dispatch?.({ 
        paylod: filteredData, 
        type: RoadBudgetActionType.DEPARTURE 
      })
    }
  }, [filteredData])
  
  return (
    <GoRoadsSectionUI
      data={filteredData}
      filters={filters}
      allRoadBudget={allRoadBudget}
      onSelectAllBookingRoad={onSelectAllBookingRoad}
      isLoading={roadQuery.isLoading || isLoading}
      isAvailable={isAvailable}
      bookingInfo={bookingInfo}
      onFilterChange={onFilterChange}
      onSelectRoad={onSelectRoad}
      onOpenDetails={onOpenDetails}
    />
  );
};

GoRoadsSection.displayName = "GoRoadsSection";
