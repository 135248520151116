import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { Popover, PopoverAnchor, PopoverClose, PopoverContent, PopoverTrigger } from "~/core/shared/components/Popover";
import { Branch, CostCenter, CustomerEmployee, CustomerEmployeesGroup, Project } from "~/application/types";
import { CardFooter } from "~/core/modules/DeprecatedBooking/components/Card";
import { DoubleCalendar } from "~/core/shared/components/DoubleCalendar";
import { GenerateReport } from "~/application/usecases/Reports/types";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { MultiSelect } from "~/components/Input/MultiSelect";
import { displayDate, toDate } from "~/utils/date.utils";
import { SvgCalendar } from "~/components/Icon/icons";
import { Card, CardBody } from "~/components/Card";
import { TextInput } from "~/components/Input";
import { Button } from "~/components/Button";
import { Col, Row } from "~/components/Grid";
import { Flex } from "~/components/Flex";

export interface ReportingCenterFormProps {
  branches?: Branch[];
  isLoadingBranches: boolean;
  costCenters?: CostCenter[];
  isLoadingCostCenters: boolean;
  passengers?: CustomerEmployee[];
  isLoadingPassengers: boolean;
  issuers?: CustomerEmployee[];
  isLoadingIssuers: boolean;
  approvers?: CustomerEmployee[];
  isLoadingApprovers: boolean;
  projects?: Project[];
  isLoadingProjects: boolean;
  customerEmployeesGroups?: CustomerEmployeesGroup[];
  isLoadingCustomerEmployeesGroups: boolean;
  onFetchBranches: (search: string) => void;
  onFetchCostCenters: (search: string) => void;
  onFetchProjects: (search: string) => void;
  onFetchCustomerEmployeesGroups: (search: string) => void;
  onSearchName: ({
    approver,
    issuer,
    passenger,
    costCenter,
    project,
    customerEmployeeGroup,
  }: {
    passenger?: string | undefined;
    approver?: string | undefined;
    issuer?: string | undefined;
    costCenter?: string | undefined;
    project?: string | undefined;
    customerEmployeeGroup?: string | undefined;
  }) => void;
}

export function ReportingCenterForm({
  onSearchName,
  branches,
  isLoadingBranches,
  onFetchBranches,
  costCenters,
  isLoadingCostCenters,
  onFetchCostCenters,
  passengers,
  isLoadingPassengers,
  issuers,
  isLoadingIssuers,
  approvers,
  isLoadingApprovers,
  projects,
  isLoadingProjects,
  onFetchProjects,
  customerEmployeesGroups,
  isLoadingCustomerEmployeesGroups,
  onFetchCustomerEmployeesGroups
}: ReportingCenterFormProps) {
  const { control, watch, register, setValue, resetField } = useFormContext<GenerateReport>();

  const { travelDate, shoppingDate } = watch();

  const excludeShoppingDates = useCallback(() => {
    setValue("shoppingDate.end", undefined);
    setValue("shoppingDate.start", "");
  }, [resetField]);

  const excludeTravelDates = useCallback(() => {
    setValue("travelDate.end", undefined);
    setValue("travelDate.start", "");
  }, [resetField]);

  const handleShoppingDateClick = useCallback(
    (date: Date) => {
      if (!shoppingDate.start) {
        return setValue("shoppingDate.start", date);
      }

      if (shoppingDate.end && toDate(shoppingDate.end as string).getTime() === date.getTime()) {
        return setValue("shoppingDate.end", undefined);
      }

      if (date.getTime() < toDate(shoppingDate.start as string).getTime()) {
        setValue("shoppingDate.start", date);
      } else {
        setValue("shoppingDate.end", date);
      }
    },
    [shoppingDate, setValue]
  );

  const handleDateClick = useCallback(
    (date: Date) => {
      if (!travelDate?.start) {
        setValue("travelDate.start", date);
      } else if (!travelDate.end) {
        if (date.getTime() < toDate(travelDate.start as string).getTime()) {
          setValue("travelDate.start", date);
        } else {
          setValue("travelDate.end", date);
        }
      } else {
        setValue("travelDate.start", date);
        setValue("travelDate.end", undefined);
      }
    },
    [travelDate, setValue]
  );

  return (
    <>
      <Row css={{ "@mxlg": { flexDirection: "column", gap: "$4" } }}>
        <Col sz={{ "@initial": "3", "@mxlg": "auto" }}>
          <FormControl name="branches" control={control}>
            <FieldLabel>Filial</FieldLabel>
            <MultiSelect
              onInputChange={onFetchBranches}
              onChange={(selectedBranches: Branch[]) => {
                if (selectedBranches.some((branch) => branch.name === "Marcar todos")) {
                  setValue("areAllBranchesSelected", true);
                  return setValue("branches", branches?.filter((branch) => branch.uuid) || []);
                }

                if (selectedBranches.some((branch) => branch.name === "Desmarcar todos")) {
                  setValue("areAllBranchesSelected", false);
                  return setValue("branches", []);
                }

                setValue("areAllBranchesSelected", false);
                setValue("branches", selectedBranches);
              }}
              placeholder="Selecione a filial"
              isLoading={isLoadingBranches}
              options={branches}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.uuid}
              portalled
            />
          </FormControl>
        </Col>

        <Col sz={{ "@initial": "3", "@mxlg": "auto" }}>
          <FormControl name="issuers" control={control}>
            <FieldLabel>Solicitante</FieldLabel>
            <MultiSelect
              onInputChange={(e) => onSearchName({ issuer: e })}
              onChange={(selectedIssuers: CustomerEmployee[]) => {
                if (selectedIssuers.some((issuer) => issuer.name === "Marcar todos")) {
                  setValue("areAllIssuersSelected", true);
                  return setValue("issuers", issuers?.filter((issuer) => issuer.uuid) || []);
                }

                if (selectedIssuers.some((issuer) => issuer.name === "Desmarcar todos")) {
                  setValue("areAllIssuersSelected", false);
                  return setValue("issuers", []);
                }

                setValue("areAllIssuersSelected", false);
                setValue("issuers", selectedIssuers);
              }}
              placeholder="Selecione o solicitante"
              isLoading={isLoadingIssuers}
              options={issuers}
              getOptionLabel={(option) => `${option.name} ${option.lastName || ""}`}
              getOptionValue={(option) => option.uuid}
              portalled
            />
          </FormControl>
        </Col>

        <Col sz={{ "@initial": "3", "@mxlg": "auto" }}>
          <FormControl name="approvers" control={control}>
            <FieldLabel>Aprovador</FieldLabel>
            <MultiSelect
              onInputChange={(e) => onSearchName({ approver: e })}
              onChange={(selectedApprovers: CustomerEmployee[]) => {
                if (selectedApprovers.some((approver) => approver.name === "Marcar todos")) {
                  setValue("areAllApproversSelected", true);
                  return setValue("approvers", approvers?.filter((approver) => approver.uuid) || []);
                }

                if (selectedApprovers.some((approver) => approver.name === "Desmarcar todos")) {
                  setValue("areAllApproversSelected", false);
                  return setValue("approvers", []);
                }

                setValue("areAllApproversSelected", false);
                setValue("approvers", selectedApprovers);
              }}
              placeholder="Selecione o aprovador"
              isLoading={isLoadingApprovers}
              options={approvers}
              getOptionLabel={(option) => `${option.name} ${option.lastName || ""}`}
              getOptionValue={(option) => option.uuid}
              portalled
            />
          </FormControl>
        </Col>

        <Col sz={{ "@initial": "3", "@mxlg": "auto" }}>
          <FormControl name="passengers" control={control}>
            <FieldLabel>Passageiro</FieldLabel>
            <MultiSelect
              onInputChange={(e) => onSearchName({ passenger: e })}
              onChange={(selectedPassengers: CustomerEmployee[]) => {
                if (selectedPassengers.some((passenger) => passenger.name === "Marcar todos")) {
                  setValue("areAllPassengersSelected", true);
                  return setValue("passengers", passengers?.filter((passenger) => passenger.uuid) || []);
                }

                if (selectedPassengers.some((passenger) => passenger.name === "Desmarcar todos")) {
                  setValue("areAllPassengersSelected", false);
                  return setValue("passengers", []);
                }

                setValue("areAllPassengersSelected", false);
                setValue("passengers", selectedPassengers);
              }}
              placeholder="Selecione o passageiro"
              isLoading={isLoadingPassengers}
              options={passengers}
              getOptionLabel={(option) => `${option.name} ${option.lastName || ""}`}
              getOptionValue={(option) => option.uuid}
              portalled
            />
          </FormControl>
        </Col>
      </Row>

      <Row css={{ "@mxlg": { flexDirection: "column", gap: "$4" } }}>
        <Popover>
          <Flex gap="6" css={{ width: "50%", "@mxlg": { width: "100%" } }}>
            <FormControl control={control} name="shoppingDate.start" required css={{ flex: 1 }}>
              <FieldLabel>Data início da compra</FieldLabel>

              <PopoverTrigger asChild css={{ "@mxlg": { mb: "$10" } }}>
                <TextInput
                  {...register("shoppingDate.start", {
                    required: true,
                  })}
                  placeholder="Selecione a data"
                  leftIcon={SvgCalendar}
                  value={shoppingDate?.start && displayDate(shoppingDate?.start as string)}
                  style={{ width: "100%" }}
                  onChange={() => null}
                  onBlur={() => null}
                  readOnly
                />
              </PopoverTrigger>
              <PopoverAnchor />
            </FormControl>

            <FormControl control={control} name="shoppingDate.end" required css={{ flex: 1 }}>
              <FieldLabel>Data fim da compra</FieldLabel>

              <PopoverTrigger asChild>
                <TextInput
                  {...(register("shoppingDate.end"),
                  {
                    required: true,
                  })}
                  placeholder="Selecione a data"
                  leftIcon={SvgCalendar}
                  style={{ width: "100%" }}
                  value={shoppingDate?.end ? displayDate(shoppingDate?.end as string) : undefined}
                  readOnly
                />
              </PopoverTrigger>
            </FormControl>

            <PopoverContent>
              <Flex as={Card} direction="column">
                <DoubleCalendar
                  date={
                    shoppingDate?.start ? toDate(shoppingDate?.start as string) : new Date()
                  }
                  activeMinDate={toDate(shoppingDate?.start as string)}
                  activeMaxDate={toDate(shoppingDate?.end as string)}
                  maxDate={new Date()}
                  onDateClick={handleShoppingDateClick}
                />

                <CardFooter>
                  <CardBody
                    css={{
                      "@mxlg": {
                        p: "$2",
                      },
                    }}
                  >
                    <Flex
                      gap="4"
                      css={{
                        justifyContent: "flex-end",
                        "@mxlg": {
                          justifyContent: "space-around",
                        },
                      }}
                    >
                      <PopoverClose asChild>
                        <Button
                          css={{
                            "@mxlg": {
                              fontSize: "$sm",
                              height: "$5",
                            },
                          }}
                          variant="tertiary"
                          onClick={excludeShoppingDates}
                        >
                          Excluir
                        </Button>
                      </PopoverClose>

                      <PopoverClose asChild>
                        <Button
                          css={{
                            "@mxlg": {
                              fontSize: "$sm",
                              height: "$5",
                            },
                          }}
                          variant="secondary"
                        >
                          Aplicar
                        </Button>
                      </PopoverClose>
                    </Flex>
                  </CardBody>
                </CardFooter>
              </Flex>
            </PopoverContent>
          </Flex>
        </Popover>

        <Popover>
          <Flex gap="6" css={{ width: "50%", "@mxlg": { width: "100%" } }}>
            <FormControl
              control={control}
              name="travelDate.start"
              required={!!travelDate?.start}
              css={{ flex: 1 }}
            >
              <FieldLabel>Data da viagem (ida)</FieldLabel>

              <PopoverTrigger asChild css={{ "@mxlg": { mb: "$10" } }}>
                <TextInput
                  {...register("travelDate.start")}
                  placeholder="Selecione a data"
                  leftIcon={SvgCalendar}
                  value={travelDate?.start && displayDate(travelDate?.start as string)}
                  style={{ width: "100%" }}
                  onChange={() => null}
                  onBlur={() => null}
                  readOnly
                />
              </PopoverTrigger>
              <PopoverAnchor />
            </FormControl>

            <FormControl
              control={control}
              name="travelDate.end"
              required={!!travelDate?.start}
              css={{ flex: 1 }}
            >
              <FieldLabel>Data da viagem (volta)</FieldLabel>

              <PopoverTrigger asChild>
                <TextInput
                  {...register("travelDate.end")}
                  placeholder="Selecione a data"
                  leftIcon={SvgCalendar}
                  style={{ width: "100%" }}
                  value={travelDate?.end ? displayDate(travelDate?.end as string) : undefined}
                  readOnly
                />
              </PopoverTrigger>
            </FormControl>

            <PopoverContent>
              <Flex as={Card} direction="column">
                <DoubleCalendar
                  date={travelDate?.start ? toDate(travelDate?.start as string) : new Date()}
                  activeMinDate={toDate(travelDate?.start as string)}
                  activeMaxDate={toDate(travelDate?.end as string)}
                  onDateClick={handleDateClick}
                />

                <CardFooter>
                  <CardBody
                    css={{
                      "@mxlg": {
                        p: "$2",
                      },
                    }}
                  >
                    <Flex
                      gap="4"
                      css={{
                        justifyContent: "flex-end",
                        "@mxlg": {
                          justifyContent: "space-around",
                        },
                      }}
                    >
                      <PopoverClose asChild>
                        <Button
                          css={{
                            "@mxlg": {
                              fontSize: "$sm",
                              height: "$5",
                            },
                          }}
                          variant="tertiary"
                          onClick={excludeTravelDates}
                        >
                          Excluir
                        </Button>
                      </PopoverClose>

                      <PopoverClose asChild>
                        <Button
                          css={{
                            "@mxlg": {
                              fontSize: "$sm",
                              height: "$5",
                            },
                          }}
                          variant="secondary"
                        >
                          Aplicar
                        </Button>
                      </PopoverClose>
                    </Flex>
                  </CardBody>
                </CardFooter>
              </Flex>
            </PopoverContent>
          </Flex>
        </Popover>
      </Row>

      <Row css={{ "@mxlg": { flexDirection: "column", gap: "$4" } }}>
        <Col sz={{ "@initial": "4", "@mxlg": "auto" }}>
          <FormControl name="costCenters" control={control}>
            <FieldLabel>Centro de custo</FieldLabel>
            <MultiSelect
              onInputChange={onFetchCostCenters}
              onChange={(selectedCostCenters: CostCenter[]) => {
                if (selectedCostCenters.some((costCenter) => costCenter.name === "Marcar todos")) {
                  setValue("areAllCostCentersSelected", true);
                  return setValue("costCenters", costCenters?.filter((costCenter) => costCenter.uuid) || []);
                }

                if (selectedCostCenters.some((costCenter) => costCenter.name === "Desmarcar todos")) {
                  setValue("areAllCostCentersSelected", false);
                  return setValue("costCenters", []);
                }

                setValue("areAllCostCentersSelected", false);
                setValue("costCenters", selectedCostCenters);
              }}
              placeholder="Selecione o centro de custo"
              isLoading={isLoadingCostCenters}
              options={costCenters}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.uuid}
              portalled
            />
          </FormControl>
        </Col>

        <Col sz={{ "@initial": "4", "@mxlg": "auto" }}>
          <FormControl name="projects" control={control}>
            <FieldLabel>Projeto</FieldLabel>
            <MultiSelect
              onInputChange={onFetchProjects}
              onChange={(selectedProjects: Project[]) => {
                if (selectedProjects.some((project) => project.name === "Marcar todos")) {
                  setValue("areAllProjectsSelected", true);
                  return setValue("projects", projects?.filter((project) => project.uuid) || []);
                }

                if (selectedProjects.some((project) => project.name === "Desmarcar todos")) {
                  setValue("areAllProjectsSelected", false);
                  return setValue("projects", []);
                }

                setValue("areAllProjectsSelected", false);
                setValue("projects", selectedProjects);
              }}
              placeholder="Selecione o projeto"
              isLoading={isLoadingProjects}
              options={projects}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.uuid}
              portalled
            />
          </FormControl>
        </Col>


        <Col sz={{ "@initial": "4", "@mxlg": "auto" }}>
          <FormControl name="customerEmployeesGroups" control={control}>
            <FieldLabel>Grupo de funcionários</FieldLabel>
            <MultiSelect
              onInputChange={onFetchCustomerEmployeesGroups}
              onChange={(selectedCustomerEmployeesGroups: CustomerEmployeesGroup[]) => {
                if (selectedCustomerEmployeesGroups.some((group) => group.description === "Marcar todos")) {
                  setValue("areAllCustomerEmployeesGroupsSelected", true);
                  return setValue("customerEmployeesGroups", customerEmployeesGroups?.filter((group) => group.uuid) || []);
                }

                if (selectedCustomerEmployeesGroups.some((group) => group.description === "Desmarcar todos")) {
                  setValue("areAllCustomerEmployeesGroupsSelected", false);
                  return setValue("customerEmployeesGroups", []);
                }

                setValue("areAllCustomerEmployeesGroupsSelected", false);
                setValue("customerEmployeesGroups", selectedCustomerEmployeesGroups);
              }}
              placeholder="Selecione o grupo"
              isLoading={isLoadingCustomerEmployeesGroups}
              options={customerEmployeesGroups}
              getOptionLabel={(option) => option.description}
              getOptionValue={(option) => option.uuid}
              portalled
            />
          </FormControl>
        </Col>
      </Row>
    </>
  );
}