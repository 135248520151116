import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Branch, CostCenter, CustomerEmployee, CustomerEmployeesGroup, Project } from "~/application/types";
import { createSelectOptions, REPORTING_CENTER_MENU_TABS, ReportingCenterTab } from "./utils";
import { GenerateReport, ShoppingReportData } from "~/application/usecases/Reports/types";
import { CounterTab, CounterTabBadge, TabBar, Tabs } from "~/components/Tabs";
import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { ReportingCenterForm } from "./components/ReportingCenterForm";
import { ReportsList } from "./components/ReportsList";
import { Card, CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { reports } from "./utils/reports";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { Form } from "~/components/Form";
import { Link } from "~/components/Link";

export interface ReportingCenterContainerProps {
  activeTab?: ReportingCenterTab;
  branches?: Branch[];
  isLoadingBranches: boolean;
  costCenters?: CostCenter[];
  isLoadingCostCenters: boolean;
  passengers?: CustomerEmployee[];
  isLoadingPassengers: boolean;
  issuers?: CustomerEmployee[];
  isLoadingIssuers: boolean;
  approvers?: CustomerEmployee[];
  isLoadingApprovers: boolean;
  projects?: Project[];
  isLoadingProjects: boolean;
  customerEmployeesGroups?: CustomerEmployeesGroup[];
  isLoadingCustomerEmployeesGroups: boolean;
  onGenerateReport: (data: GenerateReport) => void;
  onGenerateGeneralReport: (data: ShoppingReportData) => void;
  onFetchBranches: (search: string) => void;
  onFetchCostCenters: (search: string) => void;
  onFetchProjects: (search: string) => void;
  onFetchCustomerEmployeesGroups: (search: string) => void;
  onSearchName: ({
    approver,
    issuer,
    passenger,
  }: {
    passenger?: string | undefined;
    approver?: string | undefined;
    issuer?: string | undefined;
  }) => void;
}

export function ReportingCenterContainer({
  activeTab = ReportingCenterTab.ALL,
  onSearchName,
  onGenerateReport,
  onGenerateGeneralReport,
  branches,
  isLoadingBranches,
  onFetchBranches,
  costCenters,
  isLoadingCostCenters,
  onFetchCostCenters,
  passengers,
  isLoadingPassengers,
  issuers,
  isLoadingIssuers,
  approvers,
  isLoadingApprovers,
  projects,
  isLoadingProjects,
  onFetchProjects,
  customerEmployeesGroups,
  isLoadingCustomerEmployeesGroups,
  onFetchCustomerEmployeesGroups,
}: ReportingCenterContainerProps) {
  const methods = useForm<GenerateReport>({
    defaultValues: {
      areAllApproversSelected: false,
      areAllBranchesSelected: false,
      areAllCostCentersSelected: false,
      areAllCustomerEmployeesGroupsSelected: false,
      areAllIssuersSelected: false,
      areAllPassengersSelected: false,
      areAllProjectsSelected: false,
    }
  });

  const [reportsList, setReportsList] = useState(reports);

  const reportingCenterFormProps = {
    onSearchName,
    isLoadingBranches,
    isLoadingApprovers,
    isLoadingCostCenters,
    isLoadingPassengers,
    isLoadingIssuers,
    isLoadingProjects,
    isLoadingCustomerEmployeesGroups,
    costCenters: createSelectOptions(costCenters),
    passengers: createSelectOptions(passengers),
    issuers: createSelectOptions(issuers),
    approvers: createSelectOptions(approvers),
    projects: createSelectOptions(projects),
    customerEmployeesGroups: createSelectOptions(customerEmployeesGroups, "description"),
    branches: createSelectOptions(branches),
    onFetchBranches,
    onFetchCostCenters,
    onFetchProjects,
    onFetchCustomerEmployeesGroups,
  };

  useEffect(() => {
    if (activeTab !== ReportingCenterTab.ALL) {
      setReportsList(reports.filter((report) => report.category === activeTab));
      return;
    }

    setReportsList(reports);
  }, [activeTab]);

  return (
    <Tabs value={activeTab}>
      <AppBar>
        <AppBarContainer>
          <AppBarHeading
            title="Central de relatórios"
            description="Faça o download de todos os relatórios disponíveis"
          />
        </AppBarContainer>

        <Container>
          <TabBar>
            {REPORTING_CENTER_MENU_TABS.map(({ title, id }) => {
              const count = id === ReportingCenterTab.ALL
                ? reports.length
                : reports.filter((report) => report.category === id).length;

              return (
                <Link to={`#${id}`} title={title} key={id}>
                  <CounterTab id={id} value={id}>
                    <Text>{title}</Text>
                    <CounterTabBadge>{count}</CounterTabBadge>
                  </CounterTab>
                </Link>
              );
            })}
          </TabBar>
        </Container>
      </AppBar>

      <Container css={{ py: "$10", "@mxlg": { p: "$5" } }}>
        <FormProvider {...methods}>
          <Card>
            <CardBody>
              <Flex direction="column" gap="6">
                <Text size="5" fw="600">Filtros</Text>
                <Form id="generate-report" css={{ gap: "$6" }}>
                  <ReportingCenterForm {...reportingCenterFormProps} />
                </Form>
              </Flex>
            </CardBody>
          </Card>

          <ReportsList
            data={reportsList}
            onGenerateReport={onGenerateReport}
            onGenerateGeneralReport={onGenerateGeneralReport}
          />
        </FormProvider>
      </Container>
    </Tabs>
  );
}