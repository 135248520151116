import { useCallback, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDebounce } from "use-debounce";

import { Budget, BudgetView, Customer } from "~/application/types";
import { dialogService } from "~/components/DialogStack";
import { QueryKeys, QueryTimes } from "~/constants";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { logError } from "~/presentation/shared/utils/errors";
import { budgetService } from "~/application/usecases/Budget";
import { snackbarService } from "~/components/SnackbarStack";
import { queryClient } from "~/services/queryClient";
import { getCurrentYear } from "~/utils/date.utils";
import { BudgetDialog } from "../views/Budgets/components/BudgetDialog";
import { useBudgetTab } from "./useBudgetTab";
import { BudgetTab } from "../views/Budgets/utils";

const LOG_TAG = "Customer/CustomerPage/useBudgets";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar orçamentos",
  LOAD_VIEW_ERROR_MESSAGE: "Falha ao carregar visualização dos orçamentos",
  CREATE_SUCCESS_MESSAGE: "Novo orçamento adicionado",
  CREATE_ERROR_MESSAGE: "Falha ao criar orçamento",
  UPDATE_SUCCESS_MESSAGE: "Orçamento atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar orçamento"
} as const;

export type UseBudgetsOptions = {
  customerId: string;
  enabled: boolean;
};

export type UseBudgetsResult = {
  budgets?: Budget[];
  budgetsView?: BudgetView[];
  currentViewPage: number;
  currentPage: number;
  lastPage: number;
  lastViewPage: number;
  isLoadingBudgets: boolean;
  isLoadingBudgetsView: boolean;
  search: string;
  searchView: string;
  year: number;
  yearsOptions?: IOption[];
  activeTab: BudgetTab;
  onCreateBudget: (customer: Customer) => void;
  onEditBudget: (item: Budget, customer: Customer) => void;
  onGoToPage: (value: number) => void;
  onGoToViewPage: (value: number) => void;
  fetchBudgets: (name: string) => Promise<Budget[]>;
  onSearch: (value: string) => void;
  onSearchView: (value: string) => void;
  onChangeYear: (year: number) => void;
};

export interface IOption {
  label: string;
  value: number;
}

export const useBudgets = ({ customerId, enabled }: UseBudgetsOptions): UseBudgetsResult => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentViewPage, setCurrentViewPage] = useState<number>(1);
  const [search, setSearch] = useState("");
  const [searchView, setSearchView] = useState("");
  const [year, setYear] = useState<number>(getCurrentYear());

  const [searchDebounced] = useDebounce(search, 700);
  const [searchViewDebounced] = useDebounce(searchView, 700);

  const tabValue = useBudgetTab();

  const { data: budgets, isLoading: isLoadingBudgets } = useQuery(
    [QueryKeys.BUDGETS, customerId, enabled, currentPage, searchDebounced],
    () => budgetService.find({ customerId, page: currentPage, name: searchDebounced }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
    }
  );

  const { data: budgetsView, isLoading: isLoadingBudgetsView } = useQuery(
    [QueryKeys.BUDGETS_VIEW, customerId, currentViewPage, searchViewDebounced, year],
    () => budgetService.view({ customerId, page: currentViewPage, name: searchViewDebounced, year }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_VIEW_ERROR_MESSAGE,
        });

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_VIEW_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutate: mutateCreateBudget } = useMutation(
    [QueryKeys.BUDGETS, customerId],
    async (data: Budget) => await budgetService.create({ customerId, data }),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Criando novo orçamento" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.BUDGETS, customerId]);
        queryClient.invalidateQueries([QueryKeys.BUDGETS_VIEW, customerId]);
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutate: mutateUpdateBudget } = useMutation(
    async (data: Budget) => await budgetService.update({ customerId, data }),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Atualizando orçamento" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.BUDGETS, customerId]);
        queryClient.invalidateQueries([QueryKeys.BUDGETS_VIEW, customerId]);
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const onOpenCreateModalBudget = useCallback(
    (customer: Customer) => dialogService.showDialog(
      <BudgetDialog isNew onSubmit={mutateCreateBudget} customer={customer} />
    ),
    [mutateCreateBudget]
  );

  const onOpenUpdateModalBudget = useCallback(
    (data: Budget, customer: Customer) =>
      dialogService.showDialog(
        <BudgetDialog defaultData={data} onSubmit={mutateUpdateBudget} customer={customer} />
      ),
    [mutateUpdateBudget]
  );

  const fetchBudgets = useCallback(
    async (name: string) => {
      const response = await budgetService.find({ customerId, name });
      return response.data;
    },
    [customerId]
  );

  const handleChangeSearch = useCallback((text: string) => {
    setSearch(text);
    setCurrentPage(1);
  }, []);

  const handleChangeSearchView = useCallback((text: string) => {
    setSearchView(text);
    setCurrentViewPage(1);
  }, []);

  const handleChangeYear = useCallback((year: number) => {
    setYear(year);
    setCurrentViewPage(1);
  }, []);

  const yearsOptions: IOption[] | undefined = budgetsView?.years.map((year) => ({
    label: String(year),
    value: year,
  }));

  return {
    budgets: budgets?.data,
    budgetsView: budgetsView?.data,
    currentPage,
    currentViewPage,
    lastPage: budgets?.meta?.last_page || 1,
    lastViewPage: budgetsView?.meta?.last_page || 1,
    isLoadingBudgets,
    isLoadingBudgetsView,
    search,
    searchView,
    onCreateBudget: onOpenCreateModalBudget,
    onEditBudget: onOpenUpdateModalBudget,
    onGoToPage: setCurrentPage,
    onGoToViewPage: setCurrentViewPage,
    fetchBudgets,
    onSearch: handleChangeSearch,
    onSearchView: handleChangeSearchView,
    year,
    yearsOptions,
    activeTab: tabValue,
    onChangeYear: handleChangeYear,
  };
};
