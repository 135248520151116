import { useCallback, useContext, useState } from "react";
import { Collapse } from "react-collapse";
import {
  Order,
  OrderHotelItem as OrderHotelItemType,
  OrderHotelOfflineOption,
  OrderItemStatus,
  OrderItems,
  OrderStatus,
  Traveler,
} from "~/application/types";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import {
  SvgCheck,
  SvgChevronDown,
  SvgChevronUp,
  SvgDownload,
  SvgInfo,
} from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { LazyList } from "~/components/LazyList";
import { DataListItem } from "~/components/List";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { TravelerListItem } from "~/core/shared/components/TravelerListItem";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { canMarkAsRejected, getVoucherDownloadUrl } from "~/presentation/shared/utils";
import { formatNumber } from "~/utils/string.utils";
import { OrderHotelCard } from "../../../../../../../../../presentation/shared/components/OrderHotelCard";
import { OrderHotelItemSkeleton } from "../../../../../../../../../presentation/shared/components/OrderHotelItemSkeleton";
import { ReasonRejectedItem } from "../../../../../../../../../presentation/shared/components/ReasonRejectedItem";
import { useOrder } from "../../../../../../../../../presentation/shared/hooks/useOrder/useOrder";
import { OrderApprovalItem } from "../../../../utils";
import { ApprovalOrderContext } from "../../hooks/ApprovalOrderContext";
import { ApprovalButtons } from "../ApprovalButtons";
import { ReprovalMessage } from "../ApprovalMessage";
import { OrderHotelOffline } from "../OrderHotelOfflineItem";
import { RemoveItemButton } from "../RemoveItemButton";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { AlertViolatedPoliciesPerProducts } from "~/presentation/shared/components/AlertViolatedPoliciePerProducts/AlertViolatedPoliciesPerProducts";
import { DateUtils } from "~/application/utils";
import { ItemBrokenPolicyJustication } from "~/presentation/shared/components/BrokenPolicyJusticationItem/ItemBrokenPolicyJusticationItem";
import { AdicionalTravelerInfo } from "~/presentation/shared/components/AdicionalTravelerInfo";
import { dialogService } from "~/components/DialogStack";
import { DownloadOrderVoucher } from "~/presentation/shared/components/DownloadOrderVoucher";
import { useDownload } from "~/presentation/shared/hooks/useDownload";
import { LoadingModal } from "~/presentation/shared/components/LoadingModal";

export interface OrderHotelItemProps {
  isLoading: boolean;
  data?: OrderHotelItemType[];
  canShowApprovalButtons?: boolean;
  onOpenDetails?: () => void;
  onOpenPolicies?: () => void;
  onDeleteItem?: (itemId: string) => void;
  onOpenOptionDetails: (item: OrderHotelItemType, option: OrderHotelOfflineOption) => void;
  itemIndex: number;
}

export function OrderHotelItem({
  data,
  canShowApprovalButtons,
  onOpenDetails,
  onOpenPolicies,
  onDeleteItem,
  onOpenOptionDetails,
  itemIndex,
}: OrderHotelItemProps) {
  const isMobile = useMobile();
  const [isDownloadingVoucher, setIsDownloadingVoucher] = useState(false);
  const { onDownloadIndividual } = useDownload({ logTag: "OrderHotelItem" });
  const onOpenMoreInfoDialog = (data: Traveler) => {
    dialogService.showDialog(<AdicionalTravelerInfo travelerId={data.uuid} order={order} />);
  };
  const [roomExpanded, setRoomExpanded] = useState(
    data?.map((room) => {
      return {
        id: room.uuid,
        roomExpanded: true,
        checked: room.options?.some((option) => option.status === OrderItemStatus.QUOTED),
      };
    }) as Array<{ id: string; roomExpanded: boolean; checked: boolean }>
  );

  const toggleRoomExpanded = useCallback(
    (room: OrderHotelItemType) => {
      setRoomExpanded((old) => {
        const index = old.findIndex((item) => item.id === room.uuid);

        const newRoom = {
          id: room.uuid,
          roomExpanded: old[index].roomExpanded ? false : true,
          checked: room.options?.some(
            (option) => option.status === OrderItemStatus.QUOTED
          ) as boolean,
        };

        return [...old.slice(0, index), newRoom, ...old.slice(index + 1)];
      });
    },
    [roomExpanded]
  );

  const { items, approveItem, reproveItem } = useContext(ApprovalOrderContext);
  const { order } = useOrder();
  const { contexts } = useUser();

  const listRenderer = useCallback(
    (item: OrderHotelItemType, index: number) => {
      const data = {
        item: OrderApprovalItem.ROOM_HOTEL_ORDERS,
        itemOrderId: item.uuid,
      };

      const isOpen = roomExpanded.find((room) => room.id === item.uuid)?.roomExpanded as boolean;

      const checked = roomExpanded.find((room) => room.id === item.uuid)?.checked as boolean;

      const isRejectedItem =
        items?.rejected &&
        items.rejected?.some(({ itemOrderId }) => itemOrderId === data.itemOrderId);

      const shouldMarkAsRejected = canMarkAsRejected({
        reasonRejected: item.reasonRejected,
        order,
      });

      const reasonRejected = item.reasonRejected;
      const orderStatus = order?.status as OrderStatus;
      const canRemoveItem =
        (!!contexts.customer &&
          orderStatus !== OrderStatus.QUOTING &&
          onDeleteItem &&
          ![
            OrderStatus.APPROVED,
            OrderStatus.ON_APPROVAL,
            OrderStatus.ISSUED,
            OrderStatus.CANCELED,
            OrderStatus.CANCELING,
            OrderStatus.PENDING_ISSUE,
          ].includes(orderStatus)) ||
        contexts.agency;

      const someOptionQuoted = item.options?.some(
        (option) => option.status === OrderItemStatus.QUOTED
      );

      const canShowBrokenPolicyJustification =
        (!!item?.violatedPolicies?.length &&
          [OrderStatus.OPEN, OrderStatus.REJECTED].includes(orderStatus)) ||
        item.brokenPolicyJustification;

      return item.isOffline ? (
        <Box
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "$6",
          }}
        >
          <DataListItem
            css={{
              p: "$4 $6",
            }}
          >
            <Col>
              <Text size="4" css={{ fw: "400" }}>
                {`Quarto ${index + 1}`}
              </Text>
            </Col>

            {shouldMarkAsRejected && (
              <Tag variant="error-light">
                <Text>Reprovado</Text>
              </Tag>
            )}

            {orderStatus !== OrderStatus.CANCELED && (
              <Tooltip
                content={<TooltipLabel>{isOpen ? "Recolher itens" : "Ver itens"}</TooltipLabel>}
              >
                <IconButton
                  size="lg"
                  onClick={() => toggleRoomExpanded(item)}
                  css={{
                    fill: checked ? "$success-base" : "",
                  }}
                >
                  <Icon as={checked ? SvgCheck : isOpen ? SvgChevronDown : SvgChevronUp} />
                </IconButton>
              </Tooltip>
            )}
          </DataListItem>

          <Collapse isOpened={isOpen}>
            <Flex justify="between" direction={{ "@mxlg": "column" }}>
              {someOptionQuoted && (
                <Text size="5" fw="500">
                  Cotação desejada
                </Text>
              )}
              <ApprovalButtons
                itemIndex={itemIndex}
                approveItem={approveItem}
                reproveItem={reproveItem}
                item={data}
              />
            </Flex>

            <Box>
              <Flex gap="8" direction="column">
                <OrderHotelOffline
                  item={item}
                  order={order}
                  onItemExpand={toggleRoomExpanded}
                  onOpenOptionDetails={onOpenOptionDetails}
                  canShowApprovalButtons={canShowApprovalButtons}
                  approveItem={approveItem}
                  reproveItem={reproveItem}
                />

                <Text as="h4">Hóspedes</Text>

                {item.guests.map((passenger) => {
                  const toggleIsDownloadingVoucher = () => {
                    setIsDownloadingVoucher((old) => !old);
                  };
                  const traveler = order?.travelers.find((t) => passenger.uuid === t.uuid);
                  return (
                    <Card css={{ border: "none" }} key={passenger.uuid}>
                      <Flex justify="around" gap="2" css={{ p: "$6" }}>
                        <Flex direction="column" gap="3">
                          <Text fw="600" size={{ "@mxlg": "3" }}>
                            NOME
                          </Text>
                          <Text size={{ "@mxlg": "2" }}>{`${passenger?.fullName}`}</Text>
                        </Flex>

                        <Flex direction="column" gap="3">
                          <Text fw="600" size={{ "@mxlg": "2" }}>
                            CPF
                          </Text>
                          <Text size={{ "@mxlg": "2" }}>{`${passenger?.cpf}`}</Text>
                        </Flex>

                        <Flex direction="column" gap="3">
                          <Text fw="600" size={{ "@mxlg": "2" }}>
                            RG
                          </Text>
                          <Text size={{ "@mxlg": "2" }}>{passenger.rg}</Text>
                        </Flex>

                        {traveler?.group?.description && (
                          <Flex direction="column" gap="3">
                            <Text size={{ "@mxlg": "3" }} fw="600">
                              GRUPO
                            </Text>
                            <Text size={{ "@mxlg": "2" }}>{traveler?.group?.description}</Text>
                          </Flex>
                        )}

                        <Flex direction="column" gap="3">
                          <Text fw="600" size={{ "@mxlg": "2" }}>
                            DATA DE NASCIMENTO
                          </Text>
                          <Text size={{ "@mxlg": "2" }}>
                            {DateUtils.smallDateFormat(passenger.dateBirth)}
                          </Text>
                        </Flex>
                        {orderStatus === OrderStatus.ISSUED && (
                          <Flex
                            direction="column"
                            align={"center"}
                            gap="1"
                            wrap={{ "@mxlg": "wrap" }}
                            css={{
                              maxWidth: "150px",
                            }}
                          >
                            <Text
                              fw="600"
                              css={{
                                fontSize: "$sm",
                                ta: "center",
                              }}
                            >
                              Baixar voucher individual
                            </Text>
                            <DownloadOrderVoucher
                              icon={null}
                              onDownload={() =>
                                onDownloadIndividual({
                                  order: order as Order,
                                  customerEmployeeId: passenger.uuid,
                                })
                              }
                              downloadUrl={getVoucherDownloadUrl(order)}
                              onDownloaded={toggleIsDownloadingVoucher}
                              onIconClick={toggleIsDownloadingVoucher}
                            >
                              <IconButton
                                css={{
                                  size: "$8",
                                  background: "#0064C5",
                                  opacity: 0.9,
                                  "&:hover": {
                                    background: "#0064C5",
                                    opacity: 1,
                                  },
                                }}
                              >
                                <SvgDownload />
                              </IconButton>
                            </DownloadOrderVoucher>
                          </Flex>
                        )}
                        <Flex align="center">
                          <IconButton size={"md"} onClick={() => onOpenMoreInfoDialog(passenger)}>
                            <Icon as={SvgInfo} />
                          </IconButton>
                        </Flex>
                      </Flex>
                      <LoadingModal
                        isOpen={isDownloadingVoucher}
                        message="Baixando voucher do pedido"
                      />
                    </Card>
                  );
                })}
                {isRejectedItem && <ReprovalMessage itemIndex={itemIndex} item={data} />}
              </Flex>
            </Box>
          </Collapse>

          <hr
            style={{
              marginTop: "2rem",
              border: "1px solid  #d6cbcb",
            }}
          />
        </Box>
      ) : (
        <Card
          css={{
            border: shouldMarkAsRejected ? "1px solid red" : "none",
            backgroundColor: "transparent",
          }}
        >
          <CardBody
            css={{
              p: 0,
            }}
          >
            <Flex direction="column" gap="6" key={item.uuid}>
              <Flex
                css={{
                  "@mxlg": {
                    px: "$3",
                    py: "$3",
                  },
                }}
                justify="between"
                direction={{ "@mxlg": "column" }}
              >
                <Text size="5">Quarto {formatNumber(1 + index, 2)}</Text>

                <ApprovalButtons
                  itemIndex={itemIndex}
                  approveItem={approveItem}
                  reproveItem={reproveItem}
                  item={data}
                />
              </Flex>

              <OrderHotelCard
                data={item}
                onOpenDetails={onOpenDetails}
                onDeleteItem={onDeleteItem}
                onOpenPolicies={onOpenPolicies}
              />
              {canShowBrokenPolicyJustification && (
                <ItemBrokenPolicyJustication
                  itemId={item.uuid}
                  brokenPolicyJustification={item.brokenPolicyJustification}
                  css={{
                    width: "100%",
                  }}
                />
              )}

              <Flex justify="end">
                {canRemoveItem && <RemoveItemButton onRemove={() => onDeleteItem?.(item.uuid)} />}
              </Flex>
              <Text size="5">Hóspedes</Text>

              <LazyList
                items={item.guests}
                render={(guest) => <TravelerListItem data={guest} key={guest.uuid} />}
                skeletonHeight={88}
                skeletonQuantity={2}
              />

              {shouldMarkAsRejected && <ReasonRejectedItem reasonRejected={reasonRejected} />}
            </Flex>

            {isRejectedItem && <ReprovalMessage itemIndex={itemIndex} item={data} />}
          </CardBody>
        </Card>
      );
    },
    [
      contexts,
      items,
      roomExpanded,
      onOpenDetails,
      onOpenPolicies,
      approveItem,
      reproveItem,
      canShowApprovalButtons,
      onDeleteItem,
      order,
      toggleRoomExpanded,
    ]
  );

  return (
    <Box>
      <Flex direction="column" gap="2">
        <AlertViolatedPoliciesPerProducts order={order} itemType={OrderItems.HOTEL} />

        <LazyList
          gap="8"
          items={data}
          render={listRenderer}
          skeletonQuantity={1}
          SkeletonComponent={<OrderHotelItemSkeleton />}
        />
      </Flex>
    </Box>
  );
}
