import { useCallback } from "react";
import { Order, OrderItems, OrderStatus } from "~/application/types";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { H4 } from "~/components/Typography";
import { AdditionalInfoListItem } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/components/AdditionalInfoListItem";
import { OrderAddItemListItem } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/components/OrderAddItemListItem";
import { OrderAdditionalInfoItem } from "~/presentation/shared/components/OrderAdditionalInfoItem";
import { OrderAdvanceItem } from "~/presentation/shared/components/OrderAdvanceItem";
import {
  getAdvanceFrom,
  getAirwayFrom,
  getHotelFrom,
  getRoadFrom,
  getVehicleFrom,
  isAirwayRejected,
  isHotelRejected,
  isRoadRejected,
  isVehicleRejected,
} from "~/presentation/shared/utils/order-functions";
import { UseOrderItemsResult } from "../hooks/types";
import { AgencyOrderCart } from "./AgencyOrderCart";
import { OrderAirwayItem } from "./Airway";
import OrderHotelItem from "./Hotel/OrderHotelListItem";
import { OrderItemListItem } from "./OrderItemListItem";
import { OrderRoadItem } from "./Road/OrderRoadItem";
import { OrderVehicleItem } from "./Vehicle/OrderVehicleItem";

type TabOrderItemsProps = UseOrderItemsResult & {
  onSendOfflineQuote: (orderId: string) => void;
  onSendVoucherInWhatsapp: () => void;
  onVoucherDownloaded: () => void;
  onVoucherClick: () => void;
};

export function TabOrderItems({
  order,
  isLoading,
  isAirwayItemExpanded,
  isVehicleItemExpanded,
  isHotelItemExpanded,
  isRoadItemExpanded,
  isAdvanceItemExpanded,
  isAdditionalInfoExpanded,
  itemsAvailableToAdd,
  onDeleteOfflineQuote,
  onIssueOrder,
  onOpenHotelOptionDetails,
  toggleAdditionalInfoVisible,
  onOpenRoadDetails,
  onSendOfflineQuote,
  toggleAirwayItemVisible,
  toggleVehicleItemVisible,
  toggleHotelItemVisible,
  toggleRoadItemVisible,
  toggleAdvanceItemVisible,
  onOpenFlightDetails,
  onOpenAddTrackerDialog,
  onSendVoucherInWhatsapp,
  onCancelOrder,
  onQuoteOfflineHotel,
  onRequestApproval,
  onReturnOrderStatus,
  onVoucherDownloaded,
  onVoucherClick,
  onCopyText,
  onOpenEditOrderExpirationDateDialog,
  onDeleteOrderAirway,
  onDeleteOrderHotel,
  onDeleteOrderRoad,
  onDeleteOrderVehicle,
  onAddOrderAdvance,
}: TabOrderItemsProps) {
  const orderId = order?.uuid;
  const airwayIsRejected = isAirwayRejected(order);
  const hotelIsRejected = isHotelRejected(order);
  const vehicleIsRejected = isVehicleRejected(order);
  const roadIsRejected = isRoadRejected(order);

  const airway = getAirwayFrom({ order });
  const hotel = getHotelFrom({ order });
  const vehicle = getVehicleFrom({ order });
  const road = getRoadFrom({ order });
  const advances = getAdvanceFrom({ order });

  const renderAirwayItem = useCallback(
    () => (
      <OrderItemListItem
        isOpen={isAirwayItemExpanded}
        onItemExpand={toggleAirwayItemVisible}
        item={{
          type: OrderItems.AIRWAY,
          isRejected: airwayIsRejected,
        }}
        orderId={orderId}
        key={OrderItems.AIRWAY}
      >
        <>
          <OrderAirwayItem
            isLoading={isLoading}
            data={airway}
            onOpenDetails={onOpenFlightDetails}
            onOpenAddTrackerDialog={onOpenAddTrackerDialog}
            onDeleteItem={onDeleteOrderAirway}
          />
        </>
      </OrderItemListItem>
    ),
    [
      isLoading,
      isAirwayItemExpanded,
      toggleAirwayItemVisible,
      onOpenFlightDetails,
      onDeleteOrderAirway,
      airwayIsRejected,
      airway,
      orderId,
    ]
  );

  const renderHotelItem = useCallback(
    () => (
      <OrderItemListItem
        isOpen={isHotelItemExpanded}
        onItemExpand={toggleHotelItemVisible}
        item={{
          type: OrderItems.HOTEL,
          isRejected: hotelIsRejected,
        }}
        orderId={orderId}
        key={OrderItems.HOTEL}
      >
        <OrderHotelItem
          isLoading={isLoading}
          data={hotel}
          onQuote={onQuoteOfflineHotel}
          onDeleteOfflineQuote={onDeleteOfflineQuote}
          onOpenOptionDetails={onOpenHotelOptionDetails}
          onDeleteItem={onDeleteOrderHotel}
        />
      </OrderItemListItem>
    ),
    [
      isLoading,
      isHotelItemExpanded,
      toggleHotelItemVisible,
      onQuoteOfflineHotel,
      onDeleteOfflineQuote,
      onOpenHotelOptionDetails,
      onDeleteOrderHotel,
      hotelIsRejected,
      hotel,
      orderId,
    ]
  );

  const renderVehicleItem = useCallback(
    () => (
      <OrderItemListItem
        isOpen={isVehicleItemExpanded}
        onItemExpand={toggleVehicleItemVisible}
        item={{
          type: OrderItems.VEHICLE,
          isRejected: vehicleIsRejected,
        }}
        orderId={orderId}
        key={OrderItems.VEHICLE}
      >
        <OrderVehicleItem
          isLoading={isLoading}
          data={vehicle}
          onDeleteItem={onDeleteOrderVehicle}
        />
      </OrderItemListItem>
    ),
    [
      isLoading,
      isVehicleItemExpanded,
      toggleVehicleItemVisible,
      vehicleIsRejected,
      vehicle,
      orderId,
    ]
  );

  const renderRoadItem = useCallback(
    () => (
      <OrderItemListItem
        isOpen={isRoadItemExpanded}
        onItemExpand={toggleRoadItemVisible}
        item={{
          type: OrderItems.ROAD,
          isRejected: roadIsRejected,
        }}
        orderId={orderId}
        key={OrderItems.ROAD}
      >
        <OrderRoadItem
          onOpenDetails={onOpenRoadDetails}
          onDeleteItem={onDeleteOrderRoad}
          onCopyText={onCopyText}
          isLoading={isLoading}
          data={road}
        />
      </OrderItemListItem>
    ),
    [
      isLoading,
      isRoadItemExpanded,
      toggleRoadItemVisible,
      onDeleteOrderRoad,
      onOpenRoadDetails,
      roadIsRejected,
      road,
      orderId,
    ]
  );

  const renderAdvanceItem = useCallback(
    () => (
      <OrderItemListItem
        isOpen={isAdvanceItemExpanded}
        onItemExpand={toggleAdvanceItemVisible}
        item={{ type: OrderItems.ADVANCE }}
        orderId={orderId}
        key={OrderItems.ADVANCE}
      >
        <OrderAdvanceItem isLoading={isLoading} data={advances} />
      </OrderItemListItem>
    ),
    [isLoading, isAdvanceItemExpanded, toggleAdvanceItemVisible, advances, orderId]
  );

  const renderAdditionalInfo = useCallback(
    () => (
      <AdditionalInfoListItem
        isOpen={isAdditionalInfoExpanded}
        onExpand={toggleAdditionalInfoVisible}
      >
        <OrderAdditionalInfoItem order={order} />
      </AdditionalInfoListItem>
    ),
    [isAdditionalInfoExpanded, toggleAdditionalInfoVisible, order]
  );

  const orderCartProps = {
    order,
    isLoading,
    onRequestApproval,
    onIssueOrder,
    onReturnOrderStatus,
    onVoucherClick,
    onVoucherDownloaded,
    onSendVoucherInWhatsapp,
    onSendOfflineQuote,
    onCancelOrder,
    onOpenEditOrderExpirationDateDialog,
  };

  const canShowAdditionalInfo = !!order?.costCenter?.name;

  const canAddOrderItems = ![
    OrderStatus.APPROVED,
    OrderStatus.ISSUED,
    OrderStatus.CANCELED,
    OrderStatus.CANCELING,
    OrderStatus.ON_APPROVAL,
    OrderStatus.PENDING_ISSUE,
  ].includes(order?.status as OrderStatus) && itemsAvailableToAdd.length > 0;

  return (
    <Row>
      <Col
        sz="8"
        css={{
          "@mxlg": {
            width: "100%",
          },
        }}
      >
        {order?.itemsIncluded && order.itemsIncluded.length > 0 && (
          <Flex direction="column" gap="10">
            <H4
              css={{
                fw: "600",
                "@mxlg": {
                  textAlign: "center",
                  fontSize: "$lg",
                },
              }}
            >
              Itens do pedido
            </H4>

            <Flex direction="column" gap="8">
              {order.itemsIncluded.map(
                (item) =>
                ({
                  [OrderItems.AIRWAY]: renderAirwayItem(),
                  [OrderItems.HOTEL]: renderHotelItem(),
                  [OrderItems.VEHICLE]: renderVehicleItem(),
                  [OrderItems.ROAD]: renderRoadItem(),
                  [OrderItems.ADVANCE]: renderAdvanceItem(),
                  [OrderItems.HOTEL_OFFLINE]: [],
                }[item])
              )}

              {canShowAdditionalInfo && renderAdditionalInfo()}
            </Flex>
          </Flex>
        )}

        {canAddOrderItems && (
          <Flex direction="column" gap="10">
            <H4
              css={{
                fw: "600",
                "@mxlg": {
                  textAlign: "center",
                  fontSize: "$lg",
                },
              }}
            >
              Adicionar mais itens ao pedido
            </H4>

            <Flex direction="column" gap="8">
              {itemsAvailableToAdd.map((item) => {
                if (item !== OrderItems.HOTEL_OFFLINE)
                  return (
                    <OrderAddItemListItem
                      order={order as Order}
                      onAddItem={item === OrderItems.ADVANCE ? onAddOrderAdvance : undefined}
                      item={item}
                      key={item}
                    />
                  );
              })}
            </Flex>
          </Flex>
        )}
      </Col>

      <Col sz="4" css={{ "@mxlg": { width: 0 } }}>
        <AgencyOrderCart {...orderCartProps} />
      </Col>
    </Row>
  );
}
