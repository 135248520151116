import { useQueries, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { QueryKeys, QueryTimes } from "~/application/constants";
import { CustomerEmployee, FlightQuery, UserContext as UserContextEnum } from "~/application/types";
import { flightQueryService, orderService, travelerService } from "~/application/usecases";
import { log } from "~/application/utils/log";
import { snackbarService } from "~/components/SnackbarStack";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { LogTagProvider } from "~/presentation/core/providers/LogTagProvider";
import { StepItem, createStepItems } from "~/presentation/shared/utils";
import { queryClient } from "~/services/queryClient";
import { BookingAirwaySteps, createBookingMenu } from "./constants";
import { FlightsPageProvider } from "./contexts/FlightsPageContext";
import { useAirwayParams } from "./hooks/useAirwayParams";
import { useAirwayReducer } from "./hooks/useAirwayReducer";
import {
  AirwayBookingDispatchActionType,
  AirwayBookingStep,
  AirwayFlightQuery,
} from "./hooks/useAirwayReducer/types";
import { FlightsPageUI } from "./ui/FlightsPageUI";
import { useSearchParams } from "react-router-dom";
import { useDeleteOrderAirway } from "~/presentation/shared/hooks/useDeleteOrderAirway";
import { useAirwayBudget } from "./hooks/useAirwayBudget";
import { BrokenPolicyJustificationProvider } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/hooks/BrokenPolicyJustificationContext";
import { FlightOrderType } from "./ui/types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG_TAG = "Booking/BookingAirway/FlightsPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao procurar voos",
} as const;

export function FlightsPage() {
  const { user, contexts } = useUser();
  const airwayReducer = useAirwayReducer();
  const airwayBudget = useAirwayBudget();
  const [orderType, setOrderType] = useState(FlightOrderType.PRICE);

  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const { data: order, isFetching: isLoadingOrder } = useQuery(
    [QueryKeys.ORDERS, orderId],
    () => orderService.findById(orderId!),
    {
      staleTime: 1000 * 60 * 30, // 30 minutes
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
      enabled: !!orderId,
    }
  );

  const [issuerId, setIssuerId] = useState(user.profiles.customer?.uuid);
  const airwayParams = useAirwayParams();

  const formatAirwayParams = airwayParams?.stretch?.map(
    (stretch) =>
      ({
        adultQuantity: airwayParams.adultQuantity,
        childrenQuantity: airwayParams.childrenQuantity,
        reasonTrip: airwayParams.reasonTrip,
        dateGoSelected: stretch.outboundDate,
        dateReturnSelected: stretch.returnDate,
        destinationSelected: stretch.destination,
        originSelected: stretch.origin,
      } as FlightQuery)
  );

  const isInternational = formatAirwayParams?.some(
    ({ destinationSelected, originSelected }) =>
      destinationSelected.isInternational || originSelected.isInternational
  );

  const isManyStretch = (airwayParams?.stretch.length || 0) > 1;

  const canSelectIssuer =
    user.context === UserContextEnum.Agency && contexts.customer && !order?.issuer.uuid;

  const [currentStep, setCurrentStep] = useState(0);
  const [isShowingCart, setIsShowingCart] = useState(false);

  useEffect(() => {
    airwayReducer.dispatch({
      type: AirwayBookingDispatchActionType.ADULT_QUANTITY,
      payload: Number(searchParams.get("adultQuantity")),
    });
  }, []);

  const { delete: deleteOrderAirway } = useDeleteOrderAirway(LOG_TAG, {
    order,
  });

  const { data: flightQueryLinks, isFetching: isLoadingLinks } = useQuery(
    [QueryKeys.QUERY_LINKS_FLIGHTS, contexts.customer?.uuid, formatAirwayParams],
    () =>
      flightQueryService.query(contexts.customer.uuid, formatAirwayParams?.at(0) as FlightQuery),
    {
      cacheTime: QueryTimes.NONE,
      staleTime: QueryTimes.NONE,
      enabled: !isLoadingOrder && !!airwayParams && !!contexts.customer,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
    }
  );

  const flightQueries = useQueries({
    queries:
      formatAirwayParams?.map((query) => ({
        queryKey: [QueryKeys.QUERY_LINKS_FLIGHTS, query],
        enabled: !isLoadingLinks,
        retry: 1,
        queryFn: async () => await flightQueryService.query(contexts?.customer?.uuid, query),
      })) ?? [],
  });

  const BOOKING_MENU_STEPS = createBookingMenu({
    stretchLength: formatAirwayParams?.length || 0,
    isInternational,
  });

  const bookingSteps = useMemo<StepItem<BookingAirwaySteps | string>[]>(() => {
    return createStepItems(BOOKING_MENU_STEPS, {
      isBudget: !airwayBudget.activeBudget,
      params: formatAirwayParams?.at(0) as FlightQuery,
    });
  }, [airwayParams, airwayBudget, formatAirwayParams]);

  const bookingStep = useMemo<AirwayBookingStep>(
    () => ({
      current: bookingSteps[currentStep].key,
      currentIndex: currentStep,
      steps: bookingSteps,
      setStep: setCurrentStep,
    }),
    [bookingSteps, currentStep]
  );

  const currentFlightQuery = flightQueries.at(bookingStep.currentIndex);

  const flightQuery = useMemo<AirwayFlightQuery>(
    () => ({
      data: airwayParams,
      isLoading: isManyStretch ? !!currentFlightQuery?.isLoading : isLoadingLinks,
      links: isManyStretch ? currentFlightQuery?.data : flightQueryLinks,
    }),
    [flightQueryLinks, isLoadingLinks, airwayParams, currentFlightQuery]
  );

  const isOneWay = useMemo<boolean>(
    () => !airwayParams?.stretch?.at(0)?.returnDate,
    [airwayParams]
  );

  useEffect(() => {
    return () => {
      // TODO: Group queries by search key
      queryClient.invalidateQueries([QueryKeys.QUERY_FLIGHTS_GO]);
      queryClient.invalidateQueries([QueryKeys.QUERY_FLIGHTS_RETURN]);
      queryClient.invalidateQueries([QueryKeys.QUERY_FLIGHTS_COMBINED]);
    };
  }, []);

  const onSelectIssuer = useCallback(({ uuid }: CustomerEmployee) => {
    setIssuerId(uuid);
  }, []);

  useEffect(() => {
    if (order) {
      setIssuerId(order.issuer.uuid);
    }
  }, [order]);

  const fetchTravelersByName = useCallback(
    async (name: string) => {
      return travelerService.find(contexts.customer, { name, isActive: "ativo", onlyUser: true });
    },
    [contexts]
  );
  const isInternationalCombined = flightQuery.data?.stretch.some(
    ({ origin, destination, returnDate }) =>
      (origin.isInternational || destination.isInternational) && !!returnDate
  );

  return (
    <BrokenPolicyJustificationProvider>
      <LogTagProvider logTag={LOG_TAG}>
        <FlightsPageProvider
          value={{
            order,
            isOneWay,
            airwayReducer,
            flightQuery,
            bookingStep,
            airwayBudget,
            airwayParams,
            isManyStretch,
            isInternational,
            isInternationalCombined,
            orderType,
            setOrderType,
          }}
        >
          <FlightsPageUI
            airwayParams={airwayParams}
            canSelectIssuer={!!canSelectIssuer}
            isShowingCart={isShowingCart}
            setIsShowingCart={setIsShowingCart}
            issuerId={issuerId as string}
            bookingSteps={bookingSteps}
            bookingStep={bookingSteps[currentStep].key}
            currentStep={currentStep}
            fetchTravelers={fetchTravelersByName}
            setCurrentStep={setCurrentStep}
            onSelectIssuer={onSelectIssuer}
            deleteOrderAirway={deleteOrderAirway}
            isManyStretch={isManyStretch}
          />
        </FlightsPageProvider>
      </LogTagProvider>
    </BrokenPolicyJustificationProvider>
  );
}
