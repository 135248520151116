import { Branch, CostCenter, Customer, CustomerEmployee, CustomerEmployeesGroup, Project } from "~/application/types";

export interface ShoppingReportData {
  customer: { uuid: string; name: string };
  markAllBranchs: boolean;
  branchs: { name: string, uuid: string }[];
  issuers?: CustomerEmployee[];
  approvers?: CustomerEmployee[];
  passengers?: CustomerEmployee[];
  costCenters?: CostCenter[];
  items?: {
    airway: boolean;
    road: boolean;
    hotel: boolean;
    vehicle: boolean;
    offline: boolean;
  };
  shoppingDate: {
    start: Date | string;
    end?: Date | string;
  };
  travelDate: {
    start?: Date | string;
    end?: Date | string;
  };
}

export interface FindAccessReportsData {
  agency: string;
  page: number;
  search: string;
  customer: string;
  date: string;
}

export enum ReportType {
  AIRWAY = "airway",
  VEHICLE = "vehicle",
  ROAD = "road",
  HOTEL = "hotel",
  TRAVELER_EXPENSES = "traveler_expenses",
  AIRWAY_CHANGES = "airway_changes",
  GENERAL = "general",
  UNFLOWN_AIRWAY = "unflown_airway",
}

export interface GenerateReport {
  reportType: ReportType;
  customer: Customer;
  branches?: Branch[];
  areAllBranchesSelected: boolean;
  issuers?: CustomerEmployee[];
  areAllIssuersSelected: boolean;
  approvers?: CustomerEmployee[];
  areAllApproversSelected: boolean;
  passengers?: CustomerEmployee[];
  areAllPassengersSelected: boolean;
  costCenters?: CostCenter[];
  areAllCostCentersSelected: boolean;
  projects?: Project[];
  areAllProjectsSelected: boolean;
  customerEmployeesGroups?: CustomerEmployeesGroup[];
  areAllCustomerEmployeesGroupsSelected: boolean;
  shoppingDate: {
    start: Date | string;
    end?: Date | string;
  };
  travelDate: {
    start?: Date | string;
    end?: Date | string;
  };
}